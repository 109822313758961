import React, { useEffect, useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { useError } from '../../contexts/ErrorContext';
import {
  useWriteContract,
  useWaitForTransactionReceipt,
  useChainId,
  usePublicClient,
  useAccount,
} from 'wagmi';
import { Abi, parseUnits } from 'viem';
import { contracts } from '../../constants';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const ManageSafeForm = () => {
  const { auth } = useAuth();
  const { setError } = useError();
  const { address } = useAccount();
  const [investor, setInvestor] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [amount, setAmount] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [issuanceData, setIssuanceData] = useState('');
  const [loading, setLoading] = useState(false);
  const [capTableId, setCapTableId] = useState('');
  const chainId = useChainId();
  const publicClient = usePublicClient();

  const safeContract = contracts[chainId!]?.SAFE;

  const { writeContractAsync: issueSafeToken } = useWriteContract();

  useEffect(() => {
    const fetchCapTableId = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/cap_tables/me`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        const capTable = response.data.result;
        setCapTableId(capTable.cap_table_id);
      } catch (error) {
        console.error('Error fetching cap table ID:', error);
        setError(error);
      }
    };

    fetchCapTableId();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const transactionId = uuidv4();
      const securityId = uuidv4();

      // Fetch the count of SAFEs issued by the issuer
      const safeCountResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/cap_tables/${capTableId}/safes/count`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      const safeCount = safeCountResponse.data.count;
      const customId = `SAFE-${safeCount + 1}`;

      const _issuanceData = {
        id: transactionId,
        securityId,
        customId,
        stakeholderId: investor,
        date: BigInt(new Date(issueDate).getTime()),
        investmentAmount: {
          amount: parseUnits(investmentAmount, 2),
          currency: 'USD',
        },
        conversionTriggers: [
          { type: 'PricedEquityRound', description: 'A priced equity round' },
          { type: 'LiquidityEvent', description: 'A liquidity event' },
          { type: 'Dissolution', description: 'Dissolution of the company' },
        ],
        proRata: { numerator: 1, denominator: 1 }, // Example pro-rata rights
        seniority: 1, // Example seniority level
        comments: ['Initial issuance of SAFE'],
      };

      const params = [
        investor,
        tokenId,
        parseUnits(amount, 2),
        BigInt(new Date(issueDate).getTime()),
        {
          amount: parseUnits(investmentAmount, 2),
          currency: 'USD',
        },
        _issuanceData,
      ];

      const txHash = await issueSafeToken({
        // address: safeContract.address as `0x${string}`,
        address: '0x02c420C5e3c7D7B9E734180Dfe1aE6D300800C79',
        account: address,
        abi: safeContract.abi as Abi,
        functionName: 'issueSafeToken',
        args: params,
        chainId,
        gas: BigInt(3_000_000),
      });

      const receipt = await publicClient?.waitForTransactionReceipt({
        hash: txHash,
      });

      // Call the API to save SAFE details
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/cap_tables/${capTableId}/safes`,
        {
          transaction_id: _issuanceData.id,
          security_id: _issuanceData.securityId,
          custom_id: _issuanceData.customId,
          // stakeholder_id: _issuanceData.stakeholderId,
          stakeholder_id: auth?.user.account_id,
          issue_date: issueDate,
          investment_amount: {
            amount: _issuanceData.investmentAmount.amount.toString(),
            currency: _issuanceData.investmentAmount.currency,
          },
          currency: _issuanceData.investmentAmount.currency,
          conversion_triggers: _issuanceData.conversionTriggers,
          pro_rata: _issuanceData.proRata,
          seniority: _issuanceData.seniority,
          comments: _issuanceData.comments,
          tx_hash: txHash,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      alert('SAFE issued successfully');
    } catch (error) {
      console.error('Error issuing SAFE:', error);
      setError(error);
      alert('Failed to issue SAFE');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Investor Address</Form.Label>
        <Form.Control
          type="text"
          value={investor}
          onChange={(e) => setInvestor(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Token ID</Form.Label>
        <Form.Control
          type="text"
          value={tokenId}
          onChange={(e) => setTokenId(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Amount</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Date</Form.Label>
        <Form.Control
          type="date"
          value={issueDate}
          onChange={(e) => setIssueDate(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Investment Amount</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="text"
            value={investmentAmount}
            onChange={(e) => setInvestmentAmount(e.target.value)}
            required
          />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Issuance Data</Form.Label>
        <Form.Control
          type="text"
          value={issuanceData}
          onChange={(e) => setIssuanceData(e.target.value)}
          required
        />
      </Form.Group>
      <Button type="submit" disabled={loading}>
        {loading ? 'Issuing...' : 'Issue SAFE'}
      </Button>
    </Form>
  );
};

export default ManageSafeForm;
