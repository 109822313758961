// ApprovalsStandard.tsx

import React from 'react';
import { Table } from 'react-bootstrap';

interface ApprovalsStandardProps {
  certificate: any;
}

export default function ApprovalsStandard({
  certificate,
}: ApprovalsStandardProps) {
  // Placeholder data for the audit trail
  const auditTrail = [
    {
      action: 'Issuance Initiated',
      by: 'Issuer Admin',
      date: '2023-09-01',
    },
    {
      action: 'Signed by Issuer',
      by: 'Issuer CEO',
      date: '2023-09-02',
    },
    {
      action: 'Received by Recipient',
      by: certificate.fullName || 'Recipient',
      date: '2023-09-03',
    },
    // Add more entries as needed
  ];

  return (
    <div>
      <h4>Approvals Standard</h4>

      <Table>
        <thead>
          <tr>
            <th>Action</th>
            <th>By</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {auditTrail.map((entry, index) => (
            <tr key={index}>
              <td>{entry.action}</td>
              <td>{entry.by}</td>
              <td>{new Date(entry.date).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
