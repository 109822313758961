import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

export default function AcceptInvite() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordStrength, setPasswordStrength] = useState(0);

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  /**
   * Verify the token
   */
  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const response = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/users/me/accept_invite`,
            method: 'POST',
            data: { token },
          });

          // Token is valid
          if (response.status === 200) {
            console.log(response.data);
          }
        }
      } catch (error: any) {
        console.error(error);
        // TODO: Handle "Invalid or expired token"
        console.log(error.response.data);
      } finally {
      }
    })();
  }, []);

  const getStrengthClass = () => {
    switch (passwordStrength) {
      case 0:
        return 'strength-weak';
      case 1:
        return 'strength-fair';
      case 2:
        return 'strength-good';
      case 3:
        return 'strength-strong';
      case 4:
        return 'strength-very-strong';
      default:
        return 'strength-weak';
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    const evaluation = zxcvbn(newPassword);
    setPasswordStrength(evaluation.score);
  };

  const acceptInvite = async (event: React.FormEvent) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      return alert('Passwords do not match.');
    }

    try {
      let response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/users/reset_password`,
        {
          method: 'POST',
          data: {
            password,
            token,
          },
        }
      );
      if (response.status === 200) {
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/v1/users/login`,
          {
            method: 'POST',
            data: {
              email,
              password,
            },
          }
        );
        if (response.status === 200) {
          setAuth(response.data);
          navigate('/onboarding/account/type');
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div className="vh-100 bg-primary">
      <div className="h-100 d-flex flex-column align-items-center justify-content-center mx-2">
        <div className="col-lg-4 col-12">
          <div className="d-flex justify-content-center align-items-center">
            <Card className="w-100">
              <Card.Body>
                <form onSubmit={acceptInvite}>
                  <div className="row mt-3">
                    <div className="col-12 text-center">
                      {/* <img
                        src={logo}
                        alt="CapSign"
                        className="img-fluid"
                      /> */}
                      <h1 className="my-4">Set Password</h1>
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-2 col-8">
                      <hr />
                      <p className="text-center">
                        Create a password for your account
                      </p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6 offset-lg-3 py-3">
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          defaultValue={email || ''}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          onChange={handlePasswordChange}
                        />
                        {password && (
                          <>
                            <div className="password-strength-bar">
                              <div
                                className={`password-strength-meter ${getStrengthClass()}`}
                              ></div>
                            </div>
                            <small className="text-muted">
                              Password strength:{' '}
                              {
                                [
                                  'Weak',
                                  'Fair',
                                  'Good',
                                  'Strong',
                                  'Very Strong',
                                ][passwordStrength]
                              }
                            </small>
                          </>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          onChange={(event) =>
                            setConfirmPassword(event.target.value)
                          }
                        />
                      </div>
                      <Button type="submit" className="mt-2 w-100">
                        Set Password
                      </Button>
                    </div>
                  </div>
                </form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
