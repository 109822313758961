import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

const Notifications = () => {
  return (
    <>
      <Container>
        <div className="d-flex align-items-end justify-content-between">
          <div>
            <h4 className="fw-semibold mb-1">Marketing Preferences</h4>
            <p className="text-sm text-muted">
              By filling your data you get a much better experience using our
              website.
            </p>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <Button variant="outline-secondary" size="sm">
              Cancel
            </Button>
            <Button variant="primary" size="sm">
              Save
            </Button>
          </div>
        </div>

        <hr className="my-6" />

        {/* Email Notifications */}
        <NotificationSection
          title="Email"
          options={[
            { id: 'check_notification_1', label: 'Account Activity' },
            { id: 'check_notification_2', label: 'Communications' },
            { id: 'check_notification_3', label: 'Meetups near you' },
          ]}
        />

        {/* <hr className="my-6" /> */}

        {/* SMS Notifications */}
        {/* <NotificationSection title="SMS" options={[
          { id: 'check_notification_4', label: 'Account Activity' },
          { id: 'check_notification_5', label: 'Communications' },
        ]} /> */}

        {/* <hr className="my-6" /> */}

        {/* Push Notifications */}
        {/* <NotificationSection title="Push" options={[
          { id: 'radio_notification_1', label: 'Everything', type: 'radio' },
          { id: 'radio_notification_2', label: 'Only what\'s important', type: 'radio' },
          { id: 'radio_notification_3', label: 'No push notifications', type: 'radio' },
        ]} isRadio={true} /> */}

        <hr className="my-6 d-md-none" />

        <div className="d-flex justify-content-end gap-2 mb-6">
          <Button variant="outline-secondary" size="sm">
            Cancel
          </Button>
          <Button variant="primary" size="sm">
            Save
          </Button>
        </div>
      </Container>
    </>
  );
};

const NotificationSection = ({ title, options, isRadio = false }: any) => (
  <Row className="g-3">
    <Col md={2}>
      <label className="form-label mb-0">{title}</label>
    </Col>
    <Col md={5}>
      {options.map((option: any) => (
        <div key={option.id} className="d-flex mb-3">
          <div className="me-3">
            <Form.Check
              type={isRadio ? 'radio' : 'checkbox'}
              id={option.id}
              name={isRadio ? 'radio_notifications' : option.id}
              label={isRadio ? option.label : ''}
            />
          </div>
          {!isRadio && (
            <div>
              <label htmlFor={option.id} className="form-label mb-0">
                {option.label}
              </label>
              <p className="text-sm text-muted">
                Get important notifications about you or activity you've missed
              </p>
            </div>
          )}
        </div>
      ))}
    </Col>
  </Row>
);

export default Notifications;
