import { useTheme } from '../../../contexts/ThemeContext';

const ColorModeToggler = ({ toggleTheme }: { toggleTheme: any }) => {
  const { theme } = useTheme();

  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
        onChange={toggleTheme}
        defaultChecked={theme === 'dark'}
      />
      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
        Toggle dark mode
      </label>
    </div>
  );
};

export default ColorModeToggler;
