import React, { createContext, useContext, useState, ReactNode } from 'react';

type Error = any;

type ErrorContextType = {
  error: Error;
  setError: (value: React.SetStateAction<Error>) => void;
  show: boolean;
  setShow: (value: React.SetStateAction<boolean>) => void;
};

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const useError = () => {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};

interface ErrorProviderProps {
  children: ReactNode;
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
  const [error, setError] = useState<Error>('');
  const [show, setShow] = useState(false);

  const updateError: ErrorContextType['setError'] = (value) => {
    setError(value);
    setShow(true); // Automatically show the toast whenever the message is set
  };

  const value: ErrorContextType = {
    error,
    setError: updateError,
    show,
    setShow,
  };

  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
};
