import { http, createConfig } from 'wagmi';
import { base, baseSepolia, Chain, hardhat } from 'wagmi/chains';
import { coinbaseWallet } from 'wagmi/connectors';

export const config = createConfig({
  chains: (() => {
    const temp: [Chain, ...Chain[]] | [] = [base, baseSepolia];
    if (process.env.NODE_ENV === 'development') temp.unshift(hardhat); // insert hardhat P1
    const chains: readonly [Chain, ...Chain[]] | [] = temp;
    return chains;
  })(),
  connectors: [
    coinbaseWallet({
      appName: 'CapSign',
      appLogoUrl: 'https://capsign.com/apple-icon.png',
      preference: 'eoaOnly', // TODO: Set to "all" to enable smart wallet too
    }),
  ],
  transports: {
    [base.id]: http(),
    [baseSepolia.id]: http(),
    [hardhat.id]: http(),
  },
});
