import React, { useState, useEffect } from 'react';
import { Button, Container, Modal, Table, Badge } from 'react-bootstrap';
import { useError } from '../../contexts/ErrorContext';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { contracts } from '../../constants';
import { Abi } from 'viem';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWriteContract,
} from 'wagmi';
import numbro from 'numbro';

interface Settlement {
  settlement_id: string;
  listing_id: string;
  bid_id: string;
  buyer_id: string;
  seller_id: string;
  quantity: number;
  price: number;
  rofr_exercised: boolean;
  status: string;
  created_at: string;
  updated_at: string;
}

function TransferHistory() {
  const { setError } = useError();
  const [settlements, setSettlements] = useState<Settlement[]>([]);
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [showEventModal, setShowEventModal] = useState<boolean>(false);
  const [selectedSettlement, setSelectedSettlement] =
    useState<Settlement | null>(null);
  const { auth } = useAuth();
  const chainId = useChainId();
  const { address: account } = useAccount();
  const publicClient = usePublicClient();

  const { writeContractAsync: settleWrite } = useWriteContract();

  useEffect(() => {
    async function fetchSettlements() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/markets/settlements`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setSettlements(response.data.settlements);
      } catch (error) {
        console.error('Error fetching settlements:', error);
        setError(error);
      }
    }

    fetchSettlements();
  }, [auth?.token, setError]);

  const showEventDetails = (settlement: Settlement) => {
    setSelectedSettlement(settlement);
    setShowEventModal(true);
  };

  const updateSettlementStatus = async (
    settlement: Settlement,
    status: string
  ) => {
    // TODO: Update database with the newly issued certificates!
    // TODO: Update total volume by share class in dashboard!
    setLoading((prev) => ({ ...prev, [settlement.settlement_id]: true }));
    try {
      const rofr = false; // Right of first refusal flag
      const params = [settlement.listing_id, settlement.bid_id, rofr];

      const gas = await publicClient?.estimateContractGas({
        address: contracts[chainId!]?.Auction?.address as `0x${string}`,
        abi: contracts[chainId!]?.Auction?.abi as Abi,
        functionName: 'settle',
        args: params,
        account,
      });
      const result = await settleWrite({
        address: contracts[chainId!]?.Auction?.address as `0x${string}`,
        abi: contracts[chainId!]?.Auction?.abi as Abi,
        functionName: 'settle',
        args: params,
        account,
        gas,
      });

      // Wait for the transaction to be mined (optional, but recommended)
      const receipt = await publicClient?.waitForTransactionReceipt({
        hash: result,
      });

      const txHash = result; // result is the transaction hash

      // Update settlement status
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/v1/markets/settlements/${settlement.settlement_id}/status`,
        { status, tx_hash: txHash },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      // Update local state
      setSettlements((prevSettlements) =>
        prevSettlements.map((settlement) =>
          settlement.settlement_id === settlement.settlement_id
            ? { ...settlement, status }
            : settlement
        )
      );
    } catch (error) {
      console.error('Error updating settlement status:', error);
      setError(error);
    }
    setLoading((prev) => ({ ...prev, [settlement.settlement_id]: false }));
  };

  return (
    <Container className="py-5">
      <h4 className="mb-2">Pending Settlements</h4>
      <Table hover responsive>
        <thead>
          <tr>
            <th>Settlement ID</th>
            <th>Listing ID</th>
            <th>Buyer ID</th>
            <th>Seller ID</th>
            <th>Status</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Details</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {settlements.map((settlement) => (
            <tr key={settlement.settlement_id}>
              <td>{settlement.settlement_id}</td>
              <td>{settlement.listing_id}</td>
              <td>{settlement.buyer_id}</td>
              <td>{settlement.seller_id}</td>
              <td>
                <Badge bg="secondary">{settlement.status}</Badge>
              </td>
              <td>
                {numbro(settlement.quantity).format({
                  thousandSeparated: true,
                })}
              </td>
              <td>${Number(settlement.price).toString()}</td>
              <td>
                <Button
                  variant="outline-info"
                  size="sm"
                  onClick={() => showEventDetails(settlement)}
                >
                  View Details
                </Button>
              </td>
              <td>
                {settlement.status === 'Pending' && (
                  <>
                    <Button
                      variant="success"
                      size="sm"
                      className="me-2"
                      disabled={loading[settlement.settlement_id]}
                      onClick={() =>
                        updateSettlementStatus(settlement, 'Approved')
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      disabled={loading[settlement.settlement_id]}
                      onClick={() =>
                        updateSettlementStatus(settlement, 'Rejected')
                      }
                    >
                      Reject
                    </Button>
                  </>
                )}
              </td>
            </tr>
          ))}
          {settlements.length === 0 && (
            <tr>
              <td colSpan={9} className="text-center py-5">
                No pending settlements found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal
        show={showEventModal}
        onHide={() => setShowEventModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Settlement Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{JSON.stringify(selectedSettlement, null, 2)}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEventModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default TransferHistory;
