import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const GoogleDriveCallback = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const [error, setError] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (code) {
      axios(
        `${process.env.REACT_APP_API_URL}/v1/google_drive/auth/google/callback`,
        {
          method: 'POST',
          data: { code },
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            navigate(
              '/settings/apps/305e6974-91d1-4d98-b78a-9e9034ed12d7/info'
            );
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [location]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <div>Redirecting...</div>;
};

export default GoogleDriveCallback;
