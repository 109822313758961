import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Table, Spinner, Form, Button, Row, Col } from 'react-bootstrap';
import { Auth } from '../../types';
import numbro from 'numbro';
import { formatOptions } from '../../constants';
import { useAuth } from '../../contexts/AuthContext';

interface Transaction {
  transaction_id: string;
  amount: number;
  status: string;
  effective_at: string;
  ledger_account_id: string;
  direction: string;
  entry_amount: number;
  entry_id: string;
  account_name: string;
  payee: string;
}

interface Offering {
  offering_id: string;
  offering_name: string;
}

export default function AccountTransactions() {
  const { auth } = useAuth();
  const { ledger_id, ledger_account_id } = useParams<{
    ledger_id: string;
    ledger_account_id: string;
  }>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [offerings, setOfferings] = useState<Offering[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedOffering, setSelectedOffering] = useState<string>('');

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const params = {
        start_date: startDate,
        end_date: endDate,
        offering_id: selectedOffering,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/ledgers/${ledger_id}/accounts/${ledger_account_id}/transactions`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
          params,
        }
      );
      setTransactions(response.data.result);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchOfferings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/offerings`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setOfferings(response.data.result);
    } catch (error) {
      console.error('Error fetching offerings:', error);
    }
  };

  useEffect(() => {
    fetchTransactions();
    fetchOfferings();
  }, [ledger_account_id, startDate, endDate, selectedOffering]);

  const handleDateChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
    };

  const handleOfferingChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOffering(e.target.value);
  };

  const handleFilterSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchTransactions();
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div>
      <h2 className="my-4">Transactions for Account {ledger_account_id}</h2>
      <Form onSubmit={handleFilterSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={handleDateChange(setStartDate)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={handleDateChange(setEndDate)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="offering">
              <Form.Label>Investment Offering</Form.Label>
              <Form.Select
                as="select"
                value={selectedOffering}
                onChange={handleOfferingChange}
              >
                <option value="">All Offerings</option>
                {offerings.map((offering) => (
                  <option
                    key={offering.offering_id}
                    value={offering.offering_id}
                  >
                    {offering.offering_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className="align-self-end">
            <Button type="submit" variant="primary">
              Apply Filters
            </Button>
          </Col>
        </Row>
      </Form>
      <Table bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Account Name</th>
            <th>Transaction Date</th>
            <th>Amount</th>
            <th>Recipient</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={`${transaction.transaction_id}-${transaction.entry_id}`}>
              <td>{transaction.account_name}</td>
              <td>{new Date(transaction.effective_at).toLocaleDateString()}</td>
              <td>
                {transaction.direction === 'credit' ? '-' : ''}$
                {numbro(Math.abs(transaction.entry_amount).toFixed(2)).format(
                  formatOptions
                )}
              </td>
              <td>{transaction.payee}</td>
              <td>{transaction.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
