import React, { useEffect, useState } from 'react';
import { Container, Accordion, Card } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';

export default function OfferingQuestions() {
  const [questions, setQuestions] = useState([
    {
      question: 'What is Regulation Crowdfunding (Reg CF)?',
      answer:
        'Regulation Crowdfunding is a set of rules adopted by the SEC that allow private companies to raise capital from the general public through online crowdfunding platforms. It aims to democratize access to investment opportunities by enabling individuals to invest in startups and small businesses.',
    },
    {
      question: 'Who can invest in a Reg CF offering?',
      answer:
        "Both accredited and non-accredited investors can invest in a Reg CF offering. However, there are investment limits based on an individual's income and net worth to protect non-accredited investors from excessive risk.",
    },
    {
      question: 'What is the minimum and maximum investment amount?',
      answer:
        "The minimum investment amount for CapSign's offering is $500 (500 shares), and the maximum investment amount is $300,000 (300,000 shares).",
    },
    {
      question: 'What is the share price for CapSign’s Reg CF offering?',
      answer:
        'The share price for CapSign’s Reg CF offering is $1.00 per share.',
    },
    {
      question: 'What are the key dates for the offering?',
      answer:
        'The offering period starts on July 30, 2024, and ends on September 30, 2024.',
    },
    {
      question: "How can I invest in CapSign's Reg CF offering?",
      answer:
        "Investors can participate in the offering through CapSign's funding portal. You will need to create an account, complete the necessary verification processes, and follow the instructions to make an investment.",
    },
    {
      question:
        'What are the risks associated with investing in a Reg CF offering?',
      answer:
        'Investing in early-stage companies involves significant risks, including the potential loss of your entire investment. These investments are illiquid, meaning they cannot be easily sold or exchanged for cash. It’s important to carefully review all offering materials and consider your risk tolerance before investing.',
    },
    {
      question: 'What will the funds raised be used for?',
      answer:
        "The funds raised from the Reg CF offering will be used to accelerate the development of CapSign's platform, expand its user base, and enhance its suite of blockchain-enabled financial services.",
    },
    {
      question: 'Is my investment protected or insured?',
      answer:
        'Investments in Reg CF offerings are not protected or insured by the FDIC or any other government agency. You should be prepared to lose your entire investment.',
    },
    {
      question: 'How do I stay updated on the progress of my investment?',
      answer:
        'CapSign will provide updates through its platform. Investors will receive periodic reports on the company’s progress and the use of funds. Additionally, any significant updates or changes will be communicated directly to investors.',
    },
    {
      question: 'Can I sell my shares after investing?',
      answer:
        'Shares purchased in a Reg CF offering are subject to resale restrictions for a period of one year. After this period, you may be able to sell your shares, but there may be limited options for doing so, as private company shares are not traded on public exchanges.',
    },
    {
      question: 'What happens if the company does not reach its funding goal?',
      answer:
        'If CapSign does not reach its funding goal, the offering may be canceled, and any funds invested will be returned to the investors.',
    },
    {
      question:
        'How do I get more information about CapSign’s Reg CF offering?',
      answer:
        'Additional information about the offering can be found in the offering statement filed with the SEC, available at www.sec.gov. You can also contact CapSign’s support team for any specific queries or concerns.',
    },
  ]);
  const { auth } = useAuth();
  const { setError } = useError();

  /**
   * Fetch the current offering questions
   */
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        // const response = await axios.get(
        //   `${process.env.REACT_APP_API_URL}/v1/offerings/questions`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${auth?.token}`,
        //     },
        //   }
        // );
        // setQuestions(response.data.result);
      } catch (error: any) {
        console.error('Error fetching questions:', error);
        if (error.response) {
          setError(error.response.data.message);
        }
      }
    };

    fetchQuestions();
  }, [auth, setError]);

  return (
    <Container className="mt-5">
      <h1>Frequently Asked Questions</h1>
      <Accordion defaultActiveKey="0" as={Card} className="m-0 p-0">
        {questions.map((question, index) => (
          <Accordion.Item key={index} eventKey={`${index}`} className="m-0 p-0">
            <Accordion.Header as={Card.Header} className="m-0 p-0">
              <span className="fw-bold">{question.question}</span>
            </Accordion.Header>
            <Accordion.Body as={Card.Body}>{question.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
}
