import axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Auth } from '../../types';
import { useAuth } from '../../contexts/AuthContext';

function Profile() {
  const { auth } = useAuth();
  const [address, setAddress] = React.useState('');
  const [publicKey, setPublicKey] = React.useState('');
  const [proposedAddress, setProposedAddress] = React.useState('');
  const [proposedPublicKey, setProposedPublicKey] = React.useState('');

  /**
   * Fetch/set user address
   */
  React.useEffect(() => {
    (async () => {
      if (process.env.REACT_APP_REQUIRE_AUTH && auth?.user) {
        try {
          const response = await axios(`http://localhost:8080/v1/nodes/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          });
          if (response.status === 200) {
            if (response.data.nodes[0].address)
              setAddress(response.data.nodes[0].address);
            if (response.data.nodes[0].public_key)
              setPublicKey(response.data.nodes[0].public_key);
          }
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [auth, address]);

  /**
   * Saves user address
   */
  const onSubmit = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/users/${auth?.user}`,
        {
          method: 'PATCH',
          data: {
            address: proposedAddress,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (response.status === 200) {
        setAddress(proposedAddress);
      }
    } catch (error) {
      const message = (error as any)?.response.data;
      if (message) alert(message);
    }
  };

  return (
    <>
      <Helmet>
        <title>CapSign | User Profile</title>
      </Helmet>
      <section className="card p-5 mb-5 shadow-sm mt-5">
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-12">
              <label htmlFor="address" className="form-label">
                Authorized Address
                <br />
                <small className="form-text text-muted">
                  This address is allowed to use CapSign (cannot be modified)
                </small>
              </label>
              {address ? (
                <input
                  className="form-control"
                  value={address}
                  readOnly
                  disabled
                />
              ) : (
                <input
                  className="form-control"
                  placeholder={''}
                  value={proposedAddress}
                  onChange={(event) => setProposedAddress(event.target.value)}
                />
              )}
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <label htmlFor="publicKey" className="form-label">
                Node Public Key
                <br />
                <small className="form-text text-muted">
                  Public key associated with your CapSign node
                </small>
              </label>
              {publicKey ? (
                <input
                  className="form-control"
                  value={publicKey}
                  readOnly
                  disabled
                />
              ) : (
                <input
                  className="form-control"
                  placeholder={''}
                  value={proposedPublicKey}
                  onChange={(event) => setProposedPublicKey(event.target.value)}
                />
              )}
            </div>
          </div>
          {address ? null : (
            <div className="row">
              <div className="col-12">
                <button
                  onClick={onSubmit}
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Profile;
