import React, { useState, useEffect } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';

interface ShareClassProps {
  initialState: any;
  onChange: (data: any, isValid: boolean) => void;
}

export default function Stock({ initialState, onChange }: ShareClassProps) {
  const getDefault = (value: any, defaultValue: any) =>
    value !== undefined ? value : defaultValue;

  const [classType, setClassType] = useState(
    getDefault(initialState?.class_type, 'Common')
  );
  const [className, setClassName] = useState(
    getDefault(initialState?.class_name, '')
  );
  const [initialSharesAuthorized, setInitialSharesAuthorized] =
    useState<number>(getDefault(initialState?.initial_shares_authorized, 0));
  const [votesPerShare, setVotesPerShare] = useState(
    getDefault(initialState?.votes_per_share, 0)
  );
  const [parValue, setParValue] = useState(
    getDefault(initialState?.par_value, 0)
  );
  const [prefix, setPrefix] = useState(getDefault(initialState?.prefix, ''));
  const [color, setColor] = useState(
    getDefault(initialState?.color, '#1D632D')
  );
  // const [uri, setUri] = useState(getDefault(initialState?.uri, ''));

  // Touched state for each field
  const [touched, setTouched] = useState({
    classType: false,
    className: false,
    initialSharesAuthorized: false,
    prefix: false,
    color: false,
  });

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  useEffect(() => {
    if (initialState) {
      if (initialState.class_type !== undefined) {
        setClassType(initialState.class_type);
      }
      if (initialState.prefix !== undefined) {
        setPrefix(initialState.prefix);
      }
      if (initialState.initial_shares_authorized !== undefined) {
        setInitialSharesAuthorized(initialState.initial_shares_authorized);
      }
      if (initialState.par_value !== undefined) {
        setParValue(initialState.par_value);
      }
      // if (initialState.uri !== undefined) {
      //   setUri(initialState.uri);
      // }
    }
  }, [initialState]);

  const handlePrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const lettersOnly = value.replace(/[^a-zA-Z]/g, ''); // Allow only alphabetical characters
    setPrefix(lettersOnly);
  };

  // Validation
  const [isValid, setIsValid] = useState(false);

  // Notify parent component of changes
  useEffect(() => {
    const requiredFieldsFilled =
      classType && className && color && prefix && initialSharesAuthorized > 0;
    setIsValid(requiredFieldsFilled);

    if (
      classType !== initialState.class_type ||
      className !== initialState.class_name ||
      initialSharesAuthorized !== initialState.initial_shares_authorized ||
      votesPerShare !== initialState.votes_per_share ||
      parValue !== initialState.par_value ||
      prefix !== initialState.prefix ||
      color !== initialState.color
      // uri !== initialState.uri
    ) {
      onChange(
        {
          class_type: classType,
          class_name: className,
          initial_shares_authorized: initialSharesAuthorized,
          votes_per_share: votesPerShare,
          par_value: parValue,
          prefix,
          color,
          // uri,
        },
        requiredFieldsFilled
      );
    }
  }, [
    classType,
    className,
    initialSharesAuthorized,
    votesPerShare,
    parValue,
    prefix,
    color,
    // uri,
  ]);

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={12}>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formShareClass" className="my-3">
                <Form.Label>Class Type</Form.Label>
                <Form.Select
                  value={classType}
                  onChange={(e) => setClassType(e.target.value)}
                  required
                  isInvalid={touched.classType && !classType}
                  onBlur={() => handleBlur('classType')}
                >
                  <option value="Common">Common</option>
                  <option value="Preferred">Preferred</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a share class.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formClassname" className="my-3">
                <Form.Label>Class Name</Form.Label>
                <Form.Control
                  type="text"
                  value={className}
                  isInvalid={touched.className && !className}
                  onBlur={() => handleBlur('className')}
                  onChange={(e) => setClassName(e.target.value)}
                  placeholder="Common Stock"
                />
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                Please enter a subtype.
              </Form.Control.Feedback>
            </Col>
            <Col md={2}>
              <Form.Group controlId="formColor" className="my-3">
                <Form.Label>Color</Form.Label>
                <Form.Control
                  type="color"
                  value={color}
                  isInvalid={touched.color && !color}
                  title="Choose your color"
                  onBlur={() => handleBlur('color')}
                  onChange={(e) => setColor(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                Please choose a color.
              </Form.Control.Feedback>
            </Col>
            <Col md={2}>
              <Form.Group controlId="formPrefix" className="my-3">
                <Form.Label>Class Prefix</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={prefix}
                    onChange={handlePrefixChange}
                    required
                    isInvalid={touched.prefix && !prefix}
                    onBlur={() => handleBlur('prefix')}
                  />
                  <InputGroupText>-</InputGroupText>
                  <Form.Control.Feedback type="invalid">
                    Please enter a prefix.
                  </Form.Control.Feedback>
                </InputGroup>
                <small className="text-secondary">E.g., "CS"</small>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group
                controlId="formInitialSharesAuthorized"
                className="my-3"
              >
                <Form.Label>Initial Shares Authorized</Form.Label>
                <Form.Control
                  type="number"
                  value={
                    initialSharesAuthorized === 0 ? '' : initialSharesAuthorized
                  }
                  onChange={(e) =>
                    setInitialSharesAuthorized(Number(e.target.value))
                  }
                  onBlur={() => handleBlur('initialSharesAuthorized')}
                  required
                  isInvalid={
                    touched.initialSharesAuthorized &&
                    initialSharesAuthorized <= 0
                  }
                />
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                Please enter a positive number.
              </Form.Control.Feedback>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formParValue" className="my-3">
                <Form.Label>Par Value</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    step="0.00001"
                    value={parValue === 0 ? '' : parValue}
                    placeholder={'0.01'}
                    onChange={(e) => setParValue(Number(e.target.value))}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
