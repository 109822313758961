import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import RegCF from '@capsign/contracts/artifacts/contracts/offerings/RegCF.sol/RegCF.json';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useReadContract,
  useWaitForTransactionReceipt,
  useWalletClient,
} from 'wagmi';
import { parseUnits, encodeAbiParameters, keccak256, concat } from 'viem';
import { contracts } from '../../../constants';
import { useOffering } from '../../../contexts/OfferingContext';
import { hexZeroPad, hexlify } from 'ethers/lib/utils';
import { subscriptionTokenAddress } from '../../../utils';

const create2DeployerAddress = '0x914d7Fec6aaC8cd542e72Bca78B30650d45643d7';

const CreateOfferingPaymentTerms: React.FC = () => {
  const { address: account } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const { auth } = useAuth();
  const { offeringId } = useParams();
  const [formData, setFormData] = useState({
    paymentTokenAddress: subscriptionTokenAddress[chainId],
    investmentDeadline: '',
    minInvestment: 0,
  });
  const [tokenDetails, setTokenDetails] = useState({
    symbol: '',
    name: '',
    decimals: 0,
  });
  const navigate = useNavigate();
  const { offering, fetchOffering } = useOffering();
  const { data: walletClient } = useWalletClient();
  const [txHash, setTxHash] = useState<`0x${string}` | undefined>();
  const { data: receipt, isLoading: isReceiptLoading } =
    useWaitForTransactionReceipt({
      hash: txHash,
    });

  useEffect(() => {
    if (offeringId && !offering) fetchOffering(offeringId);
  }, [offeringId, fetchOffering]);

  const contract = contracts[chainId!].RestrictedStock;

  const { data: name } = useReadContract({
    address: formData.paymentTokenAddress as `0x${string}`,
    abi: contract.abi,
    functionName: 'name',
  });

  const { data: symbol } = useReadContract({
    address: formData.paymentTokenAddress as `0x${string}`,
    abi: contract.abi,
    functionName: 'symbol',
  });

  const { data: decimals } = useReadContract({
    address: formData.paymentTokenAddress as `0x${string}`,
    abi: contract.abi,
    functionName: 'decimals',
  });

  const handleFormChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const computeCreate2Address = async (
    bytecode: `0x${string}`,
    args: any[]
  ) => {
    // TODO: For the Reg CF contract. This is for identity contract still!
    const constructorAbi = [
      {
        inputs: [
          {
            internalType: 'address',
            name: '_issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: '_intermediary',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: '_targetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_minInvestment',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: '_maxInvestment',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: '_paymentToken',
            type: 'address',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
    ];
    const inputs = constructorAbi[0].inputs;
    const deployData = encodeAbiParameters(inputs, args);
    const fullBytecode = concat([bytecode, deployData]);
    const salt = keccak256(
      new TextEncoder().encode(Date.now().toString())
    ) as `0x${string}`;
    const codeHash = keccak256(fullBytecode);

    const computedAddress = `0x${keccak256(
      concat(['0xff', create2DeployerAddress, salt, codeHash])
    ).slice(-40)}`;

    return {
      computedAddress,
      salt,
      fullBytecode,
    };
  };

  const createCallData = (
    salt: `0x${string}`,
    fullBytecode: `0x${string}`
  ): string => {
    const saltHex = hexZeroPad(salt, 32);
    const callData = hexlify(concat([saltHex as `0x${string}`, fullBytecode]));

    return callData;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (!walletClient) {
        throw new Error('No wallet client available');
      }

      const params = [
        account,
        '0x8626f6940E2eb28930eFb4CeF49B2d1F2C9C1199', // Intermediary address. TODO: Set correctly
        parseUnits(offering.soft_cap.toString(), decimals || 18),
        parseUnits(formData.minInvestment.toString(), decimals || 18),
        parseUnits(offering.hard_cap.toString(), decimals || 18),
        formData.paymentTokenAddress,
      ];

      const { computedAddress, salt, fullBytecode } =
        await computeCreate2Address(RegCF.bytecode as `0x${string}`, params);

      const callData = createCallData(salt, fullBytecode);

      const tx = await walletClient.sendTransaction({
        to: create2DeployerAddress,
        data: callData as `0x${string}`,
      });

      setTxHash(tx);
      await publicClient?.waitForTransactionReceipt({ hash: tx });

      console.log(computedAddress);

      await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
        data: {
          contract_address: computedAddress,
        },
      });

      await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/payment_terms`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
        data: {
          payment_token_address: formData.paymentTokenAddress,
          investment_deadline: formData.investmentDeadline,
          minimum_investment: formData.minInvestment,
        },
      });
      navigate(`/offerings/${offeringId}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-4 offset-4">
          <h2>Set Payment Terms</h2>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPaymentToken">
                <Form.Label>Payment Token Address</Form.Label>
                <Form.Control
                  type="text"
                  name="paymentTokenAddress"
                  placeholder="Enter payment token address"
                  value={formData.paymentTokenAddress}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridMinInvestment">
                <Form.Label>Minimum Investment</Form.Label>
                <Form.Control
                  type="number"
                  name="minInvestment"
                  placeholder="Enter minimum investment"
                  value={formData.minInvestment}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            {formData.paymentTokenAddress && (
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridTokenDetails">
                  <Form.Label>Token Details</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Token Symbol"
                    value={symbol}
                    readOnly
                  />
                  <Form.Control
                    type="text"
                    placeholder="Token Name"
                    value={name}
                    readOnly
                  />
                  <Form.Control
                    type="number"
                    placeholder="Token Decimals"
                    value={decimals}
                    readOnly
                  />
                </Form.Group>
              </Row>
            )}
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInvestmentDeadline">
                <Form.Label>Investment Deadline</Form.Label>
                <Form.Control
                  type="date"
                  name="investmentDeadline"
                  placeholder="Enter investment deadline"
                  value={formData.investmentDeadline}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
            </Row>
            <Button variant="primary" type="submit">
              Save and Finish
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default CreateOfferingPaymentTerms;
