import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { useError } from '../../../contexts/ErrorContext';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from 'wagmi';

interface IndicationModalProps {
  show: boolean;
  handleClose: () => void;
  contractAddress: string;
  offeringId: string;
  onIndicationSuccess: (indicatedAmount: number) => void;
}

const IndicationModal: React.FC<IndicationModalProps> = ({
  show,
  handleClose,
  offeringId,
  onIndicationSuccess,
}) => {
  const [indicatedAmount, setIndicatedAmount] = useState<number | string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('crypto');
  const { setError } = useError();
  const { auth } = useAuth();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { writeContractAsync: permit } = useWriteContract();

  const handleIndicatedAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIndicatedAmount(e.target.value);
  };

  const handlePaymentMethodChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPaymentMethod(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Success: update offering in the database with amount of interest indicated
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/indications`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-ID': auth?.user.account_id,
          },
          data: {
            indicated_amount: indicatedAmount,
          },
        }
      );

      const { result } = response.data;

      // Call parent component's success callback
      onIndicationSuccess(parseFloat(indicatedAmount.toString()));
    } catch (error: any) {
      if (error.code === 'CALL_EXCEPTION') {
        // Decode the revert reason if available
        const errorMessage = error.data.message;
        console.log('Transaction reverted with:', errorMessage);

        // Example: Check for specific custom errors
        if (errorMessage.includes('BelowMinimumInvestment')) {
          // Handle below minimum investment error
          console.error('Investment amount is below minimum');
        } else if (errorMessage.includes('InvestmentCapExceeded')) {
          // Handle investment cap exceeded error
          console.error('Investment cap exceeded');
        } else if (errorMessage.includes('PaymentFailed')) {
          // Handle payment failed error
          console.error('Payment transfer failed');
        } else {
          // Handle unrecognized custom error
          console.error('Unrecognized custom error:', errorMessage);
        }
      }
      console.log(error);
      setError(error);
    } finally {
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Indicate Interest</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPaymentMethod">
            <Form.Label>Payment Method</Form.Label>
            <Form.Select
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
              required
            >
              <option value="crypto" disabled>
                Crypto
              </option>
              <option value="ach" disabled>
                ACH
              </option>
              <option value="wire">Wire</option>
              <option value="creditCard" disabled>
                Credit Card
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="formInvestmentAmount" className="mt-3">
            <Form.Label>Investment Amount</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                placeholder="Enter amount"
                value={indicatedAmount}
                onChange={handleIndicatedAmountChange}
                required
              />
              <InputGroup.Text>USDC</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default IndicationModal;
