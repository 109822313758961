import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';

export default function CorporateAuthorization(props: any) {
  const [legalName, setLegalName] = useState('');
  const [title, setTitle] = useState('');
  const [authorizationFile, setAuthorizationFile] = useState<File | null>(null);

  const { auth } = useAuth();
  const { setError } = useError();
  const navigate = useNavigate();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior

    try {
      const formData = new FormData();
      formData.append('legal_name', legalName);
      formData.append('title', title);
      if (authorizationFile) {
        formData.append('file', authorizationFile);
      }

      const headers = {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'multipart/form-data', // Important if you're sending files
        'X-Account-Id': auth?.user.account_id,
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/business_entities/me/authorizations`,
        formData,
        { headers }
      );

      // Navigate to the next step upon successful submission
      navigate('/onboarding/create-id?walletType=self-custody');
    } catch (error) {
      setError(error);
      console.error('Error submitting authorization:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (
      file &&
      (file.type === 'application/pdf' ||
        file.type === 'application/msword' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    ) {
      setAuthorizationFile(file);
    } else {
      alert('Please upload a valid PDF or Word document.');
      event.target.value = ''; // Reset file input
    }
  };

  return (
    <div className="row">
      <Form
        className="col-lg-6 offset-lg-3 col-md-12 pt-3 pb-5"
        onSubmit={onSubmit}
      >
        <h5 className="mb-4">Corporate Authorization</h5>

        <Form.Group className="mb-3" controlId="legalNameInput">
          <Form.Label>User's Legal Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter legal name"
            value={legalName}
            onChange={(e) => setLegalName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="titleInput">
          <Form.Label>Title at the Company</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="authorizationFileInput">
          <Form.Label>Corporate Authorization Document</Form.Label>
          <Form.Control
            type="file"
            onChange={handleFileChange}
            required={false}
          />
          <Form.Text className="text-muted">
            Optional. Upload a PDF or Word Document containing the user's
            corporate authorization.
          </Form.Text>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit Authorization
        </Button>
      </Form>
    </div>
  );
}
