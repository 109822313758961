import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const CustomVestingConditions = ({ conditions, onChange, loading }: any) => {
  const [localConditions, setLocalConditions] = useState(conditions || []);

  useEffect(() => {
    onChange(localConditions);
  }, [localConditions, onChange]);

  const handleAddCondition = () => {
    setLocalConditions([
      ...localConditions,
      {
        id: '',
        description: '',
        portionNumerator: 0,
        portionDenominator: 100,
        quantity: 0,
        triggerType: 'date',
        triggerDate: '',
        relativeToConditionId: '',
        nextConditionIds: [],
      },
    ]);
  };

  const handleConditionChange = (index: number, field: string, value: any) => {
    const updatedConditions = [...localConditions];

    if (field === 'triggerDate') {
      // Convert the date string to a Unix timestamp (in seconds)
      const dateValue = new Date(value);
      updatedConditions[index][field] = Math.floor(dateValue.getTime() / 1000);
    } else {
      updatedConditions[index][field] = value;
    }

    setLocalConditions(updatedConditions);
  };

  const handleRemoveCondition = (index: number) => {
    const updatedConditions = localConditions.filter(
      (_: any, i: number) => i !== index
    );
    setLocalConditions(updatedConditions);
  };

  return (
    <Form.Group className="mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Custom Vesting Conditions</h5>
        <Button
          variant="secondary"
          onClick={handleAddCondition}
          disabled={loading}
        >
          Add Condition
        </Button>
      </div>
      {localConditions.map((condition: any, index: number) => (
        <div className="card my-3" key={index}>
          <div className="card-body">
            <Form.Group className="mb-3">
              <Form.Label>Condition ID</Form.Label>
              <Form.Control
                type="text"
                value={condition.id}
                onChange={(e) =>
                  handleConditionChange(index, 'id', e.target.value)
                }
                required
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                value={condition.description}
                onChange={(e) =>
                  handleConditionChange(index, 'description', e.target.value)
                }
                required
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Portion Numerator</Form.Label>
              <Form.Control
                type="number"
                value={condition.portionNumerator}
                onChange={(e) =>
                  handleConditionChange(
                    index,
                    'portionNumerator',
                    Number(e.target.value)
                  )
                }
                required
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Portion Denominator</Form.Label>
              <Form.Control
                type="number"
                value={condition.portionDenominator}
                onChange={(e) =>
                  handleConditionChange(
                    index,
                    'portionDenominator',
                    Number(e.target.value)
                  )
                }
                required
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Trigger Type</Form.Label>
              <Form.Select
                value={condition.triggerType}
                onChange={(e) =>
                  handleConditionChange(index, 'triggerType', e.target.value)
                }
                required
                disabled={loading}
              >
                <option value="date">Date</option>
                <option value="event">Event</option>
              </Form.Select>
            </Form.Group>
            {condition.triggerType === 'date' && (
              <Form.Control
                type="date"
                value={
                  condition.triggerDate
                    ? new Date(condition.triggerDate * 1000)
                        .toISOString()
                        .substr(0, 10)
                    : ''
                }
                onChange={(e) =>
                  handleConditionChange(index, 'triggerDate', e.target.value)
                }
                required
                disabled={loading}
              />
            )}
            {condition.triggerType === 'event' && (
              <Form.Group className="mb-3">
                <Form.Label>Event Type</Form.Label>
                <Form.Control
                  type="text"
                  value={condition.eventType}
                  onChange={(e) =>
                    handleConditionChange(index, 'eventType', e.target.value)
                  }
                  placeholder="Enter event type that triggers vesting"
                  required
                  disabled={loading}
                />
              </Form.Group>
            )}
            <Button
              variant="outline-danger"
              onClick={() => handleRemoveCondition(index)}
              disabled={loading}
              className="mt-3"
            >
              <FontAwesomeIcon icon={faTrash} /> Remove
            </Button>
          </div>
        </div>
      ))}
    </Form.Group>
  );
};

export default CustomVestingConditions;
