import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  ListGroup,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
import BuyForm from './BuyForm';
import SellForm from './SellForm';
import { contracts, formatOptions } from '../../../constants';
import numbro from 'numbro';
import { useAuth } from '../../../contexts/AuthContext';
import { decimals, explorerBaseUrl } from '../../../utils';
import {
  useChainId,
  useAccount,
  usePublicClient,
  useReadContract,
} from 'wagmi';
import { formatUnits, Abi, fromHex } from 'viem';
import axios from 'axios';

export default function Secondary() {
  const { tokenId } = useParams();
  const { auth } = useAuth();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const [buyModalShow, setBuyModalShow] = useState(false);
  const [sellModalShow, setSellModalShow] = useState(false);
  const [lastTxPrice, setLastTxPrice] = useState(null);
  const [filteredListings, setFilteredListings] = useState([]);
  const [securityClasses, setSecurityClasses] = useState<any>({});

  const tokenContract = contracts[chainId!]?.RestrictedStock;
  const auctionContract = contracts[chainId!]?.Auction;
  const identityRegistryContract = contracts[chainId!]?.IdentityRegistry;

  const { data: tokenListings, refetch: refetchListings }: any =
    useReadContract({
      address: auctionContract?.address as `0x${string}`,
      abi: auctionContract?.abi as Abi,
      functionName: 'getListingsByToken',
      args: [tokenId],
    });

  const { data: standingBids } = useReadContract({
    address: auctionContract?.address as `0x${string}`,
    abi: auctionContract?.abi as Abi,
    functionName: 'getStandingBids',
    args: [tokenId],
  });

  const { data: highestBid }: any = useReadContract({
    address: auctionContract?.address as `0x${string}`,
    abi: auctionContract?.abi as Abi,
    functionName: 'highestBids',
    args: [tokenId],
  });

  const { data: lowestAsk }: any = useReadContract({
    address: auctionContract?.address as `0x${string}`,
    abi: auctionContract?.abi as Abi,
    functionName: 'lowestAsks',
    args: [tokenId],
  });

  const { data: tokenBidCount }: any = useReadContract({
    address: auctionContract?.address as `0x${string}`,
    abi: auctionContract?.abi as Abi,
    functionName: 'bidCounts',
    args: [tokenId],
  });

  const { data: name }: any = useReadContract({
    address: tokenId as `0x${string}`,
    abi: tokenContract?.abi as Abi,
    functionName: 'name',
  });

  /**
   * Filter listings
   */
  useEffect(() => {
    if (tokenListings && tokenId) {
      const _filteredListings = tokenListings.filter(
        (tokenListing: any) => tokenListing.status !== 4
      );
      setFilteredListings(_filteredListings);

      // Fetch security classes for each listing
      const fetchSecurityClasses = async () => {
        const classes: any = {};
        for (const listing of _filteredListings) {
          const security: any = await publicClient?.readContract({
            address: tokenId as `0x${string}`,
            abi: tokenContract?.abi as Abi,
            functionName: 'securities',
            args: [listing.securityId],
          });
          const securityClass = security.class;
          classes[listing.securityId] = securityClass;
        }
        setSecurityClasses(classes);
      };

      fetchSecurityClasses();
    }
  }, [tokenListings, tokenId, publicClient, tokenContract?.abi]);

  /**
   * Check if user identity is registered with token ID registry
   */
  const verifyIdentity = async () => {
    try {
      const identityRegistryAddress = await publicClient?.readContract({
        address: tokenId as `0x${string}`,
        abi: tokenContract?.abi as Abi,
        functionName: 'identityRegistry',
      });

      const isVerified = await publicClient?.readContract({
        address: identityRegistryAddress as `0x${string}`,
        abi: identityRegistryContract?.abi as Abi,
        functionName: 'isVerified',
        args: [account],
      });

      if (!isVerified) {
        alert('Please register your identity to trade this token.');
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/v1/identities/me`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        const { identity_address } = response.data.result;
        // const tx = await publicClient?.writeContract({
        //   address: identityRegistryAddress as `0x${string}`,
        //   abi: identityRegistryContract?.abi as Abi,
        //   functionName: 'registerIdentity',
        //   args: [account, identity_address, 42],
        //   gas: BigInt(300_000),
        // });
        // await tx.wait();
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Get last tx price
   */
  // useEffect(() => {
  //   const fetchLastTxPrice = async () => {
  //     if (publicClient && tokenId && auctionContract?.address) {
  //       const filter = {
  //         address: auctionContract?.address as `0x${string}`,
  //         event: parseAbiItem(
  //           auctionContract?.abi.find(
  //             (item: any) => item.name === 'ListingSold'
  //           )
  //         ),
  //         args: [null, tokenId, null, null, null, null, null],
  //       };

  //       const latestBlock = await publicClient.getBlockNumber();
  //       const chunkSize = 10000; // Maximum allowed range
  //       let fromBlock = latestBlock - BigInt(maxBlockRange[chainId!]); // Starting block

  //       const events = [];

  //       while (fromBlock < latestBlock) {
  //         let toBlock =
  //           fromBlock + chunkSize > latestBlock
  //             ? latestBlock
  //             : fromBlock + chunkSize;
  //         const chunkEvents = await publicClient.getLogs({
  //           ...filter,
  //           fromBlock,
  //           toBlock,
  //         });
  //         events.push(...chunkEvents);
  //         fromBlock += chunkSize + 1; // Move to the next chunk
  //       }

  //       if (events.length > 0) {
  //         const lastEvent = events[events.length - 1];
  //         const lastPrice = lastEvent?.args?.price; // Assuming the event has a price argument
  //         setLastTxPrice(lastPrice.toString());
  //       } else {
  //         setLastTxPrice(null);
  //       }
  //     }
  //   };

  //   fetchLastTxPrice();
  // }, [publicClient, tokenId, auctionContract, chainId]);

  return (
    <div className="row g-0 pt-3 h-100 min-vh-100">
      <BuyForm show={buyModalShow} handleClose={() => setBuyModalShow(false)} />
      <SellForm
        show={sellModalShow}
        onListingSuccess={refetchListings}
        handleClose={() => setSellModalShow(false)}
      />
      <Container fluid>
        <Row>
          <Col md={{ span: 7, offset: 1 }}>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center p-3">
                <h2 className="mb-0 text-primary">{name}</h2>
                <form className="col-6 col-lg-8 text-end">
                  <div className="text-truncate">
                    <a
                      href={`${explorerBaseUrl[chainId!]}/address/${tokenId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tokenId}
                    </a>
                  </div>
                </form>
                {/* <div><Button variant="outline-primary"><i className="fa-regular fa-star"></i>&nbsp;Add to Watchlist</Button></div> */}
              </Card.Header>
              <Card.Body className="p-0">
                <ListGroup variant="flush">
                  <ListGroup.Item className="p-4">
                    <Card.Title className="my-4">CURRENT PRICING</Card.Title>
                    <Card className="my-2">
                      <Card.Body>
                        <div className="row">
                          <div className="col-4">
                            <small>HIGHEST BID</small>
                            <br />
                            <strong>
                              $
                              {highestBid
                                ? parseFloat(
                                    formatUnits(highestBid, decimals[chainId!])
                                  )
                                : 0}
                            </strong>
                          </div>
                          <div className="col-4">
                            <small>LOWEST ASK</small>
                            <br />
                            <strong>
                              $
                              {lowestAsk
                                ? parseFloat(
                                    formatUnits(lowestAsk, decimals[chainId!])
                                  )
                                : 0}
                            </strong>
                          </div>
                          <div className="col-4">
                            <small>LAST TX</small>
                            <br />
                            <strong>
                              {lastTxPrice
                                ? `$${parseFloat(formatUnits(lastTxPrice, decimals[chainId!]))}`
                                : 'N/A'}
                            </strong>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                    <div className="row">
                      <div className="col-lg-4 col-sm-12">
                        <Card.Title className="my-4">
                          MARKET ACTIVITY
                        </Card.Title>
                        <div className="d-flex flex-row mb-4">
                          <div className="border rounded p-2">
                            <strong>
                              {filteredListings?.length > 0
                                ? filteredListings.length
                                : 0}
                            </strong>{' '}
                            listings
                          </div>
                          <div className="mx-1"></div>
                          <div className="border rounded p-2">
                            <strong>
                              {tokenBidCount?.toString() > 0
                                ? tokenBidCount.toString()
                                : 0}
                            </strong>{' '}
                            bids
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-sm-12">
                        <Card.Title className="my-4">ABOUT</Card.Title>
                        <Card.Text>
                          {name === 'CapSign Inc.' && (
                            <>
                              CapSign provides cap table tokenization solutions.
                              The CapSign software suite enables on-chain
                              fundraising and early liquidity. The company is an
                              early-stage startup based in San Francisco, CA,
                              USA.
                            </>
                          )}
                          {name === 'Linqto, Inc.' && <></>}
                          {name === 'Ripple Labs, Inc.' && (
                            <>
                              Developer of a blockchain technology platform
                              designed to help financial institutions to send
                              money across borders, instantly, reliably, and for
                              fractions of a penny.
                            </>
                          )}
                        </Card.Text>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Footer>
                Contact <a href="mailto:matt@capsign.com">matt@capsign.com</a>{' '}
                for any questions on trading for {name}
              </Card.Footer>
            </Card>
          </Col>

          <Col md={{ span: 3 }}>
            <Card className="p-2 mt-3 mt-md-0">
              <Card.Body>
                <Card.Title>LIST FOR SALE</Card.Title>
                <Card.Text>
                  Create a listing for shares you have the ability to sell,
                  enabling buyers to bid directly on your listing.
                </Card.Text>
                <Button
                  onClick={() => {
                    verifyIdentity();
                    setSellModalShow(true);
                  }}
                  className="btn btn-primary w-100"
                >
                  Sell
                </Button>
              </Card.Body>
            </Card>
            <Card className="mt-3 p-2">
              <Card.Body>
                <Card.Title>MAKE A BID</Card.Title>
                <Card.Text>
                  Bid on an existing listing of stock for sale, or create your
                  own independent standing bid that any seller can accept.
                </Card.Text>
                <Button
                  onClick={() => {
                    verifyIdentity();
                    setBuyModalShow(true);
                  }}
                  className="btn btn-primary w-100"
                >
                  Buy
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={{ span: 10, offset: 1 }}>
            <Card>
              <Card.Header>
                <h4 className="mb-0">Market Activity</h4>
              </Card.Header>
              <Table bordered hover responsive className="mb-0">
                <thead>
                  <tr>
                    <th>SELLER</th>
                    <th>LISTING ID</th>
                    <th>QUANTITY</th>
                    <th>PRICE</th>
                    <th>TOTAL</th>
                    <th>TRANSFER TYPE</th>
                    <th>SHARE TYPE</th>
                    <th>BIDS</th>
                  </tr>
                </thead>
                <tbody>
                  {tokenListings === undefined
                    ? []
                    : filteredListings.map((listing: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>
                              <span
                                className="d-inline-block text-truncate"
                                style={{ maxWidth: '15rem' }}
                              >
                                {listing.owner}
                              </span>
                            </td>
                            <td>
                              <span
                                className="d-inline-block text-truncate"
                                style={{ maxWidth: '15rem' }}
                              >
                                {listing.id?.toString()}
                              </span>
                            </td>
                            <td>{listing.quantity?.toString()} shares</td>
                            <td>
                              $
                              {parseFloat(
                                formatUnits(listing.price, decimals[chainId!])
                              )}
                            </td>
                            <td>
                              $
                              {numbro(
                                parseFloat(
                                  formatUnits(
                                    BigInt(listing.quantity) * listing.price,
                                    decimals[chainId!]
                                  )
                                )
                              ).format(formatOptions)}
                            </td>
                            <td>Direct</td>
                            <td>
                              {(securityClasses[listing.securityId] &&
                                fromHex(
                                  securityClasses[listing.securityId],
                                  'string'
                                )) ||
                                'Loading...'}
                            </td>
                            <td>
                              <div className="badge bg-primary rounded-pill">
                                {listing.bidCount?.toString() || 0}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  {!tokenListings ||
                    (filteredListings?.length === 0 && (
                      <tr>
                        <td className="py-5 text-center" colSpan={12}>
                          No activity to display
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </Col>
          {/* Additional components/columns can be added here */}
        </Row>
      </Container>
    </div>
  );
}
