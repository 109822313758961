import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAppData } from '../../../contexts/AppDataContext';
import { useAuth } from '../../../contexts/AuthContext';

export default function AppInfo() {
  const { appData } = useAppData();
  const { auth } = useAuth();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  const authenticateWithGoogle = () => {
    axios({
      url: `${process.env.REACT_APP_API_URL}/v1/google_drive/auth/google`,
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'X-Account-Id': auth?.user.account_id,
      },
    })
      .then((response) => {
        window.location.href = response.data.result;
      })
      .catch((error) => {
        console.error('Error authenticating with Google:', error);
        setError('Failed to authenticate with Google');
      });
  };

  const fetchGoogleDriveFiles = () => {
    setLoading(true);
    axios
      .get('/drive/files')
      .then((response) => {
        setFiles(response.data.files);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching files:', error);
        setError('Failed to fetch files');
        setLoading(false);
      });
  };

  useEffect(() => {
    // Check if user is already authenticated
    axios
      .get('/auth/google/ authenticated')
      .then((response) => {
        setAuthenticated(response.data.authenticated);
      })
      .catch((error) => {
        console.error('Error checking authentication status:', error);
      });
  }, []);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: appData?.app_long_description || '',
        }}
      />
      {!authenticated ? (
        <button onClick={authenticateWithGoogle}>
          Authenticate with Google
        </button>
      ) : (
        <button onClick={fetchGoogleDriveFiles} disabled={loading}>
          {loading ? 'Loading...' : 'Load Files from Google Drive'}
        </button>
      )}
      {error && <p>Error: {error}</p>}
      <div>
        {files.length > 0 ? (
          <ul>
            {files.map((file: any) => (
              <li key={file.id}>{file.name}</li>
            ))}
          </ul>
        ) : (
          <p>No files to display.</p>
        )}
      </div>
    </>
  );
}
