import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { contracts } from '../../../constants';
import { parseUnits, hexToNumber, verifyTypedData, Abi, slice } from 'viem';
import { useError } from '../../../contexts/ErrorContext';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from 'wagmi';
import {
  decimals,
  subscriptionToken,
  subscriptionTokenAddress,
} from '../../../utils';
import { ethers } from 'ethers';

interface InvestModalProps {
  show: boolean;
  handleClose: () => void;
  contractAddress: string;
  offeringId: string;
  federalExemption: string;
  offeringType: string;
  onInvestmentSuccess: (investmentAmount: number) => void;
}

const InvestModal: React.FC<InvestModalProps> = ({
  show,
  handleClose,
  contractAddress,
  offeringId,
  federalExemption,
  offeringType,
  onInvestmentSuccess,
}) => {
  const [investmentAmount, setInvestmentAmount] = useState<number | string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('ach');
  const { setError } = useError();
  const { auth } = useAuth();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { writeContractAsync: permit } = useWriteContract();
  const [linkToken, setLinkToken] = React.useState(null);
  const [expiration, setExpiration] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const handleInvestmentAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInvestmentAmount(e.target.value);
  };

  const handlePaymentMethodChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPaymentMethod(e.target.value);
    if (e.target.value === 'wire') await createLinkToken();
  };

  const { writeContractAsync: approve } = useWriteContract();
  const { writeContractAsync: investContract } = useWriteContract();

  const createPlaidAccessToken = React.useCallback(
    async (publicToken: any, metadata: any) => {
      await axios({
        url: `http://localhost:8080/v1/plaid/exchange_public_token`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
        data: {
          public_token: publicToken,
          metadata,
        },
      });
    },
    [auth]
  );

  const createLinkToken = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/plaid/create_link_token`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
      });
      setExpiration(response.data.expration);
      setLinkToken(response.data.link_token);
    } catch (error) {
      setError(error);
      console.error(error);
    }
  };

  const handleCryptoPayment = async () => {
    try {
      setLoading(true);
      const paymentTokenAddress = subscriptionTokenAddress[chainId];
      const tokenId = 1;
      const usdcAbi = contracts[chainId!].USDC.abi as Abi;

      // Create allowance for payment token (assuming ERC20 with permit feature)
      const name: any = await publicClient?.readContract({
        address: paymentTokenAddress as `0x${string}`,
        abi: usdcAbi,
        functionName: 'name',
      })!;

      const nonce: any = await publicClient?.readContract({
        address: paymentTokenAddress as `0x${string}`,
        abi: usdcAbi,
        functionName: 'nonces',
        args: [account],
      });

      // Set the domain parameters
      const domain: any = {
        name,
        // version: process.env.NODE_ENV === 'development' ? '1' : '2', // USDC uses version "2"
        version: '2',
        chainId: chainId,
        verifyingContract: paymentTokenAddress,
      };

      // Set the permit type parameters
      const types = {
        EIP712Domain: [
          {
            name: 'name',
            type: 'string',
          },
          {
            name: 'version',
            type: 'string',
          },
          {
            name: 'chainId',
            type: 'uint256',
          },
          {
            name: 'verifyingContract',
            type: 'address',
          },
        ],
        Permit: [
          {
            name: 'owner',
            type: 'address',
          },
          {
            name: 'spender',
            type: 'address',
          },
          {
            name: 'value',
            type: 'uint256',
          },
          {
            name: 'nonce',
            type: 'uint256',
          },
          {
            name: 'deadline',
            type: 'uint256',
          },
        ],
      };

      const deadline = Math.floor(Date.now() / 1000) + 3600; // 1 hour from now
      const amount = parseUnits(
        investmentAmount.toString(),
        decimals[chainId!]
      );

      let allowance = await publicClient?.readContract({
        address: paymentTokenAddress as `0x${string}`,
        abi: usdcAbi,
        functionName: 'allowance',
        args: [account, contractAddress],
      });

      const terms = await publicClient?.readContract({
        address: contractAddress as `0x${string}`,
        abi: contracts[chainId!].SAFE.abi as Abi,
        functionName: 'safeTerms',
        args: [tokenId],
      });

      if (BigInt(allowance as any) < amount) {
        // Approve the SAFE contract to spend USDC on behalf of the user
        const approveTxHash = await approve({
          address: paymentTokenAddress as `0x${string}`,
          abi: usdcAbi,
          functionName: 'approve',
          args: [contractAddress, amount],
        });

        // Wait for the approval transaction to be mined
        await publicClient?.waitForTransactionReceipt({
          hash: approveTxHash,
        });
      }

      const gas = await publicClient?.estimateContractGas({
        address: contractAddress as `0x${string}`,
        abi: contracts[chainId!].SAFE.abi as Abi,
        functionName: 'invest',
        args: [tokenId, amount, true],
        account,
      });

      // Call the `invest` function on the SAFE contract
      const txHash = await investContract({
        address: contractAddress as `0x${string}`,
        abi: contracts[chainId!].SAFE.abi as Abi,
        functionName: 'invest',
        args: [tokenId, amount, true],
        gas,
      });

      await publicClient?.waitForTransactionReceipt({
        hash: txHash,
      });

      // Set the permit type values
      const message = {
        owner: account,
        spender: contractAddress,
        value: amount,
        nonce,
        deadline,
      };

      // Sign the permit type data with the user's private key
      const signature: any = await walletClient?.signTypedData({
        account,
        message,
        domain,
        primaryType: 'Permit',
        types,
      });

      const [r, s, v] = [
        slice(signature, 0, 32),
        slice(signature, 32, 64),
        slice(signature, 64, 65),
      ];

      // Verify the permit type data with the signature
      const valid = await verifyTypedData({
        address: account!,
        domain,
        types,
        primaryType: 'Permit',
        message: message,
        signature,
      });

      if (!valid) {
        throw new Error('Invalid signature');
      }

      // const gas = await publicClient?.estimateContractGas({
      //   address: contractAddress as `0x${string}`,
      //   abi: contracts[chainId!].SAFE.abi as Abi,
      //   functionName: 'investWithPermit',
      //   args: [tokenId, amount, true, deadline, v, r, s],
      // });

      // const txHash = await investContract({
      //   address: contractAddress as `0x${string}`,
      //   abi: contracts[chainId!].SAFE.abi as Abi,
      //   functionName: 'investWithPermit',
      //   args: [tokenId, amount, true, deadline, v, r, s],
      //   gas,
      // });
      // console.log('txHash', txHash);

      // await publicClient?.waitForTransactionReceipt({
      //   hash: txHash,
      // });

      // Success: update offering in the database with amount invested
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/investments`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
          data: {
            tx_hash: txHash,
            amount: Number(investmentAmount),
            token: subscriptionTokenAddress[chainId],
            token_id: tokenId,
          },
        }
      );

      const { result } = response.data;

      // Call parent component's success callback
      onInvestmentSuccess(parseFloat(investmentAmount.toString()));
    } catch (error: any) {
      if (error.code === 'CALL_EXCEPTION') {
        // Decode the revert reason if available
        const errorMessage = error.data.message;
        console.log('Transaction reverted with:', errorMessage);

        // Example: Check for specific custom errors
        if (errorMessage.includes('BelowMinimumInvestment')) {
          // Handle below minimum investment error
          console.error('Investment amount is below minimum');
        } else if (errorMessage.includes('InvestmentCapExceeded')) {
          // Handle investment cap exceeded error
          console.error('Investment cap exceeded');
        } else if (errorMessage.includes('PaymentFailed')) {
          // Handle payment failed error
          console.error('Payment transfer failed');
        } else {
          // Handle unrecognized custom error
          console.error('Unrecognized custom error:', errorMessage);
        }
      }
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleWirePayment = async () => {
    // const request: TransferAuthorizationCreateRequest = {
    //   access_token: 'access-sandbox-71e02f71-0960-4a27-abd2-5631e04f2175',
    //   account_id: '3gE5gnRzNyfXpBK5wEEKcymJ5albGVUqg77gr',
    //   type: 'debit',
    //   network: 'ach',
    //   amount: '12.34',
    //   ach_class: 'ppd',
    //   user: {
    //     legal_name: 'Anne Charleston',
    //   },
    // };
    try {
      // const response = await client.transferAuthorizationCreate(request);
      // const authorizationId = response.data.authorization.id;
    } catch (error) {
      // handle error
    }
    return console.log('Success');
  };

  const handleAchPayment = async () => {
    try {
      alert(
        'TODO: Call backend API to tell North Capital to move external account funds'
      );
    } catch (error) {
      // handle error
    }
    return console.log('Success');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (paymentMethod === 'crypto') handleCryptoPayment();
    if (paymentMethod === 'wire') handleWirePayment();
    if (paymentMethod === 'ach') handleAchPayment();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Invest</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPaymentMethod">
            <Form.Label>Payment Method</Form.Label>
            <Form.Select
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
              required
            >
              <option value="ach">ACH</option>
              <option value="creditCard" disabled>
                Credit Card
              </option>
              <option value="crypto">Crypto</option>
              <option value="wire">Wire</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="formInvestmentAmount" className="mt-3">
            <Form.Label>Investment Amount</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                placeholder="Enter amount"
                value={investmentAmount}
                onChange={handleInvestmentAmountChange}
                required
              />
              <InputGroup.Text>
                {paymentMethod === 'crypto'
                  ? subscriptionToken[chainId]
                  : 'USD'}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <br />
          <div className="text-end">
            <Button
              variant="primary"
              type="submit"
              className="mt-3"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Submit Payment'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InvestModal;
