import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

export default function Login() {
  const [email, setEmail] = useState<string>();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const backToLogin = async () => {
    setSubmitted(false); // reset
    navigate('/login');
  };

  const requestResetToken = async () => {
    if (!email) return alert('Please enter email');
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/password_reset_requests`,
        {
          method: 'POST',
          data: { email },
        }
      );
      if (response.status === 200) {
        setSubmitted(true);
      }
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401) alert(error.response.data.message);
      else alert(error.message);
    }
  };

  const resetPassword = async () => {
    if (password !== confirmPassword) {
      return alert('Passwords do not match.');
    }

    try {
      let response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/users/reset_password`,
        {
          method: 'POST',
          data: {
            password,
            token,
          },
        }
      );
      if (response.status === 200) {
        alert('Success');
        navigate('/login');
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div className="vh-100 bg-primary">
      <div className="h-100 d-flex flex-column align-items-center justify-content-center mx-2">
        <div className="col-lg-4 col-12">
          <div className="d-flex justify-content-center align-items-center">
            <Card className="w-100">
              <Card.Body>
                <div className="row mt-3">
                  <div className="col-12 text-center">
                    {/* <img
                      src={logo}
                      alt="CapSign"
                      className="img-fluid"
                    /> */}
                    <h1 className="my-4">Reset Password</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="offset-2 col-8">
                    <hr />
                  </div>
                </div>
                {!token &&
                  (!submitted ? (
                    <div className="row mt-5 mb-3">
                      <div className="col-lg-6 offset-lg-3">
                        <form>
                          <div className="mb-3">
                            <label className="form-label">Email address</label>
                            <input
                              type="email"
                              className="form-control"
                              onChange={(event) => setEmail(event.target.value)}
                            />
                          </div>
                          <div className="d-grid gap-2 mb-2">
                            <button
                              type="button"
                              onClick={requestResetToken}
                              className="btn btn-primary"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  ) : (
                    <div className="row m-3">
                      <div className="col">
                        An email has been sent to the email if it exists in our
                        system.
                      </div>
                    </div>
                  ))}
                {token && (
                  <div className="row mt-5 mb-3">
                    <div className="col-lg-6 offset-lg-3">
                      <form>
                        <div className="mb-3">
                          <label className="form-label">Password</label>
                          <input
                            type="password"
                            className="form-control"
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Confirm Password</label>
                          <input
                            type="password"
                            className="form-control"
                            onChange={(event) =>
                              setConfirmPassword(event.target.value)
                            }
                          />
                        </div>
                        <div className="d-grid gap-2 mb-2">
                          <button
                            type="button"
                            onClick={resetPassword}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                <div className="row mb-3">
                  <div className="col-12 text-center">
                    <div>
                      <Button variant="link" onClick={backToLogin}>
                        <small>Back to Login</small>
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
