import React, { createContext, useContext, useState, ReactNode } from 'react';

type Message = string;

type ToastContextType = {
  message: Message;
  setMessage: (value: React.SetStateAction<Message>) => void;
  show: boolean;
  setShow: (value: React.SetStateAction<boolean>) => void;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within an ToastProvider');
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [message, setMessage] = useState<Message>('');
  const [show, setShow] = useState<boolean>(false);

  const updateMessage: ToastContextType['setMessage'] = (value) => {
    setMessage(value);
    setShow(true); // Automatically show the toast whenever the message is set
  };

  const value: ToastContextType = {
    message,
    setMessage: updateMessage,
    show,
    setShow,
  };

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};
