import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

interface MobileContextType {
  mobile: boolean;
  setMobile: (mobile: boolean) => void;
}

const MobileContext = createContext<MobileContextType>({
  mobile: false,
  setMobile: () => {},
});

export const useMobile = () => useContext(MobileContext);

interface MobileProviderProps {
  children: ReactNode; // ReactNode is a type that includes anything that can be rendered in React (strings, numbers, elements, etc.)
}

export const MobileProvider = ({ children }: MobileProviderProps) => {
  const [mobile, setMobile] = useState(false);

  /**
   * Detect CapSign mobile app
   */
  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
    const isMobile = /CapSignMobileApp/i.test(navigator.userAgent);
    setMobile(isMobile);
  }, []);

  return (
    <MobileContext.Provider value={{ mobile, setMobile }}>
      {children}
    </MobileContext.Provider>
  );
};
