import { Alert, Modal } from 'react-bootstrap';

export default function WireTransfer() {
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <>
      <h5>Wire transfer instructions</h5>
      <p>
        To add funds, send a wire from your bank to CapSign using the details
        below. We'll email you when the transfer is complete.
      </p>
      <Alert variant="primary">
        Make sure the name on your bank account exactly matches your name on
        CapSign.
      </Alert>
      <div className="mb-3">
        <label className="form-label">Recipient name</label>
        <div className="d-flex">
          <input
            readOnly
            className="form-control-plaintext text-muted"
            value="CapSign Inc."
          />
          <button
            onClick={() => copyToClipboard('World Exchange Corp.')}
            className="btn btn-link"
            type="button"
          >
            <i className="fas fa-copy" />
          </button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Recipient address</label>
        <div className="d-flex">
          <input
            readOnly
            className="form-control-plaintext text-muted"
            value="101 Polk Street #302, San Francisco, CA 94102"
          />
          <button
            onClick={() =>
              copyToClipboard('101 Polk Street #302, San Francisco, CA 94102')
            }
            className="btn btn-link"
            type="button"
          >
            <i className="fas fa-copy" />
          </button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Account number</label>
        <div className="d-flex">
          <input
            readOnly
            className="form-control-plaintext text-muted"
            value="############"
          />
          <button
            onClick={() => copyToClipboard('############')}
            className="btn btn-link"
            type="button"
          >
            <i className="fas fa-copy" />
          </button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Routing number</label>
        <div className="d-flex">
          <input
            readOnly
            className="form-control-plaintext text-muted"
            value="#########"
          />
          <button
            onClick={() => copyToClipboard('#########')}
            className="btn btn-link"
            type="button"
          >
            <i className="fas fa-copy" />
          </button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Bank name</label>
        <div className="d-flex">
          <input
            readOnly
            className="form-control-plaintext text-muted"
            value="JPMorgan Chase Bank National Association"
          />
          <button
            onClick={() =>
              copyToClipboard('JPMorgan Chase Bank National Association')
            }
            className="btn btn-link"
            type="button"
          >
            <i className="fas fa-copy" />
          </button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Bank address</label>
        <div className="d-flex">
          <input
            readOnly
            className="form-control-plaintext text-muted"
            value="270 Park Avenue, 43rd floor New York, NY 10017"
          />
          <button
            onClick={() =>
              copyToClipboard('270 Park Avenue, 43rd floor New York, NY 10017')
            }
            className="btn btn-link"
            type="button"
          >
            <i className="fas fa-copy" />
          </button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Bank country</label>
        <div className="d-flex">
          <input
            readOnly
            className="form-control-plaintext text-muted"
            value="United States"
          />
          <button
            onClick={() => copyToClipboard('United States')}
            className="btn btn-link"
            type="button"
          >
            <i className="fas fa-copy" />
          </button>
        </div>
      </div>
      <Modal.Footer>
        <small>
          Please do not send funds via ACH. An actual wire transfer must be
          submitted. Funds will usually be credited to your Linqto account on
          the same day if submitted before 1pm PT. Otherwise, funds will be
          credited on the next business day.
        </small>
      </Modal.Footer>
    </>
  );
}
