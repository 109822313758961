import { useEffect } from 'react';
import { useOnboarding } from '../../contexts/OnboardingContext';
import compliance from './compliance.png';
import { useNavigate } from 'react-router-dom';

export default function VerifyIdentity() {
  const { onboarding } = useOnboarding();
  const navigate = useNavigate();

  useEffect(() => {
    if (onboarding?.onboarded) navigate('/portfolio');
  }, []);

  return (
    <div className="my-2">
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <hr />
          <div className="text-center mt-4">
            <img className="img-fluid" width={200} src={compliance} />
          </div>
          <p className="text-center my-5">
            Awaiting review. We'll email you with an update!
          </p>
        </div>
      </div>
    </div>
  );
}
