import {
  ContractFunctionExecutionError,
  ContractFunctionExecutionErrorType,
  ContractFunctionRevertedError,
  decodeErrorResult,
} from 'viem';
import { Abi } from 'abitype';
import {
  SimulateContractErrorType,
  WriteContractErrorType,
  WaitForTransactionReceiptErrorType,
} from '@wagmi/core';
import { BaseError } from 'wagmi';

interface ParseEvmTransactionLogArgs<TAbi extends Abi | readonly unknown[]> {
  abi: TAbi;
  error:
    | ContractFunctionExecutionErrorType
    | SimulateContractErrorType
    | WriteContractErrorType
    | WaitForTransactionReceiptErrorType
    | null;
}

export const decodeEvmTransactionErrorResult = <
  TAbi extends Abi | readonly unknown[],
>({
  error,
  abi,
}: ParseEvmTransactionLogArgs<TAbi>) => {
  try {
    if (
      error instanceof BaseError ||
      error instanceof ContractFunctionExecutionError
    ) {
      const revertError = error.walk(
        (err) => err instanceof ContractFunctionRevertedError
      );
      if (revertError instanceof ContractFunctionRevertedError) {
        const errorName = revertError.data?.errorName ?? '';

        if (errorName) {
          // This error is already decoded
          const decodedError = revertError.data;
          return { error, decodedError };
        }
      }
    }

    if (
      error?.cause instanceof ContractFunctionRevertedError &&
      error?.cause?.signature
    ) {
      const decodedError = decodeErrorResult({
        abi,
        data: error?.cause?.signature,
      });

      return { decodedError, error };
    }
    return { error, decodedError: undefined };
  } catch (_) {
    return { error, decodedError: undefined };
  }
};
