import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo-transparent.png';
import './index.css';

const LandingNav = () => {
  return (
    <Navbar expand="lg" className="py-3" sticky="top" bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand
          href="/"
          className="d-flex justify-content-center align-items-center"
        >
          <img
            src={logo}
            alt="Logo"
            width="35"
            className="d-inline-block align-top"
          />
          &nbsp;CapSign
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {/* <Nav className="me-auto d-flex justify-content-center align-items-center">
            <NavLink
              to="/platform"
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
            >
              <button className="btn btn-dark">Platform</button>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
              to="/solutions"
            >
              <button className="btn btn-dark">Solutions</button>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
              to="/company"
            >
              <button className="btn btn-dark">Company</button>
            </NavLink>
          </Nav> */}
          <Nav className="ms-auto d-flex justify-content-center align-items-center">
            {/* <Nav.Link
              className="mx-2 text-light"
              href="https://docs.capsign.com"
              target="_blank"
            >
              Docs&nbsp; <span className="fa fa-external-link"></span>
            </Nav.Link> */}
            {/* 
              <Nav.Link className="mx-2" as={NavLink} to="/blog">
                Blog
              </Nav.Link>
              <Nav.Link className="mx-2" href="https://wellfound.com/jobs/2815644-chief-financial-officer" target="_blank">
                  Careers&nbsp;<span className="badge text-bg-primary rounded">We're Hiring!</span>
              </Nav.Link>
            */}
            <NavLink
              to="/login"
              className={({ isActive }) =>
                isActive ? 'nav-link active' : 'nav-link'
              }
            >
              <button className="btn btn-dark">Sign In</button>
            </NavLink>
            <NavLink
              className="mx-2 my-2"
              target="_blank"
              to="https://6v1qwdajlxv.typeform.com/to/PrLYKTM9"
            >
              <button className="btn btn-primary">Sign Up</button>
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default LandingNav;
