import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useOnboarding } from '../../../contexts/OnboardingContext';

export default function MailingAddress(props: any) {
  const [line1, setLine1] = useState<string>('');
  const [line2, setLine2] = useState<string>('');
  const [line3, setStreet3] = useState<string>('');
  const [locality, setLocality] = useState<string>('');
  const [sublocality, setSublocality] = useState<string>('');
  const [adminstrativeAreaLevel1, setAdministrativeAreaLevel1] =
    useState<string>('');
  const [adminstrativeAreaLevel2, setAdministrativeAreaLevel2] =
    useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [isSameAsPrincipal, setIsSameAsPrincipal] = useState<boolean>(false);

  const { auth } = useAuth();
  const { onboarding, setOnboarding } = useOnboarding();
  const navigate = useNavigate();

  const success =
    onboarding?.entity?.mailing_address?.line1 &&
    onboarding?.entity?.mailing_address?.locality &&
    onboarding?.entity?.mailing_address?.postal_code;

  /**
   * Fetch onboarding_status when checkbox checked because new onboarding data is not loading on navigation
   * TODO: Fix data loading on page load
   */
  useEffect(() => {
    (async () => {
      try {
        if (auth?.token) {
          const response = await axios(
            `${process.env.REACT_APP_API_URL}/v1/users/me/onboarding_status`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-ID': auth?.user.account_id,
              },
            }
          );
          setOnboarding(response.data);
        }
      } catch (error) {
        console.log(error);
        // Consider clearing localStorage if needed
        // localStorage.removeItem('capsign__onboarding');
      }
    })();
  }, [isSameAsPrincipal]);

  useEffect(() => {
    if (isSameAsPrincipal && onboarding?.entity?.principal_address) {
      // Assuming principal_address structure is similar to mailing_address
      const {
        line1,
        line2,
        locality,
        postal_code,
        administrative_area_level_1,
        country_code,
      } = onboarding.entity.principal_address;
      setLine1(line1);
      setLine2(line2);
      setLocality(locality);
      setPostalCode(postal_code);
      setAdministrativeAreaLevel1(administrative_area_level_1);
      setCountryCode(country_code);
      // You might need additional fields depending on your data structure
    }
  }, [isSameAsPrincipal, onboarding?.entity?.mailing_address]);

  const onSubmit = async (event: any) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (success) {
      return navigate('/onboarding/entity/registration-number');
    }

    try {
      const headers = {
        Authorization: `Bearer ${auth?.token}`,
        'X-Account-Id': auth?.user.account_id,
      };
      const data = {
        line1,
        line2,
        line3,
        locality,
        sublocality,
        administrative_area_level_1: adminstrativeAreaLevel1,
        administrative_area_level_2: adminstrativeAreaLevel2,
        country,
        country_code: countryCode,
        postal_code: postalCode,
      };
      // TODO: Use PUT for first operation, PATCH for subsequent edits
      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/business_entities/me/mailing_address`,
        data,
        {
          headers,
        }
      );
      return navigate('/onboarding/entity/registration-number');
    } catch (error: any) {
      console.error('Error creating account:', error);
      // Handle error case, possibly setting an error state and displaying it to the user
    }
  };

  const addressFormForCountry = (countryCode: string) => {
    switch (countryCode) {
      case 'US':
        return (
          <>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="inputState">State</label>
                <select
                  id="inputState"
                  className="form-select"
                  onChange={(e) => setAdministrativeAreaLevel1(e.target.value)}
                  value={
                    onboarding?.entity?.mailing_address
                      ?.administrative_area_level_1 || adminstrativeAreaLevel1
                  }
                >
                  <option value="">Select a state</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="inputZip">Zip Code</label>
                <input
                  type="text"
                  id="inputZip"
                  className="form-control"
                  onChange={(e) => setPostalCode(e.target.value)}
                  defaultValue={postalCode}
                />
              </div>
            </div>
          </>
        );
      case 'GB':
        return (
          <>
            <div className="mb-3">
              <label htmlFor="inputPostalCode">Postcode</label>
              <input
                type="text"
                id="inputPostalCode"
                className="form-control"
                onChange={(e) => setPostalCode(e.target.value)}
                defaultValue={postalCode}
              />
            </div>
          </>
        );
      // Add other countries and their specific form fields as needed
      default:
        return null;
    }
  };

  return (
    <div className="row">
      <Form className="col-lg-6 offset-lg-3 col-md-12 pb-5" onSubmit={onSubmit}>
        {/* Entity Address Form */}
        <h5 className="mt-4">Mailing Address</h5>
        <p className="text-muted">The corporate mailing address</p>

        <FormGroup className="mb-3">
          <Form.Check
            type="checkbox"
            label="Same as principal address"
            checked={isSameAsPrincipal}
            onChange={(e) => setIsSameAsPrincipal(e.target.checked)}
          />
        </FormGroup>

        {/* Country */}
        <div className="mb-3">
          <label className="form-label">Country</label>
          <select
            id="country"
            className="form-select"
            onChange={(event) => setCountryCode(event.target.value)}
            value={
              onboarding?.entity?.mailing_address?.country_code || countryCode
            }
          >
            <option value="">Select a country</option>
            <option value="AU">Australia</option>
            <option value="CA">Canada</option>
            <option value="KY">Cayman Islands</option>
            <option value="GB">United Kingdom</option>
            <option value="US">United States of America</option>
          </select>
        </div>

        {(onboarding?.entity?.mailing_address?.country_code || countryCode) && (
          <>
            {/* Street Line 1 Field */}
            <div className="mb-3">
              <label className="form-label">Street Line 1</label>
              <input
                type="text"
                className="form-control"
                onChange={(event) => setLine1(event.target.value)}
                defaultValue={
                  onboarding?.entity?.mailing_address?.line1 || line1
                }
              />
            </div>

            {/* Street Line 2 Field (optional) */}
            <div className="mb-3">
              <label className="form-label">Street Line 2 (optional)</label>
              <input
                type="text"
                className="form-control"
                onChange={(event) => setLine2(event.target.value)}
                defaultValue={
                  onboarding?.entity?.mailing_address?.line2 || line2
                }
              />
            </div>

            {/* City Field */}
            <div className="row">
              <div className="col-md-5">
                <label htmlFor="inputCity">City</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(event) => setLocality(event.target.value)}
                  id="inputCity"
                  defaultValue={
                    onboarding?.entity?.mailing_address?.locality || locality
                  }
                />
              </div>

              {/* State + Postal Code Fields */}
              <div className="col">
                {addressFormForCountry(
                  onboarding?.entity?.mailing_address?.country_code ||
                    countryCode
                )}
              </div>
            </div>
          </>
        )}

        <Button
          variant="primary"
          className="mt-5 w-100"
          type="submit"
          disabled={!success && !(line1 && locality && postalCode)}
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}
