import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import promo from './promo.png';

function DownloadApp() {
  return (
    <div className="App d-flex align-items-center p-3">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} md={6}>
            <img src={promo} alt="App Preview" className="img-fluid" />
          </Col>
          <Col xs={12} md={6} className="my-5">
            <h1>The CapSign Mobile App</h1>
            <p className="pe-5">Trade pre-IPO shares on Ethereum.</p>
            <a
              className="btn btn-lg btn-primary"
              href="https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fxenon.exchange"
            >
              Use the App
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DownloadApp;
