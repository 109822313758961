import React, { useEffect } from 'react';

export default function OfferingVideo() {
  /**
   * Fetch the current offering video
   */
  useEffect(() => {}, []);

  return (
    <div className="text-center mt-5 mb-5">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/BMEKgSYLK0o?si=2CpxhGncxEOhR38v"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
}
