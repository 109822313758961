import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { contracts } from '../../../constants';
import { useLocation } from 'react-router-dom';
import { useError } from '../../../contexts/ErrorContext';
import {
  useReadContract,
  useWriteContract,
  useWaitForTransactionReceipt,
  useChainId,
  usePublicClient,
  useAccount,
} from 'wagmi';
import { Abi, decodeEventLog } from 'viem';
import { InputGroup, Spinner } from 'react-bootstrap';
import { ethers } from 'ethers';
import VestingSchedule from './VestingSchedule';

enum Operation {
  Grant = 1,
  Cancel = 2,
}

const ManageCertificateForm = ({
  showModal,
  setShowModal,
  identity,
}: {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  identity: any;
}) => {
  const { auth } = useAuth();
  const { setError } = useError();
  const [shareClass, setShareClass] = useState<any>();
  const [capTable, setCapTable] = useState<any>();
  const [securityLegend, setSecurityLegend] = useState<string>(
    'THE SECURITIES REPRESENTED BY THIS CERTIFICATE HAVE NOT BEEN REGISTERED UNDER THE SECURITIES ACT OF 1933, AS AMENDED (THE "ACT"), OR ANY STATE SECURITIES LAWS. THESE SECURITIES MAY NOT BE SOLD, TRANSFERRED, PLEDGED, OR OTHERWISE DISPOSED OF IN THE ABSENCE OF AN EFFECTIVE REGISTRATION STATEMENT UNDER THE ACT AND ANY APPLICABLE STATE SECURITIES LAWS OR AN OPINION OF COUNSEL SATISFACTORY TO THE COMPANY THAT SUCH REGISTRATION IS NOT REQUIRED.'
  );
  const [federalExemption, setFederalExemption] = useState<string>('');
  const [tokenId, setTokenId] = useState<any>('');
  const [operation, setOperation] = useState(Operation.Grant);
  const [tokenAmount, setTokenAmount] = useState('');
  const [cashPaid, setCashPaid] = useState('');
  const [costBasis, setCostBasis] = useState('');
  const [pricePerShare, setPricePerShare] = useState('');
  const [hasVesting, setHasVesting] = useState(false);
  const [vestingData, setVestingData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const location = useLocation();
  const { address } = useAccount();

  const tokenContract = contracts[chainId!]?.RestrictedStock;
  const identityRegistryContract = contracts[chainId!]?.IdentityRegistry;

  /**
   * Fetch share class
   */
  useEffect(() => {
    (async () => {
      if (!location.state?.shareClass) {
        try {
          const response = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/business_entities/me/share_classes/${location.state?.shareClass.class_id}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          setShareClass(response.data.result);
        } catch (error) {
          setError(error);
        }
      }

      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/cap_tables/me`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        setCapTable(response.data.result);
      } catch (error) {
        setError(error);
      }
    })();
  }, [auth?.token, chainId]);

  const { data: identityRegistryAddress } = useReadContract({
    address: location.state?.shareClass.token_address as `0x${string}`,
    abi: tokenContract.abi as Abi,
    functionName: 'identityRegistry',
  });

  const { data: isVerified } = useReadContract({
    address: identityRegistryAddress as `0x${string}`,
    abi: identityRegistryContract.abi as Abi,
    functionName: 'isVerified',
    args: [identity?.wallet_address],
  });

  const { writeContractAsync: grant } = useWriteContract();
  const { writeContractAsync: cancel } = useWriteContract();
  const { data: issueReceipt, isLoading: isIssueLoading } =
    useWaitForTransactionReceipt();
  const { data: cancelReceipt, isLoading: isCancelLoading } =
    useWaitForTransactionReceipt();

  // Calculate price per share whenever tokenAmount or cashPaid changes
  useEffect(() => {
    const amount = parseFloat(tokenAmount);
    const cash = parseFloat(cashPaid);
    if (amount > 0 && cash > 0) {
      const calculatedPrice = cash / amount;
      setPricePerShare(calculatedPrice.toString());
    } else {
      setPricePerShare('');
    }
  }, [tokenAmount, cashPaid]);

  /**
   * @function handleOperation
   * @param event
   */
  const handleOperation = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (operation === Operation.Grant) await handleGrant();
    if (operation === Operation.Cancel) await handleCancel();
  };

  /**
   * @function handleGrant
   * @returns
   */
  const handleGrant = async () => {
    setLoading(true);
    try {
      if (!isVerified) {
        alert('Please register your identity with this token.');
        setLoading(false);
        return;
      }

      const uri = 'https://api.capsign.com/v1/metadata/test.json';
      let vestingTerms = {
        name: 'No Vesting',
        description: 'No Vesting',
        allocationType: 'CUMULATIVE_ROUNDING',
        conditions: [],
      };

      let startTime = vestingData!.startDate
        ? Math.floor(vestingData!.startDate!.getTime() / 1000)
        : 0;

      if (hasVesting) {
        startTime = Math.floor(vestingData.startDate!.getTime() / 1000);
        vestingTerms = {
          name: vestingData.vestingName,
          description: vestingData.vestingDescription,
          allocationType: vestingData.allocationType,
          conditions: vestingData.vestingConditions.map((condition: any) => ({
            ...condition,
            triggerDate:
              condition.triggerType === 'date'
                ? Math.floor(new Date(condition.triggerDate).getTime() / 1000)
                : undefined,
          })),
        };
      }

      const moduleData = {
        vestingData: {
          totalAmount: Number(tokenAmount),
          startDate: startTime,
          vestingTerms: vestingTerms,
        },
        accreditedVerificationData: { isAccredited: true },
      };

      const ownedAtGrant = true; // RSA

      const vestingConditionType =
        'tuple(string id,string description,uint256 portionNumerator,uint256 portionDenominator,uint256 quantity,string triggerType,uint256 triggerDate,string relativeToConditionId,string[] nextConditionIds)';

      // Encode moduleData
      const moduleDataEncoded = new ethers.utils.AbiCoder().encode(
        [
          'tuple(' +
            'tuple(uint256 totalAmount, uint256 startDate, ' +
            `tuple(string name, string description, string allocationType, ${vestingConditionType}[] conditions) vestingTerms` +
            ') vestingData,' +
            'tuple(bool isAccredited) accreditedVerificationData' +
            ')',
        ],
        [moduleData]
      );

      const params = [
        identity.wallet_address,
        BigInt(tokenAmount),
        uri,
        moduleDataEncoded,
        ownedAtGrant,
      ];

      const gas = await publicClient?.estimateContractGas({
        args: params,
        address: location.state?.shareClass.token_address as `0x${string}`,
        abi: tokenContract.abi as Abi,
        functionName: 'grant',
        account: address,
      });

      const txHash = await grant({
        args: params,
        address: location.state?.shareClass.token_address as `0x${string}`,
        abi: tokenContract.abi as Abi,
        functionName: 'grant',
        account: address,
        chainId,
        gas,
      });

      const issueReceipt = await publicClient?.waitForTransactionReceipt({
        hash: txHash,
      });

      if (issueReceipt?.status === 'reverted') {
        return setError('Transaction reverted');
      }

      // Get StockGranted event arg here, save it in database below
      const eventAbiItem: any = tokenContract.abi.find(
        (item: any) => item.name === 'StockGranted'
      )!;

      let _tokenId: bigint | undefined;

      if (issueReceipt?.logs) {
        for (const log of issueReceipt.logs) {
          try {
            const event = decodeEventLog({
              abi: [eventAbiItem],
              eventName: 'StockGranted',
              data: log.data,
              topics: log.topics,
            });
            _tokenId = ((event as any).args as any).tokenId;
            break;
          } catch (error) {
            // Not the event we are looking for
          }
        }
      }

      setTokenId(_tokenId);

      if (!isIssueLoading) {
        // Create certificate
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/cap_tables/${capTable.cap_table_id}/certificates`,
          {
            share_class: location.state?.shareClass.class_id,
            quantity: tokenAmount,
            available_quantity: tokenAmount,
            federal_exemption: federalExemption,
            legend: securityLegend,
            account_id: identity.account_id,
            tx_hash: txHash,
            token_id: Number(_tokenId),
            price_per_share: pricePerShare,
            cash_paid: cashPaid,
            cost_basis: costBasis,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );

        setTokenAmount('');
        setPricePerShare('');
        setShowModal(false);
        setLoading(false);
        alert(`Transaction success! Tx hash: ${issueReceipt?.transactionHash}`);
      }
    } catch (e) {
      setError(e);
      console.error(e);
      setLoading(false);
    }
  };

  /**
   * @function handleCancel
   * @returns
   */
  const handleCancel = async () => {
    setLoading(true);
    try {
      if (!isVerified) {
        alert('Please register your identity with this token.');
        setLoading(false);
        return;
      }

      const params = [identity.wallet_address, tokenId, tokenAmount];

      const cancelReceipt = await cancel({
        address: location.state?.shareClass.token_address as `0x${string}`,
        abi: tokenContract.abi as Abi,
        args: params,
        functionName: 'cancel',
      });

      if (!isCancelLoading) {
        setTokenAmount('');
        setShowModal(false);
        setLoading(false);
        alert(`Transaction success! Tx hash: ${cancelReceipt}`);
      }
    } catch (e) {
      setError(e);
      console.error(e);
      setLoading(false);
    }
  };

  const handleVestingDataChange = (data: any) => {
    setVestingData(data);
  };

  return (
    <Form onSubmit={handleOperation}>
      <h5>Certificate Details</h5>
      <Form.Group className="mb-3">
        <Form.Label>Operation</Form.Label>
        <div>
          <Form.Check
            type="radio"
            label="Issue"
            name="operation"
            value={Operation.Grant}
            defaultChecked
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOperation(Number(e.target.value))
            }
            required
          />
          <Form.Check
            type="radio"
            label="Cancel"
            name="operation"
            value={Operation.Cancel}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOperation(Number(e.target.value))
            }
            required
          />
        </div>
      </Form.Group>
      {operation === Operation.Grant && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Federal Exemption</Form.Label>
            <Form.Select
              name="federalExemption"
              value={federalExemption}
              onChange={(e) => setFederalExemption(e.target.value)}
            >
              <option value="Rule 701">Rule 701</option>
              <option value="Section 4(a)(2)">Section 4(a)(2)</option>
              <option value="Section 4(A)(1-1/2)">Section 4(A)(1-1/2)</option>
              <option value="Section 4(a)(7)">Section 4(a)(7)</option>
              <option value="Rule 144">Rule 144</option>
              <option value="Reg D - 506(b)">Reg D - 506(b)</option>
              <option value="Reg D - 506(c)">Reg D - 506(c)</option>
              <option value="Reg D - 506">Reg D - 506</option>
              <option value="Reg D - 505">Reg D - 505</option>
              <option value="Reg D - 504">Reg D - 504</option>
              <option value="Reg S">Reg S</option>
              <option value="Reg A (tier 1)">Reg A (tier 1)</option>
              <option value="Reg A (tier 2)">Reg A (tier 2)</option>
              <option value="Reg CF">Reg CF</option>
              <option value="Non-U.S.">Non-U.S.</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Security Legend</Form.Label>
            <Form.Control
              as="textarea"
              rows={11}
              placeholder="Enter the security legend"
              name="securityLegend"
              value={securityLegend}
              onChange={(e) => setSecurityLegend(e.target.value)}
            />
          </Form.Group>
        </>
      )}
      <Form.Group className="mb-3">
        <Form.Label>Quantity</Form.Label>
        <Form.Control
          type="number"
          value={tokenAmount}
          onChange={(e) => setTokenAmount(e.target.value)}
          placeholder="Enter amount of shares"
          required
          disabled={loading}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Cash Paid</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={cashPaid}
            onChange={(e) => setCashPaid(e.target.value)}
            placeholder="Enter cash paid"
            required
            disabled={loading}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Cost Basis</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={costBasis}
            onChange={(e) => setCostBasis(e.target.value)}
            placeholder="Enter cost basis"
            required
            disabled={loading}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Price Per Share</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control type="number" value={pricePerShare} readOnly disabled />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="switch"
          id="vesting-switch"
          label="Include Vesting Conditions"
          checked={hasVesting}
          onChange={(e) => setHasVesting(e.target.checked)}
        />
      </Form.Group>
      {operation === Operation.Grant && hasVesting && (
        <VestingSchedule
          onChange={handleVestingDataChange}
          tokenAmount={tokenAmount}
        />
      )}
      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? <Spinner size="sm" /> : 'Confirm'}
      </Button>
    </Form>
  );
};

export default ManageCertificateForm;
