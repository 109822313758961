import React, { useState } from 'react'; // Import useState
import { Link } from 'react-router-dom';
import numbro from 'numbro';
import { formatOptions } from '../../constants';
import { nativeAsset } from '../../utils';
import { useWeb3React } from '@web3-react/core';
import { capitalizeString } from '../../utils';

export default function CompanyListItem({
  name,
  image,
  symbol,
  address,
  url,
  issuer,
  description,
  price,
  valuation,
  valuationAsk,
  valuationString,
  valuationMethod,
  valuationDate,
  circulatingSupply,
  type,
  badgeType,
}: any) {
  const { chainId } = useWeb3React();
  // Initialize state to manage visibility of the Raising card
  const [showRaisingCard, setShowRaisingCard] = useState(
    valuationAsk !== 0 && badgeType !== 'Crypto'
  );

  // Determine badge color based on the badge type
  const badgeColor =
    badgeType === 'Secondary'
      ? 'bg-primary'
      : badgeType === 'Raising'
        ? 'bg-success'
        : 'bg-custom';
  const isCrypto = badgeType === 'Crypto';

  return (
    <Link
      to={`${badgeType.toLowerCase()}/${address}`}
      className="my-3"
      style={{ textDecoration: 'none' }}
    >
      <section className="card shadow-sm">
        <div className="card-header p-4">
          <div className="row d-flex justify-content-center align-items-center py-3">
            <div className="col-xl-2 col-md-2">
              <img
                className="img-fluid border rounded-circle"
                width={200}
                src={image}
              />
            </div>
            <div className="col-xl-10 col-md-8">
              {issuer}&nbsp;
              <span className="text-muted">
                {capitalizeString(type.replace('_', ' '))}
              </span>
              <br />
              <small>{description}</small>
            </div>
          </div>
        </div>
        <div className="my-2"></div>

        {/* Secondary */}
        {badgeType === 'Secondary' && (
          <div className="card-body">
            <div className="row p-3">
              <div className="col-xl-8 col-md-8">
                <span className="badge bg-primary mb-3">Secondary</span>
                {/* <div>{valuationString}: <span className="text-muted">${numbro(valuation).format(formatOptions)}</span></div>
                {price ? null : (
                  <>
                    <div>Valuation Method: <span className="text-muted">{valuationMethod}</span></div>
                    <div>Valuation Date:  <span className="text-muted">{valuationDate}</span></div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        )}

        {/* Primary */}
        {badgeType === 'Primary' && (
          <div className="card-body">
            <div className="p-3">
              <div className="d-flex justify-content-between">
                <div>
                  <span className="badge bg-success mb-3">Fundraising</span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Crypto */}
        {isCrypto && (
          <div className="card-body">
            <div className="row p-3">
              <div className="col-8">
                <span
                  className={`badge ${badgeColor} mb-3`}
                  style={{ backgroundColor: '#6F2CF5' }}
                >
                  Crypto
                </span>
                <div>
                  Market Cap:{' '}
                  <span className="text-muted">
                    ${numbro(valuation).format(formatOptions)}
                  </span>
                </div>
                <div>
                  Price: <span className="text-muted">${price}</span>
                </div>
                <div>
                  Circulating Supply:{' '}
                  <span className="text-muted">
                    {numbro(circulatingSupply).format(formatOptions)}{' '}
                    {nativeAsset[chainId!]}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </Link>
  );
}
