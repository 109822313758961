import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useError } from '../../contexts/ErrorContext';

function ErrorToast() {
  const { error, show, setShow } = useError();
  const [title, setTitle] = useState('Error');
  const [message, setMessage] = useState<any | null>();

  useEffect(() => {
    if (error) {
      setShow(true);
      let _message =
        error?.response?.data?.message ||
        error?.reason ||
        error?.data?.message ||
        error?.message ||
        error;

      if (_message) {
        if (_message!.includes('NotSubscribed')) {
          setTitle('Not Subscribed');
          setMessage(
            <>
              Your subscription is not active. To subscribe, click{' '}
              <Link style={{ color: 'aliceblue' }} to="/settings/billing">
                here
              </Link>
              .
            </>
          );
        } else if (_message!.includes('MustNotBePaused')) {
          setTitle('Token is paused');
          setMessage('You cannot trade while the token is paused.');
        } else if (_message!.includes('CannotBidOnOwnListing')) {
          setTitle('Invalid action');
          setMessage('You cannot place a bid on your own listing.');
        } else if (_message!.includes('BidAlreadyExists')) {
          setTitle('Bid Already Exists');
          setMessage(
            'You have already placed a bid. Please withdraw your previous bid before placing a new one.'
          );
        } else if (_message!.includes('SubscriptionStillActive')) {
          setTitle('Subscription is active');
          setMessage('You have already a subscription active.');
        } else if (
          _message!.includes(
            '[ethjs-query] while formatting outputs from RPC \'{"value":{"code":-32603,"data":{"code":-32000,"message":"Nonce too high. Expected nonce to be 13 but got 166. Note that transactions can\'t be queued when automining.","data":{"message":"Nonce too high. Expected nonce to be 13 but got 166. Note that transactions can\'t be queued when automining."}}}}\''
          )
        ) {
          setTitle('Nonce too high.');
          setMessage(
            'In most cases, this means you should reset your MetaMask account.'
          );
        } else if (_message!.includes('OwnableUnauthorizedAccount')) {
          setTitle('Unauthorized Account');
          setMessage(
            'The account you are using is not authorized for this action.'
          );
        } else if (_message.includes('InsufficientBalance')) {
          setTitle('Insufficient balance');
          setMessage(
            'Your account has an insufficient balance for this action.'
          );
        } else {
          setTitle('Error');
          setMessage(_message);
        }
        setShow(true);
      }
    }
  }, [error]);

  if (!error) return null;

  return (
    <Toast
      onClose={() => setShow(false)}
      show={show}
      autohide
      delay={15000}
      animation
      style={{ position: 'fixed', top: 20, right: 20, minWidth: 200 }}
    >
      <Toast.Header>
        <strong className="me-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body className="text-white bg-danger">{message}</Toast.Body>
    </Toast>
  );
}

export default ErrorToast;
