import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useToast } from '../../../contexts/ToastContext';

export default function PressRelease() {
  const { setMessage } = useToast();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${window.location.href}`);
    setMessage('Shareable link copied to clipboard!');
  };
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>
            CapSign Inc. Announces New Regulation Crowdfunding Offering: Shares
            Now Available
          </h1>
          <p>
            <strong>NEWS PROVIDED BY</strong>
          </p>
          <p>CapSign Inc. Jul 30, 2024, 09:00 ET</p>
          <Button variant="primary" onClick={copyToClipboard}>
            <i className="fa fa-link" /> Share
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <p>
            CapSign Inc. has launched its new Regulation Crowdfunding offering,
            making shares available to individual investors.
          </p>
          <p>
            <strong>SAN FRANCISCO, July 30, 2024 /PRNewswire/ --</strong>{' '}
            CapSign Inc., a Delaware corporation and leading blockchain-powered
            financial services platform, today announced the launch of its new
            Regulation Crowdfunding offering. This offering will allow
            individual investors to purchase shares in CapSign Inc. at a price
            of $10.00 per share, democratizing access to early-stage investment
            opportunities and providing an avenue for potential growth and
            returns.
          </p>
          <p>
            CapSign Inc. is at the forefront of leveraging blockchain technology
            to enhance the transparency, efficiency, and security of financial
            transactions. The platform offers a range of services including
            on-chain equity management, private secondary sales, and tokenized
            fundraising, catering to private companies and individual investors
            alike.
          </p>
          <p>
            <strong>
              "We are thrilled to open this new chapter in CapSign's growth
              story by offering shares to the public through Regulation
              Crowdfunding,"
            </strong>{' '}
            said Matthew Rosendin, CEO of CapSign Inc.{' '}
            <strong>
              "This offering aligns with our mission to democratize access to
              private investments and provide a platform where individual
              investors can participate in the growth of innovative companies
              that might be overlooked by institutional investors."
            </strong>
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>Summary of Offering</h3>
          <p>
            Pursuant to the Regulation Crowdfunding offering, CapSign Inc. is
            offering shares of its common stock at a price of $1.00 per share.
            The funds raised will be used to accelerate the development of
            CapSign's platform, expand its user base, and enhance its suite of
            blockchain-enabled financial services.
          </p>
          <ul>
            <li>
              <strong>Share Price:</strong> $1.00 per share
            </li>
            <li>
              <strong>Minimum Investment:</strong> $500 (500 shares)
            </li>
            <li>
              <strong>Maximum Investment:</strong> $300,000 (300,000 shares)
            </li>
            <li>
              <strong>Offering Period:</strong> July 30, 2024, to September 30,
              2024
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>About CapSign Inc.</h3>
          <p>
            CapSign Inc. is a pioneering blockchain-powered financial services
            platform that aims to revolutionize the way companies and investors
            manage and transact equity. By integrating decentralized identity
            solutions, automated compliance systems, and frictionless investing,
            CapSign Inc. offers a comprehensive solution for equity management,
            fundraising, and private secondary sales.
          </p>
          <p>
            <strong>
              "This Regulation Crowdfunding offering is a significant milestone
              for CapSign,"
            </strong>{' '}
            said Jane Smith, CFO of CapSign Inc.{' '}
            <strong>
              "It provides an opportunity for investors to become part of our
              journey as we continue to innovate and lead in the blockchain
              financial services space."
            </strong>
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>Additional Information</h3>
          <p>
            Investors seeking additional information about the Regulation
            Crowdfunding offering can consult the offering statement filed with
            the Securities and Exchange Commission ("SEC") and available at
            www.sec.gov. CapSign Inc. has filed a Form C with the SEC, which
            includes detailed information about the offering and the company.
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>Advisors</h3>
          <p>Rogoway Law Group is acting as legal counsel to CapSign Inc.</p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>Contacts</h3>
          <p>
            CapSign Inc. Matthew Rosendin CEO{' '}
            <a href="mailto:matt@capsign.com">matt@capsign.com</a>
          </p>
          <p>
            CapSign Inc. Jane Smith CFO{' '}
            <a href="mailto:jane.smith@capsign.com">jane.smith@capsign.com</a>
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <p>
            <strong>Important Information and Where to Find It</strong>
          </p>
          <p>
            A full description of the terms of the Regulation Crowdfunding
            offering will be provided in the Form C filed by CapSign Inc. with
            the SEC. This press release does not contain all the information
            that should be considered concerning the offering and is not
            intended to form the basis of any investment decision or any other
            decision in respect of the offering. CapSign Inc. urges its
            investors, shareholders, and other interested persons to read the
            Form C and other documents filed with the SEC because these
            documents will contain important information about CapSign Inc. and
            the offering. Once available, shareholders and interested parties
            will also be able to obtain a copy of the Form C and other documents
            filed with the SEC, without charge, at the SEC's website
            (www.sec.gov).
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <p>
            <strong>No Offer or Solicitation</strong>
          </p>
          <p>
            This press release is not a proxy statement or solicitation of a
            proxy, consent, or authorization with respect to any securities or
            in respect of the potential offering and does not constitute an
            offer to sell or a solicitation of an offer to buy the securities of
            CapSign Inc. or the combined company, nor will there be any sale of
            any such securities in any state or jurisdiction in which such
            offer, solicitation, or sale would be unlawful prior to registration
            or qualification under the securities laws of such state or
            jurisdiction. No offer of securities will be made except by means of
            a prospectus meeting the requirements of the Securities Act of 1933,
            as amended (the "Securities Act").
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <p>
            <strong>Special Note Regarding Forward-Looking Statements</strong>
          </p>
          <p>
            This press release contains forward-looking statements within the
            meaning of Section 27A of the Securities Act and Section 21E of the
            Securities Exchange Act of 1934, as amended, that are based on
            beliefs and assumptions and on information currently available. In
            some cases, you can identify forward-looking statements by the
            following words: "may," "will," "could," "would," "should,"
            "expect," "intend," "plan," "anticipate," "believe," "estimate,"
            "predict," "project," "potential," "continue," "ongoing," or the
            negative of these terms or other comparable terminology, although
            not all forward-looking statements contain these words. These
            statements involve risks, uncertainties, and other factors that may
            cause actual results, levels of activity, performance, or
            achievements to be materially different from the information
            expressed or implied by these forward-looking statements. Although
            we believe that we have a reasonable basis for each forward-looking
            statement contained in this press release, we caution you that these
            statements are based on a combination of facts and factors currently
            known by us and our projections of the future, about which we cannot
            be certain. Forward-looking statements in this press release
            include, but are not limited to, statements regarding the Regulation
            Crowdfunding offering, including the timing and structure of the
            offering, the proceeds of the offering, the initial market
            capitalization of the company, the benefits of the offering, and the
            company's future financial performance. We cannot assure you that
            the forward-looking statements in this press release will prove to
            be accurate. These forward-looking statements are subject to a
            number of risks and uncertainties, including, among others, the
            ability to complete the offering due to the failure to obtain
            approval from shareholders or satisfy other closing conditions, the
            occurrence of any event or other circumstances that could give rise
            to the termination of the offering, the outcome of any legal
            proceedings that may be instituted against CapSign Inc. following
            the announcement of the offering, the outcome of any potential
            government and/or regulatory proceedings, investigations, and
            inquiries, the risk that the offering disrupts CapSign Inc.'s
            business operations, the ability to recognize the anticipated
            benefits of the offering, costs related to the offering, changes in
            applicable laws or regulations, and other risks and uncertainties,
            including those to be included under the header "Risk Factors" in
            the Form C filed by CapSign Inc. with the SEC. In light of the
            significant uncertainties in these forward-looking statements, you
            should not regard these statements as a representation or warranty
            by us or any other person that we will achieve our objectives and
            plans in any specified time frame, or at all. The forward-looking
            statements in this press release represent our views as of the date
            of this press release. We anticipate that subsequent events and
            developments will cause our views to change. Except as otherwise
            required by applicable law, CapSign Inc. disclaims any duty to
            update any forward-looking statements. You should, therefore, not
            rely on these forward-looking statements as representing our views
            as of any date subsequent to the date of this press release.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
