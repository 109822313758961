// CertificateModal.tsx

import React, { useState } from 'react';
import { Modal, Nav } from 'react-bootstrap';
import Details from './Details';
import VestingSchedule from './VestingSchedule';
import PersonnelDetails from './PersonnelDetails';
import Compliance from './Compliance';
import ApprovalsStandard from './ApprovalsStandard';
import Legend from './Legend';

interface CertificateModalProps {
  show: boolean;
  onHide: () => void;
  certificate: any;
}

export default function CertificateModal({
  show,
  onHide,
  certificate,
}: CertificateModalProps) {
  const [activeTab, setActiveTab] = useState('details');

  // Define a function to render the content based on activeTab
  const renderContent = () => {
    switch (activeTab) {
      case 'details':
        return <Details certificate={certificate} />;
      case 'vesting':
        return <VestingSchedule certificate={certificate} />;
      case 'personnel':
        return <PersonnelDetails certificate={certificate} />;
      case 'compliance':
        return <Compliance certificate={certificate} />;
      case 'approvals':
        return <ApprovalsStandard certificate={certificate} />;
      case 'legend':
        return <Legend certificate={certificate} />;
      default:
        return <Details certificate={certificate} />;
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      dialogClassName="modal-90w"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{certificate.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex">
        {/* Left Sidebar Navigation */}
        <div className="sidebar me-4">
          <Nav
            variant="pills"
            className="flex-column"
            activeKey={activeTab}
            onSelect={(selectedKey) => setActiveTab(selectedKey!)}
          >
            <Nav.Item>
              <Nav.Link eventKey="details">Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="vesting">Vesting Schedule</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="personnel">Personnel Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="compliance">Compliance</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="approvals">Approvals Standard</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="legend">Legend</Nav.Link>
            </Nav.Item>
          </Nav>
          <button
            className="btn btn-primary mt-4"
            onClick={() => window.print()}
          >
            Print
          </button>
        </div>

        {/* Main Content Area */}
        <div className="content flex-fill">{renderContent()}</div>
      </Modal.Body>
    </Modal>
  );
}
