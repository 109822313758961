import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import email from './email.png';

export default function ConfirmEmail() {
  const { auth } = useAuth();
  const [sent, setSent] = useState<boolean>();

  const resendVerificationEmail = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/users/me/resend_verification_email`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
      });

      // Email verified, continue with onboarding
      if (response.status === 200) {
        alert('Sent! Please check your email.');
        setSent(true);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
    }
  };

  return (
    <div className="vh-100 bg-primary">
      <div className="h-100 d-flex flex-column align-items-center justify-content-center mx-2">
        <div className="col-lg-4 col-12">
          <div className="d-flex justify-content-center align-items-center">
            <Card className="w-100">
              <Card.Body>
                <div className="row mt-3">
                  <div className="col-12 text-center">
                    {/* <img
                      src={logo}
                      alt="CapSign"
                      className="img-fluid"
                    /> */}
                    <h1 className="my-4">Confirm Email</h1>
                    <img className="img-fluid mb-3" width="150" src={email} />
                  </div>
                </div>
                <div className="row">
                  <div className="offset-2 col-8">
                    <hr />
                    <p className="text-center pt-4">
                      Please check your email to continue.
                    </p>
                    {/* TODO: Only display this if the user logs in (instead of signs up) and still needs to confirm their email. */}
                    {/* <div className="mb-3">
                      <div className="text-center py-3">
                        <p>Haven't received your email?</p>
                        <div className="mb-3">
                          <small className="text-muted">Make sure to check your spam folder. Otherwise, click below to resend the email.</small>
                        </div>
                        <button className="btn btn-primary" disabled={sent} onClick={resendVerificationEmail}>Resend</button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
