import React, { useState } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import { contracts, formatOptions } from '../../../constants';
import { useParams } from 'react-router-dom';
import numbro from 'numbro';
import { useError } from '../../../contexts/ErrorContext';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useReadContract,
  useWriteContract,
} from 'wagmi';
import { parseUnits, formatUnits, zeroAddress } from 'viem';
import { decimals, subscriptionTokenAddress } from '../../../utils';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { decodeEventLog } from 'viem';

const usdcAbi = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'allowance', type: 'uint256' },
      { internalType: 'uint256', name: 'needed', type: 'uint256' },
    ],
    name: 'ERC20InsufficientAllowance',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'uint256', name: 'balance', type: 'uint256' },
      { internalType: 'uint256', name: 'needed', type: 'uint256' },
    ],
    name: 'ERC20InsufficientBalance',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'approver', type: 'address' }],
    name: 'ERC20InvalidApprover',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'receiver', type: 'address' }],
    name: 'ERC20InvalidReceiver',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
    name: 'ERC20InvalidSender',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }],
    name: 'ERC20InvalidSpender',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export default function BuyForm({ show, handleClose }: any) {
  const { tokenId } = useParams();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const [selectedListing, setSelectedListing] = useState<string>();
  const [price, setPrice] = useState<number>();
  const [isChecked, setIsChecked] = useState(false);
  const { setError } = useError();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const { writeContractAsync: approve } = useWriteContract();

  const { data: listings, refetch: refetchListings }: any = useReadContract({
    address: contracts[chainId!]?.Auction.address as `0x${string}`,
    abi: contracts[chainId!]?.Auction.abi,
    functionName: 'getListingsByToken',
    args: [tokenId],
  });

  const checkAndApproveToken = async (
    amount: bigint,
    tokenAddress: string,
    spenderAddress: string
  ) => {
    if (tokenAddress === zeroAddress) return true; // Skip if native ETH

    const currentAllowance: any = await publicClient?.readContract({
      address: subscriptionTokenAddress[chainId!] as `0x${string}`,
      abi: usdcAbi,
      functionName: 'allowance',
      args: [account, spenderAddress],
    });

    if (BigInt(currentAllowance) < amount) {
      const params = {
        address: subscriptionTokenAddress[chainId!] as `0x${string}`,
        abi: usdcAbi,
        functionName: 'approve',
        args: [spenderAddress, amount],
      };
      // const gas = await publicClient?.estimateContractGas(params);
      const approveTx = await approve({ ...params });
      await publicClient?.waitForTransactionReceipt({ hash: approveTx });
    }
    return true;
  };

  const { writeContractAsync: placeBid } = useWriteContract();

  const saveBidInDatabase = async (
    listingId: string,
    quantity: bigint,
    bidId: bigint,
    bidPrice: bigint,
    expiresAt: number
  ) => {
    try {
      const bidData = {
        bid_id: bidId.toString(),
        quantity: quantity.toString(),
        listing_id: listingId.toString(),
        bidder_address: account,
        bid_price: formatUnits(bidPrice, decimals[chainId!]),
        expires_at: new Date(expiresAt * 1000).toISOString(),
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/markets/bids`,
        bidData,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
    } catch (error) {
      console.error('Error saving bid in database:', error);
      setError(error);
    }
  };

  const handlePlaceBid = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (!price) {
      alert('Please set a bid price.');
      return;
    }
    if (!isChecked) {
      alert('Please acknowledge the disclosure.');
      return;
    }
    try {
      const bidPrice = parseUnits(price.toString(), decimals[chainId!]);
      const listing: any = await publicClient?.readContract({
        address: contracts[chainId!]?.Auction.address as `0x${string}`,
        abi: contracts[chainId!]?.Auction.abi,
        functionName: 'getListingById',
        args: [selectedListing],
      });

      const amount = BigInt(listing.quantity) * bidPrice;
      const approved = await checkAndApproveToken(
        amount,
        subscriptionTokenAddress[chainId!],
        contracts[chainId!]?.Auction.address as `0x${string}`
      );

      if (!approved) {
        alert('Failed to approve tokens for transaction.');
        return;
      }

      const nowInSeconds = Math.floor(new Date().getTime() / 1000);
      const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
      const expiresAt = nowInSeconds + thirtyDaysInSeconds;

      const gas = await publicClient?.estimateContractGas({
        address: contracts[chainId!]?.Auction.address as `0x${string}`,
        abi: contracts[chainId!]?.Auction.abi,
        functionName: 'placeBid',
        args: [selectedListing, bidPrice, expiresAt],
        account,
        value: listing!.paymentToken === zeroAddress ? amount : BigInt(0),
      });

      // Place the bid on the smart contract
      const tx = await placeBid({
        address: contracts[chainId!]?.Auction.address as `0x${string}`,
        abi: contracts[chainId!]?.Auction.abi,
        functionName: 'placeBid',
        args: [selectedListing, bidPrice, expiresAt],
        gas,
        account,
        value: listing!.paymentToken === zeroAddress ? amount : BigInt(0),
      });

      // Wait for the transaction receipt
      const receipt = await publicClient?.waitForTransactionReceipt({
        hash: tx,
      });

      // Extract the BidPlaced event
      let bidId: bigint | undefined;

      if (receipt?.logs) {
        for (const log of receipt.logs) {
          try {
            const event = decodeEventLog({
              abi: contracts[chainId!]?.Auction.abi,
              eventName: 'BidPlaced',
              data: log.data,
              topics: log.topics,
            });
            bidId = (event.args as any).bidId;
            break;
          } catch (error) {
            // Not the event we are looking for
          }
        }
      }

      if (typeof bidId === 'undefined') {
        throw new Error('Failed to retrieve bid ID from transaction receipt');
      }

      // Save the bid in the database
      await saveBidInDatabase(
        selectedListing!,
        listing.quantity,
        bidId,
        bidPrice,
        expiresAt
      );

      alert(`Bid for ${selectedListing} at $${price} placed successfully!`);
      setSelectedListing('');
      setPrice(0);
      setIsChecked(false);
      handleClose(); // Close the modal after placing the bid
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Place Bid</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handlePlaceBid}>
          <Form.Group className="mb-3">
            <Form.Label>
              Select a listing on which to make a bid, or alternatively, place a
              standing bid.
            </Form.Label>
            <ListGroup>
              {listings
                ?.filter((tokenListing: any) => tokenListing.status !== 4)
                .map((listing: any) => (
                  <ListGroup.Item
                    key={listing.id}
                    action
                    active={selectedListing === listing.id}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setSelectedListing(listing.id);
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <div>ID #{listing.id.toString().slice(0, 10)}</div>
                      <div>
                        {numbro(listing.quantity.toString()).format(
                          formatOptions
                        )}{' '}
                        @ ${formatUnits(listing.price, decimals[chainId!])}/sh
                      </div>
                      <div>
                        $
                        {numbro(
                          formatUnits(
                            BigInt(listing.quantity) * listing.price,
                            decimals[chainId!]
                          )
                        ).format(formatOptions)}
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Form.Group>
          {selectedListing !== undefined && (
            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter bid price"
                onChange={(e) => setPrice(Number(e.target.value))}
                required
              />
            </Form.Group>
          )}
        </Form>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="disclosureCheckbox"
            required
            onChange={handleCheckboxChange}
            checked={isChecked}
          />
          <label className="form-check-label" htmlFor="disclosureCheckbox">
            <small>
              I agree to the&nbsp;
              <a
                href="https://app.termly.io/document/disclaimer/21e6271b-0a31-4d8e-b5e6-58397463bc96"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>{' '}
              of the marketplace and confirm my bona fide interest in pursuing
              this transaction.
            </small>
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handlePlaceBid}
          disabled={selectedListing === undefined || !price || loading}
        >
          {loading ? 'Placing Bid...' : 'Place Bid'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
