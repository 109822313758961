import React, { useState } from 'react';
import LandingNav from '../../components/LandingNav';
import Footer from '../../components/Footer';
import HeroImage from './background.jpeg';
import './index.css';
import { Link } from 'react-router-dom';

const Solutions = () => {
  const [activeTab, setActiveTab] = useState('Equity Management');

  const content: any = {
    'Equity Management':
      'Token synchronization with equity management platforms',
    Fundraising: 'Fundraising for early-stage ventures with tokenized SAFEs',
    'Stock Option Financing':
      'Stock-option exercise and financings using tokenized cash',
    'Collateralized Loans':
      'Encumbering restricted securities for collateralized loans',
    'Early Liquidity':
      'Early liquidity via direct secondary or funding round sale',
  };

  return (
    <div>
      <LandingNav />
      <section>
        <div
          className="jumbotron jumbotron-fluid"
          style={{ position: 'relative' }}
        >
          <img
            src={HeroImage}
            alt="Full-Stack Equity"
            className="img-fluid"
            style={{
              width: '100%',
              objectFit: 'cover',
              height: '350px',
              opacity: '0.6',
            }}
          />
          <div
            className="container"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <h1 className="display-5 text-primary mb-5">
              Solutions for Growing Startups
            </h1>
            <ul className="nav nav-pills nav-fill">
              {Object.keys(content).map((key) => (
                <li className="nav-item" key={key}>
                  <a
                    className={`nav-link ${activeTab === key ? 'active' : ''}`}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab(key);
                    }}
                  >
                    {key}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <div className="block bg-dark py-5">
        <div className="container text-center">
          <div className="row">
            <h2 className="mb-5 pt-3 text-light">{activeTab}</h2>
            {[
              'Early Liquidity',
              'Stock Option Financing',
              'Collateralized Loans',
            ].includes(activeTab) && (
              <h5>
                <div className="badge text-bg-primary">Coming Soon</div>
              </h5>
            )}
          </div>
          {activeTab === 'Equity Management' && (
            <>
              <div className="row mt-5">
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-bolt fa-3x text-light"></i>
                  <p className="text-light mt-4">
                    Real-time cap table updates and transaction settlement for
                    all shareholders.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-user-shield text-light"></i>
                  <p className="text-light mt-4">
                    Enhanced security with cryptographic verification of equity
                    transactions.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-arrow-down text-light"></i>
                  <p className="text-light mt-4">
                    Reduced administrative overhead through automated processes
                    and smart contracts.
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-globe text-light"></i>
                  <p className="text-light mt-4">
                    Streamlined global equity management across different legal
                    jurisdictions.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-user-clock text-light"></i>
                  <p className="text-light mt-4">
                    Facilitated employee stock ownership plans (ESOPs) with
                    simplified token-based systems.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-eye-slash text-light"></i>
                  <p className="text-light mt-4">
                    Increased trust and immutable record-keeping, with selective
                    disclosure to protect shareholder identities.
                  </p>
                </div>
              </div>
            </>
          )}
          {activeTab === 'Stock Option Financing' && (
            <>
              <div className="row mt-5">
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-arrow-up fa-3x text-light"></i>
                  <p className="text-light mt-4">
                    Diversified financing options, offering employees better
                    terms and conditions.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-user-shield text-light"></i>
                  <p className="text-light mt-4">
                    Non-dilutive financing alternatives for companies,
                    preserving equity for founders and early investors.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-file-signature text-light"></i>
                  <p className="text-light mt-4">
                    Efficient and transparent financing process with all data
                    recorded on the blockchain.
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-door-open text-light"></i>
                  <p className="text-light mt-4">
                    Lower barriers to entry for employees looking to finance
                    their stock options.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-id-card text-light"></i>
                  <p className="text-light mt-4">
                    Integration with decentralized identity to streamline the
                    verification and credit assessment processes.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-globe text-light"></i>
                  <p className="text-light mt-4">
                    Immediate access to a broad network of potential financiers.
                  </p>
                </div>
              </div>
            </>
          )}
          {activeTab === 'Collateralized Loans' && (
            <>
              <div className="row mt-5">
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-bolt fa-3x text-light"></i>
                  <p className="text-light mt-4">
                    Secure and instant collateralization of assets via
                    blockchain technology.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-asterisk text-light"></i>
                  <p className="text-light mt-4">
                    Standard, flexible, or bespoke loan terms through
                    programmable smart contracts.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-handshake text-light"></i>
                  <p className="text-light mt-4">
                    Reduced counterparty risk with verified identities.
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-arrow-down text-light"></i>
                  <p className="text-light mt-4">
                    Lower transaction fees due to disintermediation.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-globe text-light"></i>
                  <p className="text-light mt-4">
                    Direct access to a potentially wider pool of lenders within
                    a decentralized ecosystem.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-gears text-light"></i>
                  <p className="text-light mt-4">
                    Automated compliance and collateral management, reducing the
                    risk of human error.
                  </p>
                </div>
              </div>
            </>
          )}
          {activeTab === 'Early Liquidity' && (
            <>
              <div className="row mt-5">
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-sliders fa-3x text-light"></i>
                  <p className="text-light mt-4">
                    Controlled liquidity events and continuous liquidity
                    programs customized to pre-defined parameters.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-mask text-light"></i>
                  <p className="text-light mt-4">
                    Transparent market activity with the anonymized shareholder
                    participation.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-bolt text-light"></i>
                  <p className="text-light mt-4">
                    Reduced time-to-liquidity with instant settlements or custom
                    settlement triggers on blockchain.
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-gears text-light"></i>
                  <p className="text-light mt-4">
                    Automated compliance with securities laws, company governing
                    documents, & shareholder agreements encoded in smart
                    contracts.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-magnifying-glass text-light"></i>
                  <p className="text-light mt-4">
                    Transparent and fair market price discovery mechanisms with
                    customizable price discovery formats.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-arrow-down text-light"></i>
                  <p className="text-light mt-4">
                    Cost reductions by eliminating intermediaries in the
                    transaction process.
                  </p>
                </div>
              </div>
            </>
          )}
          {activeTab === 'Fundraising' && (
            <>
              <div className="row mt-5">
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-globe text-light"></i>
                  <p className="text-light mt-4">
                    Ability to raise funds globally, tapping into a wider
                    investor base.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-clipboard-check text-light"></i>
                  <p className="text-light mt-4">
                    Automate securities notice filings with documents backed up
                    to your cloud
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-arrow-down text-light"></i>
                  <p className="text-light mt-4">
                    Reduced legal and accounting costs through smart contract
                    execution.
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-hand-holding-droplet text-light"></i>
                  <p className="text-light mt-4">
                    Enhanced liquidity for investors with the ability to trade
                    tokenized securities.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-envelope text-light"></i>
                  <p className="text-light mt-4">
                    Invite investors to invest in your fundraise with a
                    personalized link.
                  </p>
                </div>
                <div className="col-md-4 px-4 mb-5">
                  <i className="fa fa-3x fa-sack-dollar text-light"></i>
                  <p className="text-light mt-4">
                    Real-time distribution and allocation of raised funds,
                    improving cash flow management.
                  </p>
                </div>
              </div>
            </>
          )}
          {/* Get Started */}
          {['Equity Management'].includes(activeTab) && (
            <Link to="/register" className="btn btn-primary btn-lg my-5">
              Get Started
            </Link>
          )}
          {/* Learn More */}
          {['Fundraising'].includes(activeTab) && (
            <Link to="/" className="btn btn-primary btn-lg my-5">
              Learn More
            </Link>
          )}
        </div>
      </div>

      <div className="container-fluid">
        <div className="row my-5">
          <div className="offset-md-2 col-md-8 col-xs-12">
            <div className="card">
              <div className="card-body p-md-5 p-4">
                <p className="lead mb-0">
                  <h3 className="text-primary">
                    Solutions for Brokers, Investors, & Financial Institutions
                  </h3>
                  <p className="text-block">
                    We are open to partnership opportunities and will design and
                    implement solutions for your firm. CapSign will help you
                    reach new customers, expand your product offering, and
                    increase transactional efficiencies. Please reach out to us
                    via email:
                  </p>
                  <a
                    href="mailto:careers@capsign.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    partnerships@capsign.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Solutions;
