import React, { useEffect } from 'react';
import PressRelease from './PressRelease';

export default function OfferingPress() {
  /**
   * Fetch the current offering press
   */
  useEffect(() => {}, []);

  return (
    <>
      <PressRelease />
    </>
  );
}
