import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Card, Form, Button } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';

export default function IdentityDocuments() {
  const { auth } = useAuth();
  const [documentDetails, setDocumentDetails] = useState<any>(null);

  useEffect(() => {
    // Fetch the user's identity document details when the component mounts
    const fetchDocumentDetails = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/v1/identity_documents/me`,
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        setDocumentDetails(response.data.result);
      } catch (error) {
        console.error('Error fetching document details:', error);
      }
    };

    fetchDocumentDetails();
  }, [auth?.token]);

  const handleDownload = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/v1/identity_documents/me/download`,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
        responseType: 'blob',
      });

      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'document.jpg'); // or the correct filename extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <>
      <Helmet>CapSign | Documents</Helmet>
      <div className="document-page">
        <Card className="mt-3">
          <Card.Body>
            <Card.Title>Identity Document</Card.Title>
            {documentDetails && (
              <Form>
                <Form.Group className="mb-3" controlId="formDocumentType">
                  <Form.Label>Document Type</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={documentDetails.document_type}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDocumentNumber">
                  <Form.Label>Document Number</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={documentDetails.document_number}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formIssueDate">
                  <Form.Label>Issue Date</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={documentDetails.issue_date}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formExpirationDate">
                  <Form.Label>Expiration Date</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={documentDetails.expiration_date}
                  />
                </Form.Group>
                <Button variant="primary" onClick={handleDownload}>
                  Download Document
                </Button>
              </Form>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
