// SafeList.tsx

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { useChainId, useAccount } from 'wagmi';
import SafeListItem from './SafeListItem';

export default function SafeList() {
  const { auth } = useAuth();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const [safes, setSafes] = useState<any[]>([]);
  const [logos, setLogos] = useState<{ [key: string]: string }>({});

  /**
   * Fetch safes
   */
  useEffect(() => {
    (async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/v1/accounts/me/safes`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        const result = response.data.result;
        setSafes(result);

        // Fetch corporate logos for each safe
        const logoPromises = result.map(async (safe: any) => {
          const logoResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/business_entities/${safe.entity_id}/logo`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
              responseType: 'arraybuffer',
            }
          );
          const contentType = logoResponse.headers['content-type'];
          const base64Logo = Buffer.from(logoResponse.data, 'binary').toString(
            'base64'
          );
          const logoSrc = `data:${contentType};base64,${base64Logo}`;

          return { issuerId: safe.entity_id, logoSrc };
        });

        const logosData = await Promise.all(logoPromises);
        const logoMap: { [key: string]: string } = {};
        logosData.forEach(({ issuerId, logoSrc }) => {
          logoMap[issuerId] = logoSrc;
        });

        setLogos(logoMap);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token, chainId, account]);

  return (
    <section className="pb-3">
      <h4>Convertibles</h4>
      {safes.map((safe: any, index: number) => {
        if (safe.canceled_date) {
          return null;
        }
        const logoUrl = logos[safe.entity_id] || 'https://placehold.co/50';
        return <SafeListItem key={index} safe={{ ...safe, logoUrl }} />;
      })}
      {safes.length === 0 && (
        <span className="text-muted">No safes to display</span>
      )}
    </section>
  );
}
