import { useEffect, useState } from 'react';
import { Badge, Card, Button, ListGroup, Modal } from 'react-bootstrap';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi';
import axios from 'axios';
import { useError } from '../../contexts/ErrorContext';
import { useAuth } from '../../contexts/AuthContext';
import numbro from 'numbro';
import { Abi } from 'viem';
import { contracts } from '../../constants';

type Listing = {
  listing_id: number;
  owner_id: number;
  base_token_address: string;
  quote_token_address: string;
  security_id: string;
  quantity: number;
  price: number;
  expires_at: string;
  status: string;
  bids: Bid[];
};

type Bid = {
  bid_id: number;
  listing_id: number;
  bidder_id: number;
  bidder_address: string;
  bid_price: number;
  quantity: number;
  expires_at: string;
  status: string;
};

export default function Listings() {
  const { auth } = useAuth();
  const [listings, setListings] = useState<Listing[]>([]);
  const { setError } = useError();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedBid, setSelectedBid] = useState<Bid | null>(null);
  const chainId = useChainId();
  const { address: account } = useAccount();
  const publicClient = usePublicClient();
  const [showListings, setShowListings] = useState<boolean>(true);

  const { writeContractAsync: acceptBid, data: acceptBidData } =
    useWriteContract();

  const { isLoading: isAcceptingBid, isError: isAcceptBidError } =
    useWaitForTransactionReceipt({
      hash: acceptBidData,
    });

  const { writeContractAsync: withdrawListing } = useWriteContract();

  useEffect(() => {
    async function fetchListings() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/markets/listings`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        const fetchedListings = response.data.listings;

        // For each listing, fetch its bids
        const listingsWithBids = await Promise.all(
          fetchedListings.map(async (listing: Listing) => {
            const bidsResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/v1/markets/listings/${listing.listing_id}/bids`,
              {
                headers: {
                  Authorization: `Bearer ${auth?.token}`,
                  'X-Account-Id': auth?.user.account_id,
                },
              }
            );
            const bids = bidsResponse.data.bids;
            return { ...listing, bids };
          })
        );

        setListings(listingsWithBids);
      } catch (error) {
        setError(error);
        console.error('Error fetching listings:', error);
      }
    }
    fetchListings();
  }, [auth?.token]);

  const statusEnumToText = (value: string) => {
    return {
      Active: 'ACTIVE',
      Withdrawn: 'WITHDRAWN',
      Expired: 'EXPIRED',
      Pending: 'PENDING',
      Settled: 'SOLD',
    }[value];
  };

  const handleAcceptBid = (bid: Bid) => {
    setSelectedBid(bid);
    setShowModal(true);
  };

  const confirmBidAcceptance = async () => {
    if (!selectedBid) return;
    try {
      if (!selectedBid || !account) return;
      const gas = await publicClient?.estimateContractGas({
        account,
        address: contracts[chainId!].Auction.address as `0x${string}`,
        abi: contracts[chainId!].Auction.abi as Abi,
        functionName: 'acceptBid',
        args: selectedBid
          ? [selectedBid.listing_id, selectedBid.bid_id]
          : undefined,
      });
      await acceptBid({
        account,
        address: contracts[chainId!].Auction.address as `0x${string}`,
        abi: contracts[chainId!].Auction.abi as Abi,
        functionName: 'acceptBid',
        args: selectedBid
          ? [selectedBid.listing_id, selectedBid.bid_id]
          : undefined,
        gas,
      });

      // Create a settlement record and update listing status via API call
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/markets/settlements`,
        {
          listing_id: selectedBid.listing_id,
          bid_id: selectedBid.bid_id,
          buyer_id: selectedBid.bidder_id,
          seller_id: listings.find(
            (l) => Number(l.listing_id) === selectedBid.listing_id
          )?.owner_id,
          quantity: selectedBid.quantity,
          price: selectedBid.bid_price,
          rofr_exercised: false, // Adjust as necessary
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      // Update listings to reflect the accepted bid
      setListings((prevListings) =>
        prevListings.map((listing) =>
          listing.listing_id === selectedBid.listing_id
            ? { ...listing, status: 'Pending' }
            : listing
        )
      );

      // Update the selected bid status to 'Pending'
      setSelectedBid((prevBid) =>
        prevBid ? { ...prevBid, status: 'Pending' } : null
      );

      setShowModal(false);
      setSelectedBid(null);
    } catch (error) {
      setError(error);
      console.error('Error accepting bid:', error);
      setShowModal(false);
    }
  };

  const handleWithdrawListing = async (listing_id: number) => {
    try {
      const gas = await publicClient?.estimateContractGas({
        address: contracts[chainId!].Auction.address as `0x${string}`,
        abi: contracts[chainId!].Auction.abi as Abi,
        functionName: 'withdrawListing',
        args: [listing_id],
        account,
      });
      await withdrawListing({
        address: contracts[chainId!].Auction.address as `0x${string}`,
        abi: contracts[chainId!].Auction.abi as Abi,
        functionName: 'withdrawListing',
        args: [listing_id],
        account,
        gas,
      });
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/markets/listings/${listing_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      // Update the listings to reflect the withdrawn status
      setListings((prevListings) =>
        prevListings.map((listing) =>
          listing.listing_id === listing_id
            ? { ...listing, status: 'Withdrawn' }
            : listing
        )
      );
    } catch (error) {
      setError(error);
      console.error('Error withdrawing listing:', error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h5>
          Your Listings{' '}
          {listings.length > 0 && (
            <div className="badge bg-primary">{listings.length}</div>
          )}
        </h5>
        <div>
          <button
            className="btn btn-sm"
            onClick={() => setShowListings(!showListings)}
          >
            <i className={`fa fa-caret-${showListings ? 'down' : 'up'}`} />
          </button>
        </div>
      </div>
      {showListings &&
        (listings.length === 0 ? (
          <p className="text-muted">No listings to display</p>
        ) : (
          <div className="card-container">
            {listings.map((listing) => (
              <Card key={listing.listing_id} className="mb-3 shadow-sm">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <div>
                    Listing #{listing.listing_id} - Token{' '}
                    {listing.base_token_address}
                  </div>
                  <div>
                    {statusEnumToText(listing.status) !== 'WITHDRAWN' && (
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() =>
                          handleWithdrawListing(listing.listing_id)
                        }
                      >
                        <i className="fa fa-xmark" />
                      </Button>
                    )}
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center m-2">
                    <div className="col-3">
                      <strong>Quantity</strong>
                      <br />
                      {numbro(listing.quantity.toString()).format({
                        thousandSeparated: true,
                      })}{' '}
                      sh.
                    </div>
                    <div className="col-3">
                      <strong>Ask</strong>
                      <br />${Number(listing.price).toFixed(2)}
                    </div>
                    <div className="col-3 d-inline-block text-truncate">
                      <strong>Security ID</strong>
                      <br />
                      <span className="">{listing.security_id}</span>
                    </div>
                    <div className="col-3 text-end">
                      <Badge
                        bg={
                          ['Active', 'Pending'].includes(listing.status)
                            ? 'primary'
                            : 'secondary'
                        }
                      >
                        {statusEnumToText(listing.status)}
                      </Badge>
                      {listing.status === 'Active' &&
                        listing.bids?.length === 0 && (
                          <Badge bg="secondary" className="ms-2 text-uppercase">
                            No Bids
                          </Badge>
                        )}
                    </div>
                  </div>
                  {listing.status !== 'Settled' && listing.bids?.length > 0 && (
                    <>
                      <hr />
                      <h5 className="mt-2">Bids</h5>
                      {listing.status === 'Pending' ? (
                        'Listing is pending settlement by the issuing company.'
                      ) : (
                        <ListGroup
                          variant="flush"
                          className="border rounded w-100"
                        >
                          {listing.bids.map((bid) => (
                            <ListGroup.Item key={bid.bid_id.toString()}>
                              <div className="row">
                                <div className="col-5 align-self-center">
                                  <div>Bidder: {bid.bidder_address}</div>
                                </div>
                                <div className="col-2 align-self-center">
                                  <div>
                                    Offer: ${Number(bid.bid_price).toString()}
                                    /sh
                                  </div>
                                </div>
                                <div className="col-3 align-self-center">
                                  <div>
                                    Expiry:{' '}
                                    {new Date(bid.expires_at).toLocaleString()}
                                  </div>
                                </div>
                                <div className="col-2 align-self-center text-end">
                                  {bid.status === 'Active' ? (
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      className="shadow-sm"
                                      onClick={() => handleAcceptBid(bid)}
                                      disabled={listing.status === 'Pending'}
                                    >
                                      Accept Bid
                                    </Button>
                                  ) : listing.status === 'Withdrawn' ? (
                                    <div className="badge text-bg-secondary">
                                      Withdrawn
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            ))}
          </div>
        ))}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Bid Acceptance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to accept this bid?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={confirmBidAcceptance}>
            Accept Bid
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
