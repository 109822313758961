import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Container,
  Table,
  Modal,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
} from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import Permissions from './Permissions';
import './index.css';

export default function APIKeyManager() {
  const { auth } = useAuth();
  const [personalAccessToken, setPersonalAccessToken] = useState<any>([]);
  const [apiClients, setApiClients] = useState<any>([]);
  const [permissions, setPermissions] = useState<any>([]);
  const [scopes, setScopes] = useState<any>([]);
  const [applicationName, setApplicationName] = useState('');
  const [applicationDescription, setApplicationDescription] = useState('');
  const [applicationLogoUrl, setApplicationLogoUrl] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Fetch API keys and permissions on component mount
  useEffect(() => {
    fetchPermissions();
    fetchPersonalAccessToken();
    fetchApiClients();
  }, []);

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/permissions`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      setPermissions(response.data.result);
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  };

  const fetchPersonalAccessToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/me/personal_access_token`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      setPersonalAccessToken(response.data.result);
    } catch (error) {
      console.error('Error fetching PAT:', error);
    }
  };

  const fetchApiClients = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/accounts/me/api_clients`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      setApiClients(response.data.result);
    } catch (error) {
      console.error('Error fetching API clients:', error);
    }
  };

  const handleCreateApiClient = async () => {
    if (!applicationName.trim() || scopes.length === 0) {
      alert('Please provide a name and select at least one permission.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/accounts/me/api_clients`,
        {
          application_name: applicationName,
          application_description: applicationDescription,
          redirect_uri: redirectUri,
          scopes: scopes,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      await fetchApiClients();
      setShowModal(false);
      setApplicationName('');
      setApplicationDescription('');
      setApplicationLogoUrl('');
      setScopes([]);
    } catch (error) {
      console.error('Failed to create API client:', error);
    }
  };

  const handlePermissionChange = (permissionId: string) => {
    setScopes((prev: any) => {
      if (prev.includes(permissionId)) {
        return prev.filter((p: string) => p !== permissionId);
      } else {
        return [...prev, permissionId];
      }
    });
  };

  return (
    <Container>
      <h2>API Access Management</h2>
      {personalAccessToken ? (
        <Alert variant="success">
          <strong>Your Personal Access Token:</strong>{' '}
          {personalAccessToken.token}
        </Alert>
      ) : (
        <Alert variant="warning">You do not have a PAT yet.</Alert>
      )}

      <div className="d-flex justify-content-between align-items-center my-4">
        <h4 className="mb-0">OAuth Clients</h4>
        <Button onClick={() => setShowModal(true)}>Create Client</Button>
      </div>
      <Table hover className="mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Scopes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {apiClients.length > 0 ? (
            apiClients.map((client: any) => (
              <tr key={client.client_id}>
                <td>{client.application_name}</td>
                <td>
                  {client.scopes?.map((scope: string, index: number) => (
                    <React.Fragment key={scope}>
                      <code className="code-style">{scope}</code>
                      {index < client.scopes.length - 1 ? ' ' : ''}
                    </React.Fragment>
                  ))}
                </td>
                <td>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => alert('Delete function not implemented.')}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className="text-center py-3 border">
                No API clients have been created yet.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create a New OAuth Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup className="my-2">
              <FormLabel>Application Name</FormLabel>
              <FormControl
                type="text"
                value={applicationName}
                onChange={(e) => setApplicationName(e.target.value)}
                placeholder="Enter application name"
              />
            </FormGroup>
            <FormGroup className="my-2">
              <FormLabel>Description</FormLabel>
              <FormControl
                as="textarea"
                rows={3}
                value={applicationDescription}
                onChange={(e) => setApplicationDescription(e.target.value)}
                placeholder="Enter application description"
              />
            </FormGroup>
            <FormGroup className="my-2">
              <FormLabel>Redirect URI</FormLabel>
              <FormControl
                type="text"
                value={redirectUri}
                onChange={(e) => setRedirectUri(e.target.value)}
                placeholder="Enter redirect URI"
              />
            </FormGroup>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Application Logo</Form.Label>
              <Form.Control type="file" disabled />
            </Form.Group>
            <FormGroup className="my-2">
              <FormLabel>Scopes</FormLabel>
              <Permissions
                handlePermissionChange={handlePermissionChange}
                scopes={scopes}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateApiClient}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
