import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useToast } from '../../contexts/ToastContext';

function MessageToast() {
  const { message, show, setShow } = useToast();
  const [title, setTitle] = useState('Info');

  return (
    <Toast
      onClose={() => setShow(false)}
      show={show}
      autohide
      delay={15000}
      animation
      style={{ position: 'fixed', top: 20, right: 20, minWidth: 200 }}
    >
      <Toast.Header>
        <strong className="me-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body className="text-white bg-primary">{message}</Toast.Body>
    </Toast>
  );
}

export default MessageToast;
