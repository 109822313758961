import React from 'react';

export default function AchTransfer() {
  const [externalAccounts, setExternalAccounts] = React.useState([]);
  const [selectedAccount, setSelectedAccount] = React.useState();
  const [amount, setAmount] = React.useState<number>();
  return (
    <>
      <label htmlFor="depositFrom" className="form-label">
        Transfer from
      </label>
      <select className="form-select" defaultValue={externalAccounts[0]}>
        {externalAccounts.map((externalAccount: any, index: number) => {
          return (
            <option
              onSelect={() => setSelectedAccount(externalAccount)}
              key={index}
            >
              {externalAccount.account_type.charAt(0).toUpperCase() +
                externalAccount.account_type.slice(1)}{' '}
              {externalAccount.account_details[0].account_number.replace(
                /.(?=.{4,}$)/g,
                '*'
              )}
            </option>
          );
        })}
      </select>
      <div className="my-3">
        <label htmlFor="amount" className="form-label">
          Amount
        </label>
        <input
          type="number"
          className="form-control"
          id="amount"
          onChange={(event) => setAmount(Number(event.target.value))}
          placeholder="0.00"
        />
      </div>
    </>
  );
}
