// PersonnelDetails.tsx

import React from 'react';
import { Table } from 'react-bootstrap';

interface PersonnelDetailsProps {
  certificate: any;
}

export default function PersonnelDetails({
  certificate,
}: PersonnelDetailsProps) {
  const {
    fullName,
    email,
    dateOfBirth,
    issueDateRelationship,
    taxId,
    employeeId,
    payrollId,
  } = certificate;

  return (
    <div>
      <h4>Personnel Details</h4>

      {/* Basic Information Section */}
      <h5>Basic Information</h5>
      <Table>
        <tbody>
          <tr>
            <th>Full Name</th>
            <td>{fullName || 'N/A'}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{email || 'N/A'}</td>
          </tr>
          <tr>
            <th>Date of Birth</th>
            <td>
              {dateOfBirth ? new Date(dateOfBirth).toLocaleDateString() : 'N/A'}
            </td>
          </tr>
          <tr>
            <th>Issue Date Relationship</th>
            <td>{issueDateRelationship || 'N/A'}</td>
          </tr>
        </tbody>
      </Table>

      {/* Identification Section */}
      <h5>Identification</h5>
      <Table>
        <tbody>
          <tr>
            <th>Tax ID</th>
            <td className={!taxId ? 'text-muted' : ''}>
              {taxId || 'None entered'}
            </td>
          </tr>
          <tr>
            <th>Employee ID</th>
            <td className={!employeeId ? 'text-muted' : ''}>
              {employeeId || 'None entered'}
            </td>
          </tr>
          <tr>
            <th>Payroll ID</th>
            <td className={!payrollId ? 'text-muted' : ''}>
              {payrollId || 'None entered'}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
