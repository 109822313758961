import { useState, useEffect, useCallback } from 'react';
import {
  useAccount,
  useConnect,
  useChainId,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from 'wagmi';
import axios from 'axios';
import IdentityProxy from '@capsign/contracts/artifacts/contracts/identity/proxy/IdentityProxy.sol/IdentityProxy.json';
import Identity from '@capsign/contracts/artifacts/contracts/identity/Identity.sol/Identity.json';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { contracts } from '../../../constants';
import { useError } from '../../../contexts/ErrorContext';
import { baseSepolia } from 'viem/chains';
import {
  encodeAbiParameters,
  keccak256,
  concat,
  Abi,
  getContractAddress,
} from 'viem';
import walletIcon from './wallet.png';
import { config } from '../../../config';
import { hexlify } from 'ethers/lib/utils';
import { ethers } from 'ethers';

const baseSepoliaCreate2Deployer = '0x914d7Fec6aaC8cd542e72Bca78B30650d45643d7';

export default function SelfCustodyWallet(props: any) {
  const [userIdentity, setUserIdentity] = useState<string>();
  const publicClient = usePublicClient({ config });
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const { setError } = useError();
  const navigate = useNavigate();
  const account = useAccount();
  const { connect, connectors } = useConnect();
  const { data: walletClient } = useWalletClient();
  const { writeContractAsync: addKey } = useWriteContract();
  const chainId = useChainId();

  /**
   * Set user identity
   */
  useEffect(() => {
    const identityAddress =
      props.onboarding?.entity?.identity?.identity_address;
    if (identityAddress) setUserIdentity(identityAddress);
  }, [props.onboarding]);

  /**
   * @function connectWallet
   */
  const connectWallet = useCallback(() => {
    try {
      const coinbaseWalletConnector = connectors.find(
        (connector) => connector.id === 'coinbaseWalletSDK'
      );
      if (coinbaseWalletConnector) {
        connect({
          connector: coinbaseWalletConnector,
          chainId: baseSepolia.id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [connectors, connect]);

  /**
   * @function computeAddress
   * @param bytecode
   * @param args
   * @returns
   */
  const computeAddress = async (bytecode: `0x${string}`, args: any[]) => {
    const constructorAbi = [
      {
        inputs: [
          {
            internalType: 'address',
            name: '_implementationAuthority',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'initialManagementKey',
            type: 'address',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
    ];
    const inputs = constructorAbi[0].inputs;
    const deployData = encodeAbiParameters(inputs, args);
    const fullBytecode = concat([bytecode, deployData]);
    const salt = keccak256(
      new TextEncoder().encode(Date.now().toString())
    ) as `0x${string}`;
    const codeHash = keccak256(fullBytecode);

    console.log('Full bytecode:', fullBytecode);
    console.log('Salt:', salt);
    console.log('Code hash:', codeHash);

    // Compute address using the CREATE2 formula
    const computedAddress = `0x${keccak256(
      concat(['0xff', baseSepoliaCreate2Deployer, salt, codeHash])
    ).slice(-40)}`;

    return {
      computedAddress,
      salt,
      fullBytecode,
    };
  };

  // Function to create the call data
  const createCallData = (
    salt: `0x${string}`,
    fullBytecode: `0x${string}`
  ): string => {
    // The call data format is <salt:bytes32><deploymentCalldata:bytes>
    const saltHex = ethers.utils.hexZeroPad(salt, 32);
    const callData = hexlify(concat([saltHex as `0x${string}`, fullBytecode]));

    return callData;
  };

  /**
   * @function createIdentity
   * @returns {void}
   */
  const createIdentity = async (): Promise<void> => {
    if (!walletClient) return;

    try {
      setLoading(true);
      const signerAddress = walletClient.account.address;

      const identityImplementationAuthorityAddress = contracts[chainId]
        .IdentityImplementationAuthority.address as `0x${string}`;

      if (
        !walletClient.account.address ||
        !identityImplementationAuthorityAddress
      ) {
        throw new Error('Invalid addresses');
      }

      const args: any = [identityImplementationAuthorityAddress, signerAddress];

      let tx, identityAddress;

      alert(`Confirm chain ID: ${chainId}`);

      if (process.env.NODE_ENV === 'development') {
        tx = await walletClient.deployContract({
          abi: IdentityProxy.abi as Abi,
          bytecode: IdentityProxy.bytecode as `0x${string}`,
          args: args,
          gas: BigInt(3_000_000),
        });

        const result = await publicClient?.waitForTransactionReceipt({
          hash: tx,
        });

        identityAddress = result?.contractAddress;
      } else {
        // Create call data
        const { computedAddress, salt, fullBytecode } = await computeAddress(
          IdentityProxy.bytecode as `0x${string}`,
          args
        );
        const callData = createCallData(salt, fullBytecode);

        tx = await walletClient.sendTransaction({
          to: baseSepoliaCreate2Deployer,
          data: callData as `0x${string}`,
        });

        identityAddress = computedAddress;
      }

      const result = await publicClient?.waitForTransactionReceipt({
        hash: tx,
      });

      await addKey({
        // either use result?.contractAddress or computedAddress (for Base)
        address: result?.contractAddress as `0x${string}`,
        abi: Identity.abi as Abi,
        functionName: 'addKey',
        args: [keccak256(signerAddress), 2, 1],
      });

      await axios(`${process.env.REACT_APP_API_URL}/v1/identities`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-ID': auth?.user.account_id,
        },
        data: {
          wallet_address: signerAddress,
          identity_address: identityAddress,
          chain_id: chainId,
        },
      });

      setUserIdentity(identityAddress as `0x${string}`);
      navigate('/onboarding/verify-id');
    } catch (error: any) {
      console.log(error.message);
      console.error('Error deploying contract:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {account?.status === 'disconnected' ? (
        <div className="text-start">
          <p>
            Please connect a browser wallet like{' '}
            <a
              href="https://metamask.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              MetaMask
            </a>{' '}
            or{' '}
            <a
              href="https://www.coinbase.com/wallet"
              target="_blank"
              rel="noopener noreferrer"
            >
              Coinbase Wallet
            </a>{' '}
            to connect. You may use a wallet browser extension, or a wallet
            mobile app with a web3 browser.
          </p>
          <p>
            <small className="text-muted">
              Hardware wallets are not supported at this time. We are working
              hard to add support for better wallet options in the future.
            </small>
          </p>
          <div className="text-center">
            <button
              className="btn btn-primary btn-lg text-center"
              onClick={connectWallet}
            >
              Connect Wallet
            </button>
          </div>
        </div>
      ) : (
        <>
          <img src={walletIcon} className="w-50" />
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary btn-lg"
              onClick={createIdentity}
              disabled={loading}
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                'Prove Ownership'
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
