// src/pages/Offerings/EditOffering/EditEquityOfferingDocuments.tsx

import React, { useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';

const EditEquityOfferingDocuments: React.FC = () => {
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const { offering } = useOffering();
  const navigate = useNavigate();

  const [documents, setDocuments] = useState<{ [key: string]: File | null }>(
    {}
  );
  const [existingDocuments, setExistingDocuments] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    // Initialize required documents based on federal exemption
    let requiredDocs: string[] = [];

    switch (offering.federal_exemption) {
      case 'Reg CF':
        requiredDocs = [
          'Form C',
          'Financial Statements',
          'Subscription Agreement',
          'Pitch Deck',
          'Use of Funds Statement',
          'Risk Factors Document',
          'Capitalization Table',
        ];
        break;
      case 'Reg D 506(b)':
      case 'Reg D 506(c)':
        requiredDocs = [
          'Private Placement Memorandum',
          'Subscription Agreement',
          'Financial Statements',
          'Pitch Deck',
          'Use of Funds Statement',
          'Risk Factors Document',
          'Capitalization Table',
        ];
        break;
      default:
        requiredDocs = [];
        break;
    }

    const initialDocuments = requiredDocs.reduce(
      (acc, doc) => ({ ...acc, [doc]: null }),
      {}
    );
    setDocuments(initialDocuments);

    // Fetch existing documents
    const fetchExistingDocuments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        const docs = response.data.reduce((acc: any, doc: any) => {
          acc[doc.type] = doc.file_url;
          return acc;
        }, {});
        setExistingDocuments(docs);
      } catch (error) {
        console.error('Error fetching existing documents:', error);
      }
    };

    fetchExistingDocuments();
  }, [offering, auth, offeringId]);

  const handleFileChange = (docType: string, file: File | null) => {
    setDocuments((prev) => ({
      ...prev,
      [docType]: file,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      // Upload updated documents
      for (const [docType, file] of Object.entries(documents)) {
        if (file) {
          const formData = new FormData();
          formData.append('files', file);
          formData.append('type', docType);

          await axios.post(
            `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
            }
          );
        }
      }

      navigate(`/offerings/${offeringId}/edit/payment_terms`);
    } catch (error) {
      console.error('Error uploading documents:', error);
    }
  };

  return (
    <Container>
      <h2>Edit Offering Documents</h2>
      <Form onSubmit={handleSubmit}>
        {Object.keys(documents).map((docType) => (
          <Form.Group key={docType} controlId={docType.replace(/ /g, '_')}>
            <Form.Label>{docType}</Form.Label>
            {existingDocuments[docType] && (
              <div>
                <a
                  href={existingDocuments[docType]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Existing Document
                </a>
              </div>
            )}
            <Form.Control
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFileChange(docType, e.target.files?.[0] || null)
              }
            />
            <Form.Text className="text-muted">
              {existingDocuments[docType]
                ? 'Upload a new file to replace the existing document.'
                : 'No existing document. Please upload one.'}
            </Form.Text>
          </Form.Group>
        ))}

        <Button variant="primary" type="submit">
          Save Changes
        </Button>
      </Form>
    </Container>
  );
};

export default EditEquityOfferingDocuments;
