import './index.css';

function ExternalSites() {
  return (
    <div className="social-links container-fluid text-center mt-5 mb-3">
      <a target="blank" href="https://docs.capsign.com" className="mx-2">
        <i className="fa fa-book"></i>
      </a>
      {/* <a target="blank" href="https://capsign.zendesk.com/hc/" className="mx-2">
        <i className="fas fa-question-circle" />
      </a> */}
      <a target="blank" href="https://github.com/capsign" className="mx-2">
        <i className="fa-brands fa-github" />
      </a>
      <a target="blank" href="https://x.com/CapSignInc" className="mx-2">
        <i className="fa-brands fa-x-twitter" />
      </a>
    </div>
  );
}
export default ExternalSites;
