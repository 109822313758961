import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';

export default function OfferingUpdates() {
  const [updates, setUpdates] = useState([
    {
      title: 'Registered with the SEC',
      content:
        'Completed filing for CapSign Securities LLC registration as a Funding Portal.',
      date: new Date('7/1/2024'),
    },
    {
      title: 'Submitted FINRA SAA Form',
      content:
        'Submitted FINRA form for Super Account Administrator credentials for CapSign Securities LLC',
      date: new Date('7/9/2024'),
    },
    {
      title: 'Equity Token Standard Draft',
      content:
        'Released draft of new Ethereum standard for equity tokens available for  public comment.',
      date: new Date('8/6/2024'),
    },
  ]);
  const { auth } = useAuth();
  const { setError } = useError();

  /**
   * Fetch the current offering updates
   */
  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        // const response = await axios.get(
        //   `${process.env.REACT_APP_API_URL}/v1/offerings/updates`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${auth?.token}`,
        //     },
        //   }
        // );
        // setUpdates(response.data.result);
      } catch (error: any) {
        console.error('Error fetching updates:', error);
        if (error.response) {
          setError(error.response.data.message);
        }
      }
    };

    fetchUpdates();
  }, [auth, setError]);

  return (
    <Container className="mt-5">
      <h1>Company Updates</h1>
      <Row>
        {updates.map((update: any, index) => (
          <Col key={index} md={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{update.title}</Card.Title>
                <Card.Text>{update.content}</Card.Text>
                <Card.Footer className="text-muted">
                  {new Date(update.date).toLocaleDateString()}
                </Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
