import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  ListGroup,
  Card,
  Form,
} from 'react-bootstrap';
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { contracts } from '../../constants';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useReadContract,
  useWalletClient,
  useWriteContract,
} from 'wagmi';
import { Abi } from 'viem';
import { useError } from '../../contexts/ErrorContext';

const complianceModules = [
  'Conditional Transfer',
  'Country Allow',
  'Country Restrict',
  'Exchange with Monthly Limits',
  'Max Balance',
  'Supply Limit',
  'Time Exchange Limits',
  'Time Transfers Limits',
  'Transfer Fees',
  'Transfer Restrictions',
  'Holding Period',
  'Vesting',
];

export default function Compliance() {
  const [activeModules, setActiveModules] = useState<string[]>([]);
  const [customModuleAddress, setCustomModuleAddress] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const { address } = useAccount();
  const chainId = useChainId();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient({ account: address, chainId });
  const [vestingModuleAddress, setVestingModuleAddress] = useState('');
  const { setError } = useError();
  const location = useLocation();

  const { writeContractAsync: addModule } = useWriteContract();

  const { data: compliance } = useReadContract({
    address: location.state.shareClass.token_address,
    abi: contracts[chainId].RestrictedStock.abi,
    functionName: 'compliance',
  });

  const deployVestingModule = async () => {
    if (!walletClient) return;
    try {
      const tx = await walletClient.deployContract({
        abi: contracts[chainId!].VestingModule.abi as Abi,
        bytecode: contracts[chainId!].VestingModule.bytecode as `0x${string}`,
        args: [], // No constructor arguments needed in this case
        gas: BigInt(3_000_000),
        account: address,
      });

      const receipt = await publicClient?.waitForTransactionReceipt({
        hash: tx,
      });
      if (receipt?.contractAddress) {
        setVestingModuleAddress(receipt.contractAddress);
        console.log(`Vesting module deployed at ${receipt.contractAddress}`);
      }
    } catch (error) {
      console.error('Error deploying Vesting module:', error);
    }
  };

  useEffect(() => {
    (async () => {
      const compliance: `0x${string}` = await publicClient?.readContract({
        address: location.state?.shareClass?.token_address as `0x${string}`,
        abi: contracts[chainId!].RestrictedStock.abi,
        functionName: 'compliance',
      })!;
      const modules = await publicClient?.readContract({
        address: compliance as `0x${string}`,
        abi: contracts[chainId!].ModularCompliance.abi,
        functionName: 'getModules',
      })!;
      setActiveModules(modules);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (vestingModuleAddress) {
          const params = {
            address: contracts[chainId!].ModularCompliance
              .address as `0x${string}`,
            abi: contracts[chainId!].ModularCompliance.abi as Abi,
            functionName: 'addModule',
            args: [vestingModuleAddress],
            account: address,
          };
          // Add the deployed vesting module to the compliance contract
          const gas = await publicClient?.estimateContractGas(params);
          addModule({ gas, ...params })
            .then(() => {
              console.log(`Vesting module added to compliance contract`);
            })
            .catch((error) => {
              console.error('Error adding Vesting module:', error);
            });
        }
      } catch (error) {
        console.log(error);
        setError(error);
      }
    })();
  }, [vestingModuleAddress, addModule, chainId]);

  const handleAddModule = async (module: string) => {
    if (module === 'Vesting' && !activeModules.includes('Vesting')) {
      await deployVestingModule();
    }
    if (!activeModules.includes(module)) {
      setActiveModules([...activeModules, module]);
    }
  };

  const handleAddCustomModule = () => {
    if (customModuleAddress && !activeModules.includes(customModuleAddress)) {
      setActiveModules([...activeModules, customModuleAddress]);
      setCustomModuleAddress(''); // Clear input after adding
    }
  };

  const handleRemoveModule = (module: string) => {
    setActiveModules(activeModules.filter((mod) => mod !== module));
  };

  const handleSubmit = () => {
    console.log('Submitting...');
  };

  return (
    <Container fluid>
      {/* Breadcrumb */}
      <div className="border-bottom py-3 mb-4">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink
                  to={`/equity/${location.state?.shareClass?.token_address}`}
                  state={location.state}
                >
                  {location.state?.shareClass?.class_name}
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Compliance
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Row className="justify-content-md-center my-4">
        <Col md={8}>
          <Card>
            <Card.Header>
              <h2>Compliance Management</h2>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <div>Compliance Address: {compliance}</div>
                  <br />
                  <div>Modules: {JSON.stringify(activeModules)}</div>
                  <br />
                  <h4>Add a Compliance Module</h4>
                  <div className="d-flex flex-wrap mb-3">
                    {complianceModules.map((module: string) => (
                      <Button
                        variant="outline-primary"
                        className="m-1"
                        onClick={() => handleAddModule(module)}
                        key={module}
                      >
                        {module}
                      </Button>
                    ))}
                  </div>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Enter custom module address"
                      aria-label="Enter custom module address"
                      value={customModuleAddress}
                      onChange={(e) => setCustomModuleAddress(e.target.value)}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={handleAddCustomModule}
                    >
                      Add Custom Module
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>Active Modules</h4>
                  <ListGroup>
                    {activeModules.map((module, idx) => (
                      <ListGroup.Item key={idx}>
                        <ModuleForm
                          moduleName={module}
                          onRemove={handleRemoveModule}
                        />
                      </ListGroup.Item>
                    ))}
                    {activeModules.length === 0 && (
                      <div className="d-flex justify-content-center my-3 align-items-center">
                        No active modules
                      </div>
                    )}
                  </ListGroup>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              <Button onClick={handleSubmit}>Confirm</Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

interface ModuleFormProps {
  moduleName: string;
  onRemove: (moduleName: string) => void;
}

function ModuleForm({ moduleName, onRemove }: ModuleFormProps) {
  switch (moduleName) {
    case 'Conditional Transfer':
      return (
        <ConditionalTransferForm
          moduleName={moduleName}
          onRemove={() => onRemove(moduleName)}
        />
      );
    case 'Country Allow':
      return (
        <CountryAllowForm
          moduleName={moduleName}
          onRemove={() => onRemove(moduleName)}
        />
      );
    case 'Holding Period':
      return (
        <HoldingPeriodForm
          moduleName={moduleName}
          onRemove={() => onRemove(moduleName)}
        />
      );
    case 'Vesting':
      return (
        <div className="d-flex justify-content-between align-items-center">
          <span>{moduleName}</span>
          <Button variant="outline-danger" onClick={() => onRemove(moduleName)}>
            <FaTrash />
          </Button>
        </div>
      );
    default:
      return <p>Select a module to configure its settings.</p>;
  }
}

interface FormProps {
  moduleName: string;
  onRemove: (moduleName: string) => void;
}

function ConditionalTransferForm({ moduleName, onRemove }: FormProps) {
  return (
    <Form>
      <h5>Conditional Transfer Module</h5>
      <Row>
        <Col md={4}>
          <Form.Label>Condition:</Form.Label>
          <InputGroup>
            <FormControl type="text" />
            <Button type="submit" variant="primary">
              Save
            </Button>
          </InputGroup>
        </Col>
        <Col md={8} className="text-end">
          <Button variant="outline-danger" onClick={() => onRemove(moduleName)}>
            <FaTrash />
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

function CountryAllowForm({ moduleName, onRemove }: FormProps) {
  return (
    <Form>
      <h5>Country Allow Module</h5>
      <Row>
        <Col md={4}>
          <Form.Label>Country Code:</Form.Label>
          <InputGroup>
            <FormControl type="text" />
            <Button type="submit" variant="primary">
              Save
            </Button>
          </InputGroup>
        </Col>
        <Col md={8} className="text-end">
          <Button variant="outline-danger" onClick={() => onRemove(moduleName)}>
            <FaTrash />
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const HoldingPeriodForm: React.FC<FormProps> = ({
  moduleName,
  onRemove,
}: FormProps) => {
  const [periodValue, setPeriodValue] = useState('');
  const [periodUnit, setPeriodUnit] = useState('days');

  const handlePeriodValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPeriodValue(event.target.value);
  };

  const handlePeriodUnitChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPeriodUnit(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const seconds = convertToSeconds(periodValue, periodUnit);
    // Add logic to handle form submission
    console.log('Holding Period in seconds:', seconds);
    // Additional logic to deploy the HoldingPeriod contract with the specified holding period
  };

  const convertToSeconds = (value: string, unit: string): number => {
    const numValue = parseInt(value, 10);
    switch (unit) {
      case 'days':
        return numValue * 24 * 60 * 60;
      case 'weeks':
        return numValue * 7 * 24 * 60 * 60;
      case 'months':
        return numValue * 30 * 24 * 60 * 60;
      case 'years':
        return numValue * 365 * 24 * 60 * 60;
      default:
        return 0;
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h5>Holding Period Module</h5>
      <Row>
        <Col md={6}>
          <Form.Label htmlFor="holdingPeriod">
            Holding Period Length:
          </Form.Label>
          <InputGroup>
            <FormControl
              type="number"
              id="holdingPeriodValue"
              value={periodValue}
              onChange={handlePeriodValueChange}
              className="form-control"
              placeholder="Enter value"
            />
            <Form.Select
              aria-label="Select unit"
              value={periodUnit}
              onChange={handlePeriodUnitChange}
            >
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
            </Form.Select>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </InputGroup>
        </Col>
        <Col md={6} className="text-end">
          <Button variant="outline-danger" onClick={() => onRemove(moduleName)}>
            <FaTrash />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
