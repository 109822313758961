// Details.tsx

import numbro from 'numbro';
import React from 'react';
import { explorerBaseUrl } from '../../../utils';
import { useChainId } from 'wagmi';

export default function Details({ certificate }: { certificate: any }) {
  const chainId = useChainId();

  function parseAmountWithCurrency(value: string) {
    const match = value?.match(/\(([\d.]+),(\w+)\)/);
    if (match) {
      const amount = parseFloat(match[1]);
      const currency = match[2];
      return { amount, currency };
    }
    return { amount: 0, currency: 'USD' }; // default fallback
  }

  function formatCurrency(amount: number, currency: string) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    }).format(amount);
  }

  // Parse and format cash_paid, price_per_share, cost_basis
  const parsedCashPaid = parseAmountWithCurrency(certificate.cash_paid);
  const formattedCashPaid = formatCurrency(
    parsedCashPaid.amount,
    parsedCashPaid.currency
  );

  const parsedPricePerShare = parseAmountWithCurrency(
    certificate.price_per_share
  );
  const formattedPricePerShare = formatCurrency(
    parsedPricePerShare.amount,
    parsedPricePerShare.currency
  );

  const parsedCostBasis = parseAmountWithCurrency(certificate.cost_basis);
  const formattedCostBasis = formatCurrency(
    parsedCostBasis.amount,
    parsedCostBasis.currency
  );

  // Format dates
  const issueDate = certificate.issue_date
    ? new Date(certificate.issue_date).toLocaleDateString()
    : 'N/A';

  const acquisitionDate = certificate.original_acquisition_date
    ? new Date(certificate.original_acquisition_date).toLocaleDateString()
    : 'N/A';

  const boardApprovedDate = certificate.board_approved_date
    ? new Date(certificate.board_approved_date).toLocaleDateString()
    : 'N/A';

  return (
    <div>
      <h4>Details</h4>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Shareholder Name</th>
            <td>{certificate.shareholder_name || 'N/A'}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{certificate.shareholder_email || 'N/A'}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{certificate.canceled_date ? 'Cancelled' : 'Outstanding'}</td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex flex-row gap-2 align-items-center">
        <h5>Certificate Details</h5>
        <a
          className="mb-1"
          target="_blank"
          rel="noopener noreferrer"
          href={`${explorerBaseUrl[chainId!]}/tx/${certificate.tx_hash}`}
        >
          View on Explorer{' '}
          <i className="fa-solid fa-arrow-up-right-from-square"></i>
        </a>
      </div>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Share Class</th>
            <td>{certificate.share_class_name || 'N/A'}</td>
          </tr>
          <tr>
            <th>Quantity</th>
            <td>
              {certificate.quantity !== undefined
                ? numbro(certificate.quantity).format({
                    thousandSeparated: true,
                  })
                : 'N/A'}
            </td>
          </tr>
          <tr>
            <th>Cash Paid</th>
            <td>{formattedCashPaid || 'N/A'}</td>
          </tr>
          <tr>
            <th>Price Per Share</th>
            <td>{formattedPricePerShare || 'N/A'}</td>
          </tr>
          <tr>
            <th>Original Acquisition Date</th>
            <td>{acquisitionDate}</td>
          </tr>
          <tr>
            <th>Issue Date</th>
            <td>{issueDate}</td>
          </tr>
          <tr>
            <th>Cost Basis</th>
            <td>{formattedCostBasis || 'N/A'}</td>
          </tr>
          {/* Add thumbnails for certificate front and back if available */}
        </tbody>
      </table>
      <h5>Issuer Details</h5>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Issued by</th>
            <td>{certificate.issuer_legal_name || 'N/A'}</td>
          </tr>
          <tr>
            <th>Issue Date</th>
            <td>{issueDate}</td>
          </tr>
          <tr>
            <th>Board Approved</th>
            <td>{boardApprovedDate}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
