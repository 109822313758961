import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Form, Row, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { contracts } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useError } from '../../../contexts/ErrorContext';
import { capitalizeString } from '../../../utils';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWriteContract,
} from 'wagmi';
import { zeroAddress, Abi, parseEventLogs } from 'viem';
import { config } from '../../../config';
import ArticlesOfIncorporationUploader from '../../Documents/ArticlesOfIncorporationUploader';
import SidePopout from '../../../components/SidePopout';
import ShareClass from './ShareClass';
import { useEntity } from '../../../contexts/EntityContext';

interface TokenDetails {
  owner: `0x${string}`;
  name: string;
  symbol: string;
  decimals: bigint;
  irs: `0x${string}`;
  ONCHAINID: `0x${string}`;
  irAgents: `0x${string}`[];
  tokenAgents: `0x${string}`[];
  complianceModules: `0x${string}`[];
  complianceSettings: `0x${string}`[];
}

interface ClaimDetails {
  claimTopics: bigint[];
  issuers: `0x${string}`[];
  issuerClaims: bigint[][];
}

export default function CreateToken() {
  const { auth } = useAuth();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const { entity } = useEntity();
  const chainId = useChainId();
  const account = useAccount();
  const navigate = useNavigate();
  const publicClient = usePublicClient({ config });
  const [shareClasses, setShareClasses] = useState<any[]>([]);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [validShareClasses, setValidShareClasses] = useState<boolean[]>([]);

  const {
    data: txHash,
    error,
    writeContractAsync: deployTREXSuite,
  } = useWriteContract();

  /**
   * Fetch share classes
   */
  useEffect(() => {
    const fetchShareClasses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/business_entities/${entity.entity_id}/share_classes`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        console.log(response.data.result);
        setShareClasses(response.data.result);
      } catch (error) {
        console.error('Error fetching share classes:', error);
        // Handle error appropriately
      }
    };

    if (entity?.entity_id) {
      fetchShareClasses();
    }
  }, []);

  const handleUploadSuccess = (data: any) => {
    setShareClasses(data);
  };

  const handleShareClassChange = (
    index: number,
    data: any,
    isValid: boolean
  ) => {
    setShareClasses((prevShareClasses) => {
      const updatedShareClasses = [...prevShareClasses];
      updatedShareClasses[index] = data;
      return updatedShareClasses;
    });

    setValidShareClasses((prevValidations) => {
      const updatedValidations = [...prevValidations];
      updatedValidations[index] = isValid;
      return updatedValidations;
    });
  };

  const isFormValid = () => {
    return (
      shareClasses.length > 0 && validShareClasses.every((isValid) => isValid)
    );
    // Add validation for other asset types if necessary
    return true;
  };

  /**
   * @function handleSubmitToken
   * @param event
   * @returns
   */
  const handleSubmitToken = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    for (const shareClass of shareClasses) {
      if (shareClass.contract_address) continue;

      const salt = new Date().toISOString(); // This needs to be unique for each deployment
      const tokenDetails: TokenDetails = {
        name: shareClasses[0].class_name,
        symbol: shareClasses[0].prefix,
        decimals: BigInt(0),
        irAgents: [account?.address! as `0x${string}`],
        tokenAgents: [account?.address! as `0x${string}`],
        complianceModules: [
          contracts[chainId!].VestingModule.address as `0x${string}`,
        ], // Vesting module
        complianceSettings: [],
        ONCHAINID: zeroAddress as `0x${string}`,
        owner: account?.address! as `0x${string}`,
        irs: zeroAddress as `0x${string}`,
      };

      const claimDetails: ClaimDetails = {
        claimTopics: [], // List of claim topics
        issuers: [], // List of issuer addresses (TODO: rename "issuers" to "attestors" to avoid confusion)
        issuerClaims: [], // Corresponding claims for each issuer/attestor
      };
      try {
        const tokenType = 0; // TokenType.Stock = 0
        const args = [salt, tokenDetails, claimDetails, tokenType];
        const gas = await publicClient?.estimateContractGas({
          address: contracts[chainId].TREXFactory.address as `0x${string}`,
          abi: contracts[chainId].TREXFactory.abi as Abi,
          functionName: 'deployTREXSuite',
          args,
          account: account.address,
        });
        const hash = await deployTREXSuite({
          address: contracts[chainId].TREXFactory.address as `0x${string}`,
          abi: contracts[chainId].TREXFactory.abi as Abi,
          functionName: 'deployTREXSuite',
          args,
          gas: gas,
        });
        const receipt = await publicClient?.waitForTransactionReceipt({
          hash: hash,
        });

        const logs = parseEventLogs({
          abi: contracts[chainId].TREXFactory.abi as Abi,
          logs: receipt?.logs!,
        });
        const event = logs.filter(
          (l) => l.eventName === 'TREXSuiteDeployed'
        )[0];

        const { _token: tokenAddress, _ir: ir } = event.args as any;

        // Tokenize
        await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/business_entities/${entity.entity_id}/share_classes/${shareClass.class_id}/tokenize`,
          method: 'POST',
          data: {
            contract_address: tokenAddress,
            registry_address: ir,
            identity_address: zeroAddress,
          },
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });

        navigate('/equity');
      } catch (error: any) {
        let _message = error?.message;
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h5 className="mb-4">Tokenize Equity</h5>
            <Form onSubmit={handleSubmitToken}>
              <Form.Group controlId="formCompanyName" className="mb-3">
                <Form.Label>Issuer Name</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  disabled
                  defaultValue={entity.legal_name}
                />
              </Form.Group>

              <ArticlesOfIncorporationUploader
                onUploadSuccess={handleUploadSuccess}
              />
              {shareClasses.length > 0 && (
                <Tabs
                  activeKey={activeTabKey}
                  onSelect={(k) => setActiveTabKey(Number(k))}
                  id="share-class-tabs"
                  className="mb-2 mt-4"
                >
                  {shareClasses.map((shareClass, index) => (
                    <Tab
                      eventKey={index}
                      title={shareClass.class_name}
                      key={index}
                    >
                      <ShareClass
                        initialState={shareClass}
                        onChange={(data: any, isValid: boolean) =>
                          handleShareClassChange(index, data, isValid)
                        }
                      />
                    </Tab>
                  ))}
                </Tabs>
              )}

              {/* Render the "Create Token(s)" button only if there is at least one share class */}
              {shareClasses.length > 0 && (
                <Button
                  variant="primary"
                  type="submit"
                  className="mb-3"
                  disabled={loading || !isFormValid()}
                >
                  {loading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                    'Create Token(s)'
                  )}
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
      <SidePopout />
    </>
  );
}
