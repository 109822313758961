import React, { useState, useRef } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import axios from 'axios';
import './index.css';
import { useAuth } from '../../contexts/AuthContext';
import { useError } from '../../contexts/ErrorContext';

export default function Onboarding() {
  const { auth } = useAuth();
  const { setError } = useError();
  const navigate = useNavigate();
  const [stage, setStage] = useState<string>();
  const [progress, setProgress] = useState<number>();
  const location = useLocation();

  React.useEffect(() => {
    (async () => {
      if (auth?.token) {
        try {
          // Fetch onboarding status for the user
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/users/me/onboarding_status`,
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
                'X-Account-ID': auth?.user.account_id,
              },
            }
          );

          // Update the stage based on the response
          const onboardingStage = response.data.stage;
          setProgress(response.data.progress);
          navigate(onboardingStage);
        } catch (error) {
          console.error('Error fetching onboarding status:', error);
          setError(error);
        }
      } else {
        // User is not authenticated
        navigate('/login');
      }
    })();
  }, [auth, navigate]);

  const navigateToStage = (stage: string) => {
    setStage(stage);
    navigate(stage);
  };

  const parseError = (error: any) => {
    if (error?.response?.data) return JSON.stringify(error?.response?.data);
    if (error?.reason) return JSON.stringify(error?.reason);
  };

  return (
    <div className="min-vh-100 bg-primary">
      <div className="h-100 d-flex flex-column align-items-center justify-content-center mx-2 py-5">
        <div className="col-lg-6 col-12">
          <div className="d-flex justify-content-center align-items-center">
            <Card className="w-100">
              <Card.Body>
                <div className="row mt-3">
                  <div className="col-8 offset-2 text-center">
                    {/* <img
                      src={logo}
                      alt="CapSign"
                      width="200"
                      className="img-fluid"
                    /> */}
                    <h3 className="my-4">Onboarding</h3>
                    <div className="progress mb-3" role="progressbar">
                      <div
                        className={`progress-bar progress-bar-striped progress-bar-animated ${progress === 100 && 'bg-success'}`}
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="offset-2 col-8">
                    <hr />
                  </div>
                </div>

                <div className="w-100">
                  <ul className="nav d-flex justify-content-center my-3">
                    <li className="nav-item">
                      <Button
                        variant="link"
                        onClick={() =>
                          navigateToStage('/onboarding/account/type')
                        }
                        className={`nav-link ${
                          location.pathname.includes('/onboarding/account/type')
                            ? 'active border'
                            : ''
                        } ${stage !== location.pathname ? 'disabled' : ''}`}
                      >
                        1. Account
                      </Button>
                    </li>
                    <li className="nav-item">
                      <Button
                        variant="link"
                        onClick={() => navigateToStage('/onboarding/entity')}
                        className={`nav-link ${
                          location.pathname.includes('/onboarding/entity')
                            ? 'active border'
                            : ''
                        } ${stage !== location.pathname ? 'disabled' : ''}`}
                      >
                        2. Details
                      </Button>
                    </li>
                    <li className="nav-item">
                      <Button
                        variant="link"
                        onClick={() =>
                          navigateToStage(
                            '/onboarding/create-id?walletType=self-custody'
                          )
                        }
                        className={`nav-link ${
                          location.pathname.includes(
                            '/onboarding/create-id?walletType=self-custody'
                          )
                            ? 'active border'
                            : ''
                        } ${stage !== location.pathname ? 'disabled' : ''}`}
                      >
                        3. Create DID
                      </Button>
                    </li>
                    {/* <li className="nav-item">
                      <Button
                        variant="link"
                        onClick={() => navigateToStage(5, "/onboarding/connect")}
                        className={`nav-link ${
                          location.pathname.includes('/onboarding/connect')
                            ? 'active border'
                            : ''
                        } ${stage < 5 ? 'disabled' : ''}`}
                      >
                        4. Connect
                      </Button>
                    </li> */}
                  </ul>
                </div>

                <Outlet />
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
