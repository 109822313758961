import { useAccount } from '../../contexts/AccountContext';
import CorporateDocuments from './CorporateDocuments';
import IdentityDocuments from './IdentityDocuments';

export default function Archive() {
  const { account } = useAccount();

  return account?.account_type === 'Individual' ? (
    <IdentityDocuments />
  ) : (
    <CorporateDocuments />
  );
}
