import React, { useState } from 'react';
import {
  Nav,
  Tab,
  Table,
  Button,
  Dropdown,
  Row,
  Col,
  Container,
} from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

const securityTypes = [
  'Shares',
  'Options',
  'RSAs',
  'RSUs',
  'SARs',
  'Warrants',
  'Convertibles',
];

const IssueEquity = () => {
  const [activeKey, setActiveKey] = useState(securityTypes[0]);
  const location = useLocation();

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Issue
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container className="mt-5">
        <Row className="justify-content-md-center">
          <Col md={11}>
            <Tab.Container
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k || securityTypes[0])}
            >
              <Nav variant="pills" className="mb-3">
                {securityTypes.map((type) => (
                  <Nav.Item key={type}>
                    <Nav.Link eventKey={type} disabled={type !== 'Shares'}>
                      {type}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                {securityTypes.map((type) => (
                  <Tab.Pane eventKey={type} key={type}>
                    <Button variant="primary" className="mb-3">
                      New {type.toLowerCase()} draft set
                    </Button>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Draft Name</th>
                          <th>Status</th>
                          <th>Number of securities</th>
                          <th>Last updated</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Example row, replace with dynamic data */}
                        <tr>
                          <td>Example Draft</td>
                          <td>Active</td>
                          <td>100</td>
                          <td>2023-10-01</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                              >
                                ...
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Edit draft set
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Delete draft set
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                        {/* Add more rows as needed */}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default IssueEquity;
