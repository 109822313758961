import React, { useEffect, useState, useMemo } from 'react';
import VestingConditionsDisplay from './VestingConditionsDisplay';
import CustomVestingConditions from './CustomVestingConditions';

const generateMonthlyVesting = (
  tokenAmount: number,
  totalMonths: number,
  cliffMonths: number,
  startDate: Date | null
) => {
  const conditions = [];
  const vestingStartDate = startDate || new Date();
  const vestingStartTimestamp = Math.floor(vestingStartDate.getTime() / 1000);

  // Calculate the portion of shares that vest at the end of the cliff
  const cliffPortionNumerator = cliffMonths;
  const cliffPortionDenominator = totalMonths;

  // Calculate the portion of shares that vest at the end of the cliff
  const cliffShares = Math.floor((cliffMonths / totalMonths) * tokenAmount);

  // Add cliff condition
  if (cliffMonths > 0) {
    const cliffTriggerDate = calculateTriggerDate(
      cliffMonths,
      vestingStartDate
    );
    conditions.push({
      id: `cliff_${cliffMonths}`,
      description: `Cliff of ${cliffMonths} months`,
      portionNumerator: cliffPortionNumerator,
      portionDenominator: cliffPortionDenominator,
      triggerType: 'date',
      triggerDate: cliffTriggerDate,
      quantity: 0,
      relativeToConditionId: '',
      nextConditionIds: [],
    });
  }

  // Calculate elapsed months since the start date
  const currentDate = new Date();
  const elapsedMonths = Math.floor(
    (currentDate.getTime() - vestingStartDate.getTime()) /
      (1000 * 60 * 60 * 24 * 30)
  );

  // Calculate already vested shares
  let alreadyVestedShares = 0;
  if (elapsedMonths >= cliffMonths) {
    alreadyVestedShares = cliffShares;
    const postCliffMonths = elapsedMonths - cliffMonths;
    const sharesPerMonth = Math.floor(
      (tokenAmount - cliffShares) / (totalMonths - cliffMonths)
    );
    alreadyVestedShares += Math.min(
      postCliffMonths * sharesPerMonth,
      tokenAmount - cliffShares
    );
  }

  // Start vesting after the cliff period
  const remainingMonths = totalMonths - cliffMonths;
  let cumulativeVested = alreadyVestedShares;

  for (let month = cliffMonths + 1; month <= totalMonths; month++) {
    if (month <= elapsedMonths) {
      // Skip months that have already vested
      continue;
    }

    let sharesVesting = Math.floor(
      (tokenAmount - cliffShares) / remainingMonths
    );
    if (month === totalMonths) {
      // Adjust the final month to account for rounding errors
      sharesVesting = tokenAmount - cumulativeVested;
    }
    cumulativeVested += sharesVesting;

    conditions.push({
      id: `month_${month}`,
      description: `Vest ${((sharesVesting / tokenAmount) * 100).toFixed(2)}% at month ${month}`,
      portionNumerator: sharesVesting,
      portionDenominator: tokenAmount,
      triggerType: 'date',
      triggerDate: calculateTriggerDate(month, vestingStartDate),
      quantity: sharesVesting,
      relativeToConditionId: '',
      nextConditionIds: [],
    });
  }

  return conditions;
};

const generateBoxcarVesting = (startDate: Date | null) => {
  const conditions = [];
  const vestingPortion = 0.25; // 25%
  const totalPortionNumerator = 1;
  const totalPortionDenominator = 4;

  for (let year = 1; year <= 4; year++) {
    conditions.push({
      id: `boxcar_${year}`,
      description: `Vest ${(vestingPortion * 100).toFixed(0)}% at Year ${year}`,
      portionNumerator: totalPortionNumerator,
      portionDenominator: totalPortionDenominator,
      triggerType: 'date',
      triggerDate: calculateTriggerDate(year * 12, startDate!),
      quantity: 0,
      relativeToConditionId: '',
      nextConditionIds: [],
    });
  }
  return conditions;
};

const calculateTriggerDate = (monthsFromStart: number, startDate: Date) => {
  const triggerDate = new Date(startDate);
  triggerDate.setMonth(triggerDate.getMonth() + monthsFromStart);
  return Math.floor(triggerDate.getTime()); // Unix timestamp in seconds
};

const VestingSchedule = ({
  onChange,
  tokenAmount,
}: {
  onChange: (data: any) => void;
  tokenAmount: string;
}) => {
  const [vestingType, setVestingType] = useState('');
  const [vestingConditions, setVestingConditions] = useState<any[]>([]);
  const [customConditions, setCustomConditions] = useState([]);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [vestingName, setVestingName] = useState('');
  const [vestingDescription, setVestingDescription] = useState('');
  const [allocationType, setAllocationType] = useState('CUMULATIVE_ROUNDING');

  // Memoize the vesting data to prevent unnecessary updates
  const memoizedVestingData = useMemo(
    () => ({
      vestingType,
      vestingConditions,
      startDate,
      vestingName,
      vestingDescription,
      allocationType,
    }),
    [
      vestingType,
      vestingConditions,
      startDate,
      vestingName,
      vestingDescription,
      allocationType,
    ]
  );

  // Call onChange whenever memoized vesting data changes
  useEffect(() => {
    onChange(memoizedVestingData);
  }, [memoizedVestingData, onChange]);

  const handleVestingTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedType = event.target.value;
    setVestingType(selectedType);

    switch (selectedType) {
      case '1/24_monthly_no_cliff':
        setVestingName('1/24 Monthly, No Cliff');
        setVestingDescription('Vesting over 24 months with no cliff.');
        setStartDate(startDate);
        setVestingConditions(
          generateMonthlyVesting(Number(tokenAmount), 24, 0, startDate)
        );
        break;
      case '1/48_monthly_no_cliff':
        setVestingName('1/48 Monthly, No Cliff');
        setVestingDescription('Vesting over 48 months with no cliff.');
        setStartDate(startDate);
        setVestingConditions(
          generateMonthlyVesting(Number(tokenAmount), 48, 0, startDate)
        );
        break;
      case '1/48_monthly_1_year_cliff':
        setVestingName('1/48 Monthly, 1-Year Cliff');
        setVestingDescription('Vesting over 48 months with a 1-year cliff.');
        setStartDate(startDate);
        setVestingConditions(
          generateMonthlyVesting(Number(tokenAmount), 48, 12, startDate)
        );
        break;
      case 'boxcar_vesting':
        setVestingName('Boxcar Vesting');
        setVestingDescription('Vesting in four equal annual installments.');
        setStartDate(startDate);
        setVestingConditions(generateBoxcarVesting(startDate));
        break;
      case 'custom':
        setVestingName('');
        setVestingDescription('');
        setStartDate(null);
        setVestingConditions(customConditions);
        break;
      default:
        setVestingConditions([]);
    }
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = new Date(e.target.value);
    setStartDate(newStartDate);

    // Regenerate vesting conditions based on the new start date
    if (vestingType && vestingType !== 'custom') {
      switch (vestingType) {
        case '1/24_monthly_no_cliff':
          setVestingConditions(
            generateMonthlyVesting(Number(tokenAmount), 24, 0, newStartDate)
          );
          break;
        case '1/48_monthly_no_cliff':
          setVestingConditions(
            generateMonthlyVesting(Number(tokenAmount), 48, 0, newStartDate)
          );
          break;
        case '1/48_monthly_1_year_cliff':
          setVestingConditions(
            generateMonthlyVesting(Number(tokenAmount), 48, 12, newStartDate)
          );
          break;
        case 'boxcar_vesting':
          setVestingConditions(generateBoxcarVesting(newStartDate));
          break;
        default:
          break;
      }
    }
  };

  const handleCustomConditionsChange = (newConditions: any) => {
    setCustomConditions(newConditions);
    setVestingConditions(newConditions);
  };

  return (
    <div>
      <label htmlFor="vesting-type">Vesting Type</label>
      <select
        className="form-select mb-3"
        id="vesting-type"
        value={vestingType}
        onChange={handleVestingTypeChange}
        disabled={!tokenAmount}
      >
        <option value="">Select Vesting Type</option>
        <option value="1/24_monthly_no_cliff">1/24 Monthly, No Cliff</option>
        <option value="1/48_monthly_no_cliff">1/48 Monthly, No Cliff</option>
        <option value="1/48_monthly_1_year_cliff">
          1/48 Monthly, 1-Year Cliff
        </option>
        <option value="boxcar_vesting">Boxcar Vesting</option>
        <option value="custom">Custom Vesting</option>
      </select>

      {vestingType === 'custom' && (
        <CustomVestingConditions
          conditions={customConditions}
          onChange={handleCustomConditionsChange}
          loading={false}
        />
      )}

      {/* Display the vesting conditions */}
      {vestingConditions.length > 0 && (
        <>
          <div className="mb-3">
            <label htmlFor="start-date">Start Date</label>
            <input
              type="date"
              className="form-control"
              id="start-date"
              value={startDate ? startDate.toISOString().substr(0, 10) : ''}
              onChange={handleStartDateChange}
            />
          </div>
          <VestingConditionsDisplay
            conditions={vestingConditions}
            totalShares={parseInt(tokenAmount, 10)}
          />
        </>
      )}
    </div>
  );
};

export default VestingSchedule;
