import axios from 'axios';
import numbro from 'numbro';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { Button, Modal, Nav } from 'react-bootstrap';
import { useWeb3React } from '@web3-react/core';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';
import AchTransfer from './AchTransfer';
import WireTransfer from './WireTransfer';
import Withdrawal from './Withdrawal';

enum TransactionType {
  ACH_TRANSFER,
  WIRE_TRANSFER,
  WITHDRAWAL,
}

export default function TransactionHistory() {
  const { auth } = useAuth();
  const { setError } = useError();
  const [selectedAccount, setSelectedAccount] = React.useState<any>(null);
  const [transactionsLoading, setTransactionsLoading] = React.useState(true);
  const [, setBalance] = React.useState(null);
  const [transactions, setTransactions] = React.useState([]);
  const [amount, setAmount] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const [type, setType] = React.useState<TransactionType>(
    TransactionType.ACH_TRANSFER
  );
  const navigate = useNavigate();
  const [modalContent, setModalContent] = React.useState('');
  const [transactionInProgress, setTransactionInProgress] =
    React.useState(false);

  const handleShowModal = (content: any) => {
    setShowModal(true);
    setModalContent(content);
  };

  const handleClose = () => {
    setAmount(0);
    setSelectedAccount(null);
    navigate('/settings/banking');
    setShowModal(false);
  };

  React.useEffect(() => {
    (async () => {
      await getTransactions();
    })();
  }, []);

  const deposit = async () => {
    try {
      setTransactionInProgress(true);
      await axios(`${process.env.REACT_APP_API_URL}/v1/accounts/me/deposits`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
        data: {
          amount,
          external_account_id: selectedAccount.id,
        },
      });
      alert('ACH successfully initiated.');
    } catch (error) {
      console.log(error);
      alert('There was an error.');
    }
    setTransactionInProgress(false);
  };

  const withdrawal = async () => {
    try {
      setTransactionInProgress(true);
      await axios(
        `${process.env.REACT_APP_API_URL}/v1/accounts/me/withdrawals`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
          data: {
            amount,
            external_account_id: selectedAccount.id,
          },
        }
      );
      alert('Withdraw successfully initiated.');
    } catch (error) {
      alert('There was an error.');
    }
    setTransactionInProgress(false);
  };

  const transfer = async () => {
    if (type === TransactionType.ACH_TRANSFER) await deposit();
    if (type === TransactionType.WITHDRAWAL) await withdrawal();
    await getTransactions();
    await getAccountBalance();
    setShowModal(false);
  };

  const getTransactions = React.useCallback(async () => {
    setTransactionsLoading(true);
    const response = await axios(
      `${process.env.REACT_APP_API_URL}/v1/accounts/me/transactions`,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
        method: 'GET',
      }
    );
    setTransactions(response.data.items);
    setTransactionsLoading(false);
  }, [auth]);

  const getAccountBalance = React.useCallback(async () => {
    const response = await axios(
      `${process.env.REACT_APP_API_URL}/v1/accounts/me`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }
    );
    setBalance(response.data.balances.available_balance);
  }, [auth]);

  /**
   * @function getTransactionType
   * @param {any} transaction
   * @returns {string}
   * Determine the transaction type by the ledger entries
   */
  const getTransactionType = (transaction: any) => {
    const entries = transaction.ledger_entries;

    if (entries?.length === 2) {
      if (entries[0].ledger_account_id === 1) {
        if (entries[0].direction === 'credit') {
          return 'ACH';
        } else if (entries[0].direction === 'debit') {
          return 'Withdrawal';
        }
      }
    }
    return 'Unknown';
  };

  const renderModalContent = () => {
    switch (modalContent) {
      case 'ACH':
        return <AchTransfer />;
      case 'Wire':
        return <WireTransfer />;
      case 'Withdraw':
        return <Withdrawal />;
      default:
        return null;
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create {TransactionType[type]} Transfer</Modal.Title>
        </Modal.Header>
        {type === TransactionType.ACH_TRANSFER && (
          <Modal.Header className="d-flex justify-content-center">
            <Nav
              variant="pills"
              defaultActiveKey="ach"
              onSelect={(selectedKey) => setModalContent(selectedKey || '')}
            >
              <Nav.Item>
                <Nav.Link
                  as={Button}
                  to={`/settings/banking/deposit/ach`}
                  eventKey="ACH"
                >
                  Online deposit
                </Nav.Link>
              </Nav.Item>
              <div className="mx-2"></div>
              <Nav.Item>
                <Nav.Link
                  as={Button}
                  to={`/settings/banking/deposit/wire`}
                  eventKey="Wire"
                >
                  Wire transfer
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Modal.Header>
        )}
        <Modal.Body>{renderModalContent()}</Modal.Body>
      </Modal>
      <div className="card my-3 shadow-sm">
        <div className="card-body p-5">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between mb-2 align-items-baseline">
                <label className="form-label">Transaction History</label>
                {/* <div>
                  <Button
                    className="mx-2"
                    variant="outline-primary"
                    onClick={() =>
                      handleShowModal(TransactionType.ACH_TRANSFER)
                    }
                  >
                    Deposit
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => handleShowModal(TransactionType.WITHDRAWAL)}
                  >
                    Withdraw
                  </Button>
                </div> */}
              </div>
              {transactions?.length && !transactionsLoading ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((tx: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{new Date(tx.created_at).toLocaleString()}</td>
                          <td>{getTransactionType(tx)}</td>
                          <td>
                            $
                            {numbro(tx.ledger_entries[0].amount / 100).format({
                              mantissa: 2,
                              thousandSeparated: true,
                            })}
                          </td>
                          <td>
                            {tx.status.charAt(0).toUpperCase() +
                              tx.status.slice(1)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : transactionsLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: 200 }}
                >
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center py-5 border">
                  <span>No transactions to display</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
