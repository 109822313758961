import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

const Security = () => {
  return (
    <>
      <Container fluid>
        <Row className="g-6 align-items-end justify-content-between my-4">
          <Col>
            <h4 className="fw-semibold my-4">Two-factor authentication</h4>
          </Col>
        </Row>

        <Row className="g-0 g-md-10">
          <Col md={7}>
            <div>
              <div className="d-flex align-items-center px-0">
                <div className="flex-fill">
                  <div className="h6 d-block mb-1">Authentication app</div>
                  <span className="d-block text-sm text-muted">
                    Set up 2FA with Google
                  </span>
                </div>

                <div className="ms-auto text-end">
                  <Button variant="outline-secondary" size="sm" disabled>
                    Setup
                  </Button>
                </div>
              </div>
              <br />
              <div className="d-flex align-items-center px-0">
                <div className="flex-fill">
                  <div className="h6 d-block mb-1">SMS Recovery</div>
                  <span className="d-block text-sm text-muted">
                    Set up 2FA with SMS
                  </span>
                </div>
                <div className="ms-auto text-end">
                  <Button variant="outline-secondary" size="sm" disabled>
                    Setup
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <hr className="my-4" />
        <div className="d-flex align-items-end justify-content-between my-4">
          <div>
            <h4 className="fw-semibold my-4">Password Reset</h4>
          </div>
        </div>

        <Form className="vstack gap-5">
          {['Current password', 'New password', 'Confirm password'].map(
            (label, index) => (
              <Form.Group
                as={Row}
                className="g-3 align-items-center"
                key={index}
              >
                <Form.Label column md={2} className="mb-0">
                  {label}
                </Form.Label>
                <Col md={5}>
                  <Form.Control type="password" />
                </Col>
              </Form.Group>
            )
          )}
        </Form>

        <Col md={7}>
          <div className="d-flex justify-content-end gap-2 my-3">
            <Button variant="primary">Save</Button>
          </div>
        </Col>
      </Container>
    </>
  );
};

export default Security;
