import { Helmet } from 'react-helmet-async';
import CertificateList from './CertificateList';
import SafeList from './SafeList';
import Listings from './Listings';
import Bids from './Bids';

export default function Portfolio() {
  return (
    <div className="container mt-4">
      <Helmet>
        <title>CapSign | Portfolio</title>
      </Helmet>
      <h2>Portfolio</h2>
      <CertificateList />
      <SafeList />
      <div className="pb-5">
        <h2>Activity</h2>
        <div className="row">
          <div className="col-12">
            <Listings />
          </div>
          <div className="mt-4">
            <div className="col-12">
              <Bids />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
