import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useChainId, usePublicClient, useWriteContract } from 'wagmi';
import { contracts } from '../../constants';
import { Abi } from 'viem';

const VestingActionForm = () => {
  const chainId = useChainId();
  const [tokenId, setTokenId] = useState('');
  const publicClient = usePublicClient();
  const { writeContractAsync: updateVestedAmount } = useWriteContract();

  const handleVest = async () => {
    if (!tokenId) {
      alert('Please enter a valid token ID.');
      return;
    }

    try {
      const vestingModuleAddress = contracts[chainId!].VestingModule
        .address as `0x${string}`;
      const vestingModuleAbi = contracts[chainId!].VestingModule.abi as Abi;

      const gas = await publicClient?.estimateContractGas({
        address: vestingModuleAddress,
        abi: vestingModuleAbi,
        functionName: 'updateVestedAmount',
        args: [tokenId],
      });

      await updateVestedAmount({
        address: vestingModuleAddress,
        abi: vestingModuleAbi,
        functionName: 'updateVestedAmount',
        args: [tokenId],
        gas,
      });

      alert('Vested amount updated successfully!');
    } catch (error) {
      console.error('Error updating vested amount:', error);
      alert('Failed to update vested amount.');
    }
  };

  return (
    <div className="vesting-action-form">
      <h4>Update Vested Amount</h4>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Certificate ID</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Enter Certificate ID"
              value={tokenId}
              onChange={(e) => setTokenId(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
        <Button variant="primary" onClick={handleVest}>
          Vest
        </Button>
      </Form>
    </div>
  );
};

export default VestingActionForm;
