import { Auth } from '../../types';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from './Navbar';

export default function Markets() {
  return (
    <div className="h-100">
      <Helmet>
        <title>CapSign | Markets</title>
      </Helmet>
      <Outlet />
    </div>
  );
}
