import React, { useState } from 'react';
import { Contract, providers, utils } from 'ethers';
import axios from 'axios';
import numbro from 'numbro';
import { formatOptions } from '../../../constants';
import { useWeb3React } from '@web3-react/core';
import { subscriptionToken, decimals } from '../../../utils';
import Modal from 'react-bootstrap/Modal';
import { NavLink, Link, useParams } from 'react-router-dom';
import { contracts } from '../../../constants';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';

export default function Primary() {
  const { auth } = useAuth();
  const { setError } = useError();
  const { chainId, library } = useWeb3React();
  const { tokenId } = useParams();
  const [amount, setAmount] = useState('');
  const [cryptoPrice, setCryptoPrice] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({
    hash: '',
    amount: '',
    date: '',
  });

  // Fetch the native crypto price when the component mounts
  React.useEffect(() => {
    const fetchCryptoPrice = async () => {
      try {
        const ids: any = {
          ETH: 'ethereum',
          USDC: 'usdc',
          XRP: 'ripple',
        };
        const assetName = ids[subscriptionToken[chainId!]];
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=${assetName}&vs_currencies=usd`
        );
        const price = response.data[assetName]?.usd;
        if (price) setCryptoPrice(price);
      } catch (error) {
        console.error(
          `Error fetching ${subscriptionToken[chainId!]} price:`,
          error
        );
      }
    };

    fetchCryptoPrice();
  }, []);

  const handleInvestment = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!(window as any).ethereum) {
      alert('Please install MetaMask to proceed.');
      return;
    }

    if (!agreed) {
      alert('You must agree to the terms to proceed.');
      return;
    }

    setIsPending(true); // Start the transaction and disable the button

    try {
      const provider = new providers.Web3Provider((window as any).ethereum);
      const signer = provider.getSigner();
      const account = await signer.getAddress();
      const SAFE = new Contract(
        tokenId!,
        contracts[chainId!]?.SAFE?.abi,
        signer
      );
      const USD = new Contract(
        contracts[chainId!]?.USD?.address,
        contracts[chainId!]?.USD?.abi,
        signer
      );

      // Parameters for permit
      const nonce = await USD.nonces(account);
      const deadline = Math.floor(new Date().getTime() / 1000) + 3600; // 1 hour from now
      const domain = {
        name: subscriptionToken[chainId!],
        version: '1', // Replace with the token's version
        chainId: chainId!,
        verifyingContract: tokenId!,
      };
      const types = {
        Permit: [
          { name: 'owner', type: 'address' },
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'deadline', type: 'uint256' },
        ],
      };
      const value = {
        owner: account,
        spender: SAFE.address,
        value: utils.parseUnits(amount.toString(), decimals[chainId!]),
        nonce,
        deadline,
      };

      // Sign the permit
      const signature = await signer._signTypedData(domain, types, value);
      const { v, r, s } = utils.splitSignature(signature);

      // Now call investWithPermit with all the required info
      // const params = [utils.parseUnits(amount.toString(), decimals[chainId!]), true, deadline, v, r, s, { gasLimit: 1_000_000 }]
      const params = [
        utils.parseUnits(amount.toString(), decimals[chainId!]),
        true,
        { gasLimit: 1_000_000 },
      ];
      console.log(SAFE.address);
      let tx = await USD.approve(
        SAFE.address,
        utils.parseUnits(amount.toString(), decimals[chainId!])
      );
      await USD.callStatic.approve(
        SAFE.address,
        utils.parseUnits(amount.toString(), decimals[chainId!])
      );
      await tx.wait();
      await SAFE.callStatic.invest(...params);
      tx = await SAFE.invest(...params);
      // await SAFE.callStatic.investWithPermit(...params);
      // const tx = await SAFE.investWithPermit(...params);
      await tx.wait();

      // Transaction successful
      setIsPending(false);
      setTransactionDetails({
        hash: tx.hash,
        amount: amount,
        date: new Date().toLocaleString(),
      });
      setShowModal(true);
    } catch (error: any) {
      setError(error);
      setIsPending(false);
    }
    setAmount('');
    setAgreed(false);
  };

  const handleCloseModal = () => setShowModal(false);

  const printModalContent = () => {
    window.print();
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 col-md-4 offset-md-4 col-sm-12 mt-3">
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Investment Confirmed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-truncate">
            Transaction Hash:{' '}
            <Link className="" to={''} target="_blank">
              {transactionDetails.hash}
            </Link>
          </p>
          <p>
            Amount: {transactionDetails.amount} {subscriptionToken[chainId!]}
          </p>
          <p>Date: {transactionDetails.date}</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={printModalContent}>
            Print
          </button>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="card p-5" style={{ width: 'auto' }}>
        <div className="card-body text-justify">
          <form onSubmit={handleInvestment}>
            <h5>SAFE Investment</h5>
            <p className="mb-3">
              This uncapped post-money SAFE offers a 15% discount to a priced
              equity round of financing.
            </p>
            <div className="form-group mb-3">
              <NavLink to="data-room" className="text-decoration-none">
                View Data Room <i className="fa fa-folder-open" />
              </NavLink>
            </div>
            <div className="form-group mb-3">
              <a
                className="text-decoration-none"
                href="https://docs.google.com/document/d/1_V1h5KtNpmZlwKK8E43NQ_tt-TuaeqwH/edit?usp=sharing&ouid=106792124455358745131&rtpof=true&sd=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Investment Agreement <i className="fa fa-file-contract" />
              </a>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="amount">Investment Amount</label>
              <input
                type="number"
                className="form-control"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder={`Enter amount in ${subscriptionToken[chainId!]}`}
                min="0.01"
                step="0.01"
                required
              />
              <small className="text-muted">
                Minimum: 7,500 {subscriptionToken[chainId!]}
              </small>
            </div>
            <div className="form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="agreeTerms"
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="agreeTerms">
                I agree to the terms of the investment
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!agreed || !amount || Number(amount) < 7500}
            >
              Invest
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
