import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const DisclaimerModal = ({ show, handleClose }: any) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Disclaimer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        “CapSign” refers to CapSign Inc. and its affiliates. CapSign Inc. is a
        technology company offering a range of financial products and services
        through its wholly-owned, separately managed but affiliated
        subsidiaries, CapSign Securities LLC and California Capital Advisors
        LLC. This information is provided by CapSign for educational and
        illustrative purposes only and is not considered an offer, solicitation
        of an offer, advice, or recommendation to buy, sell, or hold any
        security. All investing involves risk, including the risk of losing the
        money you invest, and past performance does not guarantee future
        performance. CapSign relies on information from various sources believed
        to be reliable, including information from its Customers, Clients, and
        other third parties, but cannot guarantee the accuracy or completeness
        of that information. CapSign Inc. does not provide tax, legal, or
        investment advice.
        <br />
        <br />
        Use of this website constitutes acceptance of our{' '}
        <Link
          target="_blank"
          to="https://app.termly.io/document/terms-of-service/45871e05-0337-4b1e-80f0-bae60d7df509"
        >
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link
          target="_blank"
          to="https://app.termly.io/document/privacy-policy/3fbdf65a-0739-459f-b652-dd29d3a4428b"
        >
          Privacy Policy
        </Link>
        .
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            handleClose();
            localStorage.setItem('disclaimerAcknowledged', 'true');
          }}
        >
          I Acknowledge
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Footer = () => {
  const [showDisclaimer, setShowDisclaimer] = React.useState(false);

  // React.useEffect(() => {
  //   // Check if the disclaimer has been acknowledged
  //   const disclaimerAcknowledged = localStorage.getItem('disclaimerAcknowledged');
  //   if (!disclaimerAcknowledged) {
  //     setShowDisclaimer(true);
  //   }
  // }, []);

  return (
    <div className="footer text-center py-5 border-top container-fluid">
      <div className="row">
        <div className="col-10 offset-1">
          <p className="text-muted" style={{ textAlign: 'justify' }}>
            <small>
              “CapSign” refers to CapSign Inc. and its affiliates. CapSign Inc.
              is a technology company offering a range of financial products and
              services through its wholly-owned, separately managed but
              affiliated subsidiaries, CapSign Securities LLC and California
              Capital Advisors LLC. This information is provided by CapSign for
              educational and illustrative purposes only and is not considered
              an offer, solicitation of an offer, advice, or recommendation to
              buy, sell, or hold any security. All investing involves risk,
              including the risk of losing the money you invest, and past
              performance does not guarantee future performance. CapSign relies
              on information from various sources believed to be reliable,
              including information from its Customers, Clients, and other third
              parties, but cannot guarantee the accuracy or completeness of that
              information. CapSign Inc. does not provide tax, legal, or
              investment advice.{' '}
            </small>
          </p>
          <p className="text-muted">
            <small>
              Use of this website constitutes acceptance of our{' '}
              <Link
                target="_blank"
                to="https://app.termly.io/document/terms-of-service/45871e05-0337-4b1e-80f0-bae60d7df509"
              >
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link
                target="_blank"
                to="https://app.termly.io/document/privacy-policy/3fbdf65a-0739-459f-b652-dd29d3a4428b"
              >
                Privacy Policy
              </Link>
              .
            </small>
          </p>
        </div>
        <div>© 2023 CapSign Inc.</div>
        <div>
          <a
            href="https://x.com/CapSignInc"
            target="_blank"
            rel="noreferrer"
            className="mx-2"
          >
            <div className="mt-3 fa-brands fa-x-twitter" />
          </a>
          <a
            href="https://linkedin.com/companies/capsign"
            target="_blank"
            rel="noreferrer"
            className="mx-2"
          >
            <div className="mt-3 fa-brands fa-linkedin" />
          </a>
          <a
            href="https://facebook.com/@CapSignInc"
            target="_blank"
            rel="noreferrer"
            className="mx-2"
          >
            <div className="mt-3 fa-brands fa-facebook" />
          </a>
          <a
            href="https://github.com/capsign"
            target="_blank"
            rel="noreferrer"
            className="mx-2"
          >
            <div className="mt-3 fa-brands fa-github" />
          </a>
          <a
            href="https://youtube.com/@CapSign"
            target="_blank"
            rel="noreferrer"
            className="mx-2"
          >
            <div className="mt-3 fa-brands fa-youtube" />
          </a>
        </div>
        <DisclaimerModal
          show={showDisclaimer}
          handleClose={() => setShowDisclaimer(false)}
        />
      </div>
    </div>
  );
};

export default Footer;
