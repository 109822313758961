import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const posts = [
  {
    id: 1,
    title: 'Announcing CapSign',
    content: '<p>Test</p>',
    author: 'Matt Rosendin',
    date: 'October 7, 2023',
    image: 'https://via.placeholder.com/150',
  },
  // ...other posts
];

const Sidebar = () => {
  return (
    <div className="col-md-4">
      <ul className="list-group">
        {posts.map((post) => (
          <li key={post.id} className="list-group-item">
            <Link to={`/blog/${post.id}`}>{post.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Post = ({ match }: any) => {
  const postId = match.params.id;
  const post = posts.find((p) => p.id === parseInt(postId));

  return (
    <div className="col-md-8">
      {post ? (
        <>
          <h1>{post.title}</h1>
          <p className="text-muted">
            By {post.author} on {post.date}
          </p>
          <div
            className="mb-5 rounded"
            style={{
              height: 500,
              backgroundImage: `url(${post.image})`,
              width: '100%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          ></div>
          <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
        </>
      ) : (
        <p>Post not found</p>
      )}
    </div>
  );
};

const Blog = () => {
  return (
    <Router>
      <div className="container mt-5">
        <div className="row p-5">
          <Sidebar />
          <Route path="/blog/:id" element={<Post />} />
        </div>
      </div>
    </Router>
  );
};

export default Blog;
