import React, { createContext, useContext, useState, ReactNode } from 'react';

type AppDataContextType = {
  appData: {
    app_name: string;
    app_logo_url: string;
    app_website: string;
    app_long_description: string;
    categories: string[];
  } | null;
  setAppData: (token: string | null) => void;
};

const AppDataContext = createContext<AppDataContextType | undefined>(undefined);

export const useAppData = () => {
  const context = useContext(AppDataContext);
  if (context === undefined) {
    throw new Error('useAppData must be used within an AppDataProvider');
  }
  return context;
};

interface AppDataProviderProps {
  children: ReactNode;
}

export const AppDataProvider: React.FC<AppDataProviderProps> = ({
  children,
}) => {
  const [appData, setAppData] = useState<any>(() => {
    return null;
  });

  const value = { appData, setAppData };

  return (
    <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>
  );
};
