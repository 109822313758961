import React, { useState } from 'react';
import { Col, Row, ListGroup, Form, Container } from 'react-bootstrap';

interface PermissionsByCategory {
  [category: string]: string[];
}

const permissionsByCategory: PermissionsByCategory = {
  'API Client Management': [
    'create_api_client',
    'view_api_client',
    'edit_api_client',
    'delete_api_client',
    'org:create_api_client',
    'org:view_api_client',
    'org:edit_api_client',
    'org:delete_api_client',
    'admin:create_api_client',
    'admin:view_api_client',
    'admin:edit_api_client',
    'admin:delete_api_client',
  ],
  'User Management': [
    'create_user',
    'view_user',
    'edit_user',
    'delete_user',
    'org:create_user',
    'org:view_user',
    'org:edit_user',
    'org:delete_user',
    'admin:create_user',
    'admin:view_user',
    'admin:edit_user',
    'admin:delete_user',
  ],
  'Asset Management': [
    'org:create_asset',
    'org:view_asset',
    'org:edit_asset',
    'org:delete_asset',
    'admin:create_asset',
    'admin:view_asset',
    'admin:edit_asset',
    'admin:delete_asset',
  ],
  'Entity Management': [
    'org:create_entity',
    'org:view_entity',
    'org:edit_entity',
    'org:delete_entity',
    'admin:create_entity',
    'admin:view_entity',
    'admin:edit_entity',
    'admin:delete_entity',
  ],
  'Account Management': [
    'create_account',
    'view_account',
    'edit_account',
    'delete_account',
    'admin:create_account',
    'admin:view_account',
    'admin:edit_account',
    'admin:delete_account',
  ],
  'Document Management': [
    'create_document',
    'view_document',
    'edit_document',
    'verify_document',
    'delete_document',
    'org:create_document',
    'org:view_document',
    'org:edit_document',
    'org:verify_document',
    'org:delete_document',
    'admin:create_document',
    'admin:view_document',
    'admin:edit_document',
    'admin:verify_document',
    'admin:delete_document',
  ],
  'Cap Table Management': [
    'org:create_table',
    'org:view_table',
    'org:edit_table',
    'org:delete_table',
    'admin:create_table',
    'admin:view_table',
    'admin:edit_table',
    'admin:delete_table',
  ],
  'Logging Management': ['view_logs', 'org:view_logs', 'admin:view_logs'],
  'Ticket Management': [
    'create_ticket',
    'view_ticket',
    'edit_ticket',
    'org:create_ticket',
    'org:view_ticket',
    'org:edit_ticket',
    'admin:create_ticket',
    'admin:view_ticket',
    'admin:edit_ticket',
    'admin:delete_ticket',
  ],
  'Bank Account Management': [
    'create_bank_account',
    'view_bank_account',
    'edit_bank_account',
    'delete_bank_account',
    'org:create_bank_account',
    'org:view_bank_account',
    'org:edit_bank_account',
    'org:delete_bank_account',
    'admin:create_bank_account',
    'admin:view_bank_account',
    'admin:edit_bank_account',
    'admin:delete_bank_account',
  ],
  'Node Management': [
    'create_node',
    'view_node',
    'edit_node',
    'delete_node',
    'org:create_node',
    'org:view_node',
    'org:edit_node',
    'org:delete_node',
    'admin:create_node',
    'admin:view_node',
    'admin:edit_node',
    'admin:delete_node',
  ],
  'Transaction Management': [
    'create_transaction',
    'view_transaction',
    'edit_transaction',
    'org:create_transaction',
    'org:view_transaction',
    'org:edit_transaction',
    'admin:create_transaction',
    'admin:view_transaction',
    'admin:edit_transaction',
  ],
  'Identity Management': [
    'create_identity',
    'view_identity',
    'edit_identity',
    'admin:create_identity',
    'admin:view_identity',
    'admin:edit_identity',
  ],
};

function Permissions({ selectedPermissions, handlePermissionChange }: any) {
  const [activeCategory, setActiveCategory] = useState<any>(
    Object.keys(permissionsByCategory)[0]
  );

  return (
    <Container fluid>
      <Row>
        <Col md={5} className="ps-0 pe-2">
          <ListGroup>
            {Object.keys(permissionsByCategory).map((category) => (
              <ListGroup.Item
                key={category}
                action
                onClick={(e) => {
                  e.preventDefault();
                  setActiveCategory(category);
                }}
                active={activeCategory === category}
                style={{ cursor: 'pointer' }}
              >
                {category}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col md={7}>
          <h4>{activeCategory}</h4>
          {permissionsByCategory[activeCategory].map((perm: any) => (
            <Form.Check
              key={perm}
              type="checkbox"
              label={perm}
              checked={selectedPermissions?.includes(perm)}
              onChange={() => handlePermissionChange(perm)}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default Permissions;
