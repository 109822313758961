import { Helmet } from 'react-helmet-async';
import BalanceCard from './BalanceCard';
import TransactionHistory from './TransactionHistory';
import PaymentMethods from './PaymentMethods';

function Banking() {
  return (
    <>
      <Helmet>
        <title>CapSign | Banking</title>
      </Helmet>
      {/* <div className="wallet-cards mt-5">
        <BalanceCard />
      </div> */}
      <PaymentMethods />
      <TransactionHistory />
    </>
  );
}

export default Banking;
