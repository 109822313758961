import LandingNav from '../../components/LandingNav';
import accelerate from './accelerate.jpeg';
import Matt from './Matt.jpg';
import Footer from '../../components/Footer';
import './index.css';

function Company() {
  return (
    <div>
      <LandingNav />
      <section className="hero" />
      <div className="block d-flex align-items-center bg-light">
        <div className="container-fluid">
          <div className="row my-md-5 text-start">
            <div className="col-md-8 offset-md-2 col-xs-12 my-5 text-block">
              <h1 className="text-primary">Our Mission</h1>
              <p>
                Our mission is to enable more opportunities and greater economic
                benefits for private companies, shareholders, and investors
                through blockchain technology. We believe that interoperability
                will be a major driver for compliance automation, deep
                liquidity, and composability between financial services.
              </p>
            </div>
          </div>
          <div className="row text-start my-md-5">
            <div className="offset-md-1 col-md-5 col-xs-12">
              <h3 className="text-primary">Accelerating Finance</h3>
              <div className="offset-md-1 col-md-4 col-12 mb-4 d-flex align-items-center d-md-none">
                <img
                  src={accelerate}
                  alt=""
                  className="rounded img-fluid d-md-none"
                />
              </div>
              <p className="lead text-block">
                {' '}
                We're at the frontier of accelerating the future of financial
                services using blockchain-based ledgers. Since we're ahead of
                the curve, we believe it's best to engage with the public sector
                and regulators to ensure innovation thrives in the United States
                of America. Innovation and regulatory compliance is in our DNA.
                Our team witnessed firsthand the{' '}
                <a
                  href="https://www.reuters.com/legal/legalindustry/ripple-effects-developments-following-groundbreaking-decision-sec-v-ripple-labs-2023-12-05/"
                  target="_blank"
                  rel="noreferrer"
                >
                  SEC v. Ripple Labs
                </a>{' '}
                lawsuit, an event that culiminated in a groundbreaking legal
                victory for Ripple. Our team previously worked with the
                nationally recognized{' '}
                <a
                  href="https://www.wsgr.com/en/services/practice-areas/regulatory/fintech-and-financial-services.html"
                  target="_blank"
                >
                  Wilson Sonsini FinTech team
                </a>{' '}
                on complex regulatory issues and we've even written{' '}
                <a
                  href="https://dfpi.ca.gov/wp-content/uploads/sites/337/2022/09/Linqto-comment-letter-8.5.22.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  comment letters
                </a>{' '}
                to the California DFPI's Office of Financial Technology
                Innovation to advocate for the nascent industry. We are
                committed to making forward progress above all, with a stellar
                track record for making responsible decisions in the face of
                legal uncertainty. This endeavor is no different; we are fully
                compliant with securities and money transmission laws, and offer
                our 8+ years of FinTech and blockchain expertise to our
                customers.
              </p>
            </div>
            <div className="offset-md-1 col-md-4 col-12 mb-4 d-flex align-items-center">
              <img
                src={accelerate}
                alt=""
                className="rounded img-fluid d-md-block d-none"
              />
            </div>
          </div>
          <div className="row py-5 bg-primary-subtle">
            <div className="col-md-12 text-center">
              <h1 className="text-primary">Leadership</h1>
              <div className="row">
                <div className="offset-2 col-8">
                  <hr />
                </div>
              </div>
              <div className="row mt-5">
                <div className="offset-md-2 col-md-4 col-xs-12">
                  <img
                    src={Matt}
                    width="500"
                    className="img-fluid border rounded mb-2"
                    alt="Matt Rosendin"
                  />
                  <div>
                    <h3>Matt Rosendin</h3>
                    <h5>Founder</h5>
                  </div>
                </div>
                <div className="col-md-4 col-xs-12 text-block">
                  <p>
                    Matt started his career in technology at Zendesk just after
                    their IPO in 2015 after graduating with a B.S. in economics
                    from Santa Clara University. After co-founding his first
                    startup with friends while earning a Master of Engineering
                    degree from UC Berkeley, Matt joined Ripple Labs as an
                    engineer in global payments.
                  </p>
                  <p>
                    Days after the SEC brought their lawsuit, he co-founded
                    Trustline, a company that pioneered decentralized finance on
                    the XRP Ledger. Trustline was acquired by Linqto, a FINRA
                    member and SEC-registered broker, where he helped launch an
                    alternative trading system for restricted securities.
                  </p>
                  <p>
                    CapSign was founded in September 2023 to accelerate the
                    future of finance, making it easy for companies to adopt
                    blockchain for corporate finance. He lives in the San
                    Francisco Bay Area where he has called home for the past 30+
                    years.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5">
            <div className="row">
              <div className="offset-md-2 col-md-8 col-xs-12">
                <div className="card">
                  <div className="card-body p-md-5 p-4">
                    <p className="lead mb-0">
                      <h3 className="text-primary">
                        Interested in joining the team?
                      </h3>
                      Although we aren't hiring, individuals with exceptional
                      engineering, business, or compliance skill sets are
                      welcome to send a message. Please reach us at{' '}
                      <a
                        href="mailto:careers@capsign.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        careers@capsign.com
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Company;
