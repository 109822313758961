import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

export default function Accreditation() {
  const [isAccreditedInvestor, setIsAccreditedInvestor] = useState(false);
  const { auth } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!isAccreditedInvestor) {
      // Optionally handle the case where the user does not confirm they are an accredited investor
      alert('Please confirm that you are an accredited investor.');
      return;
    }

    try {
      const headers = {
        Authorization: `Bearer ${auth?.token}`,
        'X-Account-ID': auth?.user.account_id,
      };

      // Placeholder for where you might send data to your backend
      console.log('Submitting accredited investor status');

      // Navigate to the next stage after successful submission
      navigate('/onboarding/create-id/custodial-wallet');
    } catch (error) {
      console.error('Error submitting accredited investor status:', error);
      // Handle error case, possibly setting an error state and displaying it to the user
    }
  };

  return (
    <div className="row">
      <Form className="col-lg-4 offset-lg-4 col-md-12 py-5" onSubmit={onSubmit}>
        <Form.Group controlId="accreditedInvestorCheckbox">
          <Form.Check
            type="checkbox"
            label="I self-certify that I am an accredited investor."
            checked={isAccreditedInvestor}
            onChange={(e) => setIsAccreditedInvestor(e.target.checked)}
          />
        </Form.Group>
        <Button variant="primary" className="mt-5 w-100" type="submit">
          Continue
        </Button>
      </Form>
    </div>
  );
}
