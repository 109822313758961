import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import {
  Card,
  Container,
  Button,
  Row,
  Col,
  Modal,
  Form,
} from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function CorporateDocuments() {
  const { auth } = useAuth();
  const [documents, setDocuments] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState<any>({
    documentType: '',
    documentTitle: '',
    file: null,
  });
  const [previewUrls, setPreviewUrls] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/business_entities/me/documents`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setDocuments(response.data.documents);
      } catch (error) {
        console.error('Failed to fetch documents:', error);
      }
    })();
  }, [auth?.token]);

  useEffect(() => {
    documents.forEach(async (doc: any) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/business_entities/me/documents/${doc.entity_document_id}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      console.log(response.data);
      const fileBlob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(fileBlob);
      console.log({ url });
      setPreviewUrls((prevUrls) => ({
        ...prevUrls,
        [doc.entity_document_id]: url,
      }));
    });
  }, [documents, auth?.token]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      file: event.target.files ? event.target.files[0] : null,
    });
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    if (!formData.file) {
      alert('Please select a file.');
      return;
    }
    const data = new FormData();
    data.append('document_type', formData.documentType);
    data.append('document_title', formData.documentTitle);
    data.append('files', formData.file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/business_entities/me/documents`,
        data,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'multipart/form-data',
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      setDocuments([...documents, response.data.document]);
      setShowModal(false);
    } catch (error) {
      console.error('Failed to upload document:', error);
    }
  };

  const handleDownload = async (documentId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/business_entities/me/documents/${documentId}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      // Create a new Blob object using the response data of the file
      const fileBlob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(fileBlob);
      const link = document.createElement('a');
      link.href = url;
      const filename = documentId; // Providing a default filename 'download'
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>CapSign | Documents</title>
      </Helmet>
      <Container fluid>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Document Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter document title"
                  name="documentTitle"
                  value={formData.documentTitle}
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Document Type</Form.Label>
                <Form.Select
                  name="documentType"
                  value={formData.documentType}
                  onChange={handleSelectChange}
                >
                  <option value="">Select document type</option>
                  <option value="Articles of Incorporation">
                    Articles of Incorporation
                  </option>
                  <option value="Bylaws">Bylaws</option>
                  <option value="Shareholder Agreement">
                    Shareholder Agreement
                  </option>
                  <option value="Stock Purchase Agreement">
                    Stock Purchase Agreement
                  </option>
                  <option value="Subscription Agreement">
                    Subscription Agreement
                  </option>
                  <option value="Minutes">Minutes</option>
                  <option value="Certificate of Good Standing">
                    Certificate of Good Standing
                  </option>
                  <option value="Notice Filing">Notice Filing</option>
                  <option value="Annual Report">Annual Report</option>
                  <option value="Quarterly Report">Quarterly Report</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>File</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group>
              <Button variant="primary" onClick={handleSubmit}>
                Upload
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <Row className="my-4">
          <Col
            className="d-flex justify-content-between align-items-center"
            md={{ offset: 1, span: 10 }}
          >
            <h3>Documents</h3>
            <div className="d-flex justify-content-center align-items-center">
              <div className="input-group mx-2">
                <span className="input-group-text">
                  <i className="fa fa-search" />
                </span>
                <input className="form-control" placeholder="Search terms" />
              </div>
              <Button variant="primary" onClick={() => setShowModal(true)}>
                <div className="d-flex align-items-center">
                  <div>Import</div>&nbsp;
                  <i className="fa fa-cloud-arrow-up"></i>
                </div>
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="my-5">
          {documents.length === 0 && (
            <Col md={{ offset: 1, span: 10 }}>No documents to display</Col>
          )}
          {documents.map((doc: any, idx: number) => (
            <Col key={idx} md={{ offset: 1, span: 3 }} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title>
                    {doc.document_title || 'Untitled Document'}
                  </Card.Title>
                  <Card.Text>
                    Type: {doc.document_type}
                    <br />
                    Uploaded: {new Date(doc.created_at).toLocaleDateString()}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button
                    variant="primary"
                    onClick={() => handleDownload(doc.entity_document_id)}
                  >
                    Download
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <hr />
          </Col>
        </Row>
        <Row className="my-4">
          <Col
            className="d-flex justify-content-between align-items-center"
            md={{ offset: 1, span: 10 }}
          >
            <h3>Recent Activity</h3>
          </Col>
        </Row>
        <Row className="my-5">
          {[].length === 0 && (
            <Col md={{ offset: 1, span: 10 }}>No file history to display</Col>
          )}
        </Row>
      </Container>
    </>
  );
}
