import { useEffect, useState } from 'react';
import CAP from './assets/companies/CAP.png';
import CMX from './assets/companies/CMX.png';
import LNQ from './assets/companies/LNQ.png';
import RIP from './assets/companies/RIP.png';
import PAL from './assets/companies/PAL.jpeg';
import localhost from './addresses.localhost.json';
import xrpl_devnet from './addresses.xrpl_devnet.json';
import { contracts } from './constants';

const usdcAbi = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'allowance', type: 'uint256' },
      { internalType: 'uint256', name: 'needed', type: 'uint256' },
    ],
    name: 'ERC20InsufficientAllowance',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'uint256', name: 'balance', type: 'uint256' },
      { internalType: 'uint256', name: 'needed', type: 'uint256' },
    ],
    name: 'ERC20InsufficientBalance',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'approver', type: 'address' }],
    name: 'ERC20InvalidApprover',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'receiver', type: 'address' }],
    name: 'ERC20InvalidReceiver',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
    name: 'ERC20InvalidSender',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'address', name: 'spender', type: 'address' }],
    name: 'ERC20InvalidSpender',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export const nativeAsset: { [key: number]: string } = {
  130: 'CMX',
  8453: 'ETH',
  31337: 'ETH',
  84532: 'ETH',
  1440002: 'XRP',
};

export const subscriptionToken: { [key: number]: string } = {
  130: 'USD',
  8453: 'USDC',
  31337: 'USD',
  84532: 'USDC',
  1440002: 'USD',
};

export const subscriptionTokenAddress: { [key: number]: string } = {
  130: '',
  8453: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913', // USDC
  31337: localhost.tokens.usd,
  84532: '0x036CbD53842c5426634e7929541eC2318f3dCF7e', // USDC
  1440002: xrpl_devnet.tokens.usd,
};

export const subscriptionTokenAbi: { [key: number]: any } = {
  8453: usdcAbi,
  31337: contracts[31337].USD.abi,
  84532: usdcAbi,
};

export const decimals: { [key: number]: number } = {
  130: 6,
  8453: 6,
  31337: 2,
  84532: 6,
  1440002: 2,
};

export const chainIdToNetwork: { [key: number]: string } = {
  130: 'CMX Mainnet',
  8453: 'Base Mainnet',
  31337: 'Hardhat',
  84532: 'Base Sepolia',
  1440002: 'XRPL EVM Devnet',
};

export const explorerBaseUrl: { [key: string]: string } = {
  8453: 'https://basescan.org/',
  31337: '',
  84532: 'https://sepolia-explorer.base.org/',
  1440002: 'https://evm-sidechain.xrpl.org/',
};

export const maxBlockRange: { [key: string]: number } = {
  8453: 2000,
  31337: 9999,
  84532: 2000,
  1440002: 9999,
};

/**
 * Format Utilities
 */

export const formatAccountSubType = (accountSubType: string) => {
  switch (accountSubType) {
    case 'credit card':
      return 'Credit Card';
    case 'checking':
      return 'Checking';
    case 'savings':
      return 'Savings';
    case 'cd':
      return 'CD';
    case 'money market':
      return 'Money Market';
    case 'ira':
      return 'IRA';
    case 'student':
      return 'Student';
    case 'mortgage':
      return 'Mortgage';
    default:
      return accountSubType;
  }
};

export function capitalizeString(
  input: string,
  capitalizeAll?: boolean
): string {
  if (['safe', 'saft', 'rsu'].includes(input)) capitalizeAll = true;
  if (capitalizeAll) {
    return input.toUpperCase();
  } else {
    return input
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
}

export function formatRelativeTime(date1: Date, date2: Date): string {
  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  // Convert Date objects to milliseconds for subtraction
  const diffTime = date2.getTime() - date1.getTime();

  // Calculate the differences in various units
  const diffSeconds = Math.round(diffTime / 1000);
  const diffMinutes = Math.round(diffSeconds / 60);
  const diffHours = Math.round(diffMinutes / 60);
  const diffDays = Math.round(diffHours / 24);
  const diffMonths = Math.round(diffDays / 30);
  const diffYears = Math.round(diffDays / 365);

  // Format the largest non-zero unit
  if (Math.abs(diffYears) > 0) {
    return rtf.format(-diffYears, 'year');
  } else if (Math.abs(diffMonths) > 0) {
    return rtf.format(-diffMonths, 'month');
  } else if (Math.abs(diffDays) > 0) {
    return rtf.format(-diffDays, 'day');
  } else if (Math.abs(diffHours) > 0) {
    return rtf.format(-diffHours, 'hour');
  } else if (Math.abs(diffMinutes) > 0) {
    return rtf.format(-diffMinutes, 'minute');
  } else {
    return rtf.format(-diffSeconds, 'second');
  }
}

/* Scroll Utilities */

export const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

const throttle = (callback: any, sleepTime: any) => {
  let time = Date.now();

  return (...args: any) => {
    if (time + sleepTime - Date.now() < 0) {
      callback(...args);
      time = Date.now();
    }
  };
};

export const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);

  const updateScrollPosition = throttle(() => {
    setScrollPosition(window.scrollY);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', updateScrollPosition);
    return () => window.removeEventListener('scroll', updateScrollPosition);
  }, [updateScrollPosition]);

  return scrollPosition;
};
