import { Card, ListGroup, Badge } from 'react-bootstrap';

const VestingConditionsDisplay = ({
  conditions,
  totalShares,
  startDate,
}: any) => {
  return (
    <div>
      <h5>Vesting Conditions:</h5>
      {conditions.map((condition: any, index: number) => {
        const sharesVesting = Math.floor(
          (condition.portionNumerator / condition.portionDenominator) *
            totalShares
        );
        return (
          <Card key={index} className="mb-3">
            <Card.Header>
              <strong>Condition ID:</strong> {condition.id}
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <strong>Description:</strong> {condition.description}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Portion:</strong> {condition.portionNumerator}/
                {condition.portionDenominator}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Trigger Type:</strong> {condition.triggerType}
              </ListGroup.Item>
              {condition.triggerType === 'date' && (
                <ListGroup.Item>
                  <strong>Trigger Date:</strong>{' '}
                  {new Date(condition.triggerDate).toLocaleDateString()}
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <strong>Shares Vesting:</strong>{' '}
                <Badge bg="info">{sharesVesting}</Badge>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        );
      })}
    </div>
  );
};

export default VestingConditionsDisplay;
