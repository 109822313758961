import React, { useState } from 'react';
import { Dropdown, FormControl, InputGroup, Image } from 'react-bootstrap';
import defaultImage from '../../../assets/images/default.png';

interface TokenOption {
  address: string;
  name: string;
  imageUrl: string;
}

interface TokenInputDropdownProps {
  recommendedTokens: TokenOption[];
  value: string;
  onChange: (value: string) => void;
}

const TokenInputDropdown: React.FC<TokenInputDropdownProps> = ({
  recommendedTokens,
  value,
  onChange,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSelect = (address: string) => {
    onChange(address);
    setShowDropdown(false);
  };

  return (
    <InputGroup>
      <FormControl
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter or select ERC20 token address"
        onFocus={() => setShowDropdown(true)}
      />
      <Dropdown
        show={showDropdown}
        onToggle={() => setShowDropdown(!showDropdown)}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          className="d-flex align-items-center"
        >
          <Image
            src={
              recommendedTokens.find((token) => token.address === value)
                ?.imageUrl || defaultImage
            }
            roundedCircle
            width="20"
            height="20"
            className="me-1"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {recommendedTokens.map((token) => (
            <Dropdown.Item
              key={token.address}
              onClick={() => handleSelect(token.address)}
            >
              <div className="d-flex align-items-center">
                <Image
                  src={token.imageUrl}
                  roundedCircle
                  width="20"
                  height="20"
                />
                <span className="ms-2">{token.name}</span>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </InputGroup>
  );
};

export default TokenInputDropdown;
