import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import CardPlanDetails from './CardPlanDetails';
import TableInvoices from './TableInvoices';

function Billing() {
  const invoices: any = [];
  return (
    <>
      <Container fluid>
        <Row className="g-6">
          <Col md={12}>
            <CardPlanDetails />
          </Col>
        </Row>
        {/* <div className="d-flex align-items-end justify-content-between mt-10 my-4">
          <div>
            <h4 className="fw-semibold mb-1">Invoices</h4>
          </div>
          <div className="d-none d-md-flex gap-2">
            <Button variant="dark" size="sm">
              <i className="fa fa-download me-2"></i>Export all
            </Button>
          </div>
        </div>
        <TableInvoices invoices={invoices} hasCheckbox={true} /> */}
      </Container>
    </>
  );
}

export default Billing;
