import LandingNav from '../../components/LandingNav';
import Footer from '../../components/Footer';
import IconBigData from './icon_big_data.png';
import IconBlockchain from './icon_blockchain.png';
import IconCrypto from './icon_crypto.png';
import IconWallet from './icon_wallet.png';
import IconServers from './icon_servers.png';
import IconHistory from './icon_history.png';
import HeroImage from './background.jpeg';

function Platform() {
  return (
    <div className="Platform">
      <LandingNav />
      <section>
        <div
          className="jumbotron jumbotron-fluid"
          style={{ position: 'relative' }}
        >
          <img
            src={HeroImage}
            alt="Full-Stack Equity"
            className="img-fluid mb-5"
            style={{
              width: '100%',
              objectFit: 'cover',
              height: '30vh',
              opacity: '1',
            }}
          />
          <div
            className="container"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <h1 className="display-5 text-light">CMX</h1>
            <p className="lead mb-5 text-light">
              CMX is a blockchain protocol for private capital markets.
            </p>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-sm">
            <img src={IconCrypto} className="mx-auto d-block" alt="Crypto" />
            <h2 className="text-center">Secured Contracts</h2>
            <p className="lead">
              Audited contracts and pen-tested platform to secure corporate
              assets and tokenize equity investments.
            </p>
          </div>
          <div className="col-sm">
            <img src={IconWallet} className="mx-auto d-block" alt="Crypto" />
            <h2 className="text-center">Integrated Custody</h2>
            <p className="lead">
              Self-custody with passkey and hosted accounts provided by licensed
              digital asset custodians to secure your assets.
            </p>
          </div>
          <div className="col-sm">
            <img src={IconHistory} className="mx-auto d-block" alt="Crypto" />
            <h2 className="text-center">Auditability</h2>
            <p className="lead">
              Administrator access to audit trails and a granular permissions
              system for your team to ensure system integrity.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-sm">
            <img src={IconBigData} className="mx-auto d-block" alt="Big Data" />
            <h2 className="text-center">Developer Platform</h2>
            <p className="lead">
              Use one API to interact with private and public data. CapSign's
              SDK is available in multiple languages.
            </p>
          </div>
          <div className="col-sm">
            <img
              src={IconBlockchain}
              className="mx-auto d-block"
              alt="Blockchain"
            />
            <h2 className="text-center">Third-Party Integrations</h2>
            <p className="lead">
              Connect to your existing vendors for transactions involving
              sensitive financial and personally identifiable data.
            </p>
          </div>

          <div className="col-sm">
            <img src={IconServers} className="mx-auto d-block" alt="Crypto" />
            <h2 className="text-center">Cloud Hosting</h2>
            <p className="lead">
              Use our trusted cloud hosting services, making it easier to manage
              your blockchain gateway on one platform.
            </p>
          </div>
        </div>
      </section>
      <hr className="mb-0" />
      <section className="bg-light-subtle">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6 col-xs-12 mb-5">
              <h4 className="text-primary">Accelerate your business</h4>
              <p className="lead text-block">
                We provide an extensible software solution for managing your
                team and your enterprise. Leverage our developer platform and
                application marketplace to customize your solution to spec.
              </p>
            </div>
            <div className="col-md-4 offset-md-2 col-xs-12">
              <h3 className="mb-3 text-primary">Sign up for early access</h3>
              <form className="d-flex justify-content-center">
                <div className="input-group input-group-lg">
                  {' '}
                  {/* Using input-group and input-group-lg for larger size */}
                  <a
                    className="btn btn-primary btn-lg"
                    href="https://6v1qwdajlxv.typeform.com/to/PrLYKTM9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get Started <i className="fa fa-right-to-bracket"></i>
                  </a>
                  {/* <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    name="_replyto"
                    aria-label="Recipient's email" // Accessibility improvement
                    aria-describedby="button-addon2" // Accessibility improvement
                    required
                  />
                  <button
                    className="btn btn-primary"
                    type="submit"
                    id="button-addon2" // Matches the aria-describedby in the input
                  >
                    Submit
                  </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Platform;
