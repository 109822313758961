import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Pagination } from 'react-bootstrap';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PDFViewer = ({ data }: { data: Blob }) => {
  const [numPages, setNumPages] = React.useState<number>(1);
  const [pageNumber, setPageNumber] = React.useState<number>(1);

  const { width, ref } = useResizeDetector();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const renderPaginationItems = () => {
    const items: any[] = [];

    if (!numPages) return items;

    // Add first page
    items.push(
      <Pagination.Item
        key={1}
        active={1 === pageNumber}
        onClick={() => handlePageChange(1)}
      >
        1
      </Pagination.Item>
    );

    // Add ellipsis if needed
    if (pageNumber > 4) {
      items.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    // Add pages before the current page
    for (
      let number = Math.max(2, pageNumber - 2);
      number < pageNumber;
      number++
    ) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === pageNumber}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Add current page
    if (pageNumber !== 1 && pageNumber !== numPages) {
      items.push(
        <Pagination.Item
          key={pageNumber}
          active
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }

    // Add pages after the current page
    for (
      let number = pageNumber + 1;
      number <= Math.min(numPages - 1, pageNumber + 2);
      number++
    ) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === pageNumber}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Add ellipsis if needed
    if (pageNumber < numPages - 3) {
      items.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    // Add last page
    if (numPages != 1) {
      items.push(
        <Pagination.Item
          key={numPages}
          active={numPages === pageNumber}
          onClick={() => handlePageChange(numPages)}
        >
          {numPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <div ref={ref} className="mb-5">
      {data && (
        <>
          <div className="border mb-5">
            <Document file={data} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={pageNumber}
                width={width ? width * 0.9995 : undefined}
              />
            </Document>
          </div>
          <div className="d-flex justify-content-center">
            <Pagination>
              <Pagination.First
                onClick={() => handlePageChange(1)}
                disabled={pageNumber === 1}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(pageNumber - 1)}
                disabled={pageNumber === 1}
              />
              {renderPaginationItems()}
              <Pagination.Next
                onClick={() => handlePageChange(pageNumber + 1)}
                disabled={pageNumber === numPages}
              />
              <Pagination.Last
                onClick={() => handlePageChange(numPages ?? 1)}
                disabled={pageNumber === numPages}
              />
            </Pagination>
          </div>
        </>
      )}
    </div>
  );
};

export default PDFViewer;
