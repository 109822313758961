// SafeListItem.tsx

import React, { useState } from 'react';
import numbro from 'numbro';
import { contracts, formatOptions } from '../../constants';
import { useChainId, usePublicClient } from 'wagmi';
// import SafeModal from './SafeModal';

interface SafeListItemProps {
  safe: any;
}

export default function SafeListItem({ safe }: SafeListItemProps) {
  const {
    investment_amount,
    currency,
    issuer_short_name,
    logoUrl,
    issue_date,
    label,
    custom_id,
    tokenAddress,
    txHash,
    security_id,
  } = safe;

  const [showModal, setShowModal] = useState(false);
  const chainId = useChainId();

  function parseAmountWithCurrency(value: string) {
    const match = value?.match(/\(([\d.]+),(\w+)\)/);
    if (match) {
      const amount = parseFloat(match[1]);
      const currency = match[2];
      return { amount, currency };
    }
    return { amount: 0, currency: 'USD' }; // default fallback
  }

  function formatCurrency(amount: number, currency: string) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    }).format(amount);
  }

  return (
    <>
      <section
        className="card my-4 shadow-sm"
        onClick={() => setShowModal(true)}
        style={{ cursor: 'pointer' }}
      >
        <div className="d-flex align-items-center card-body p-4">
          <div className="col-1">
            <img
              className="img-fluid border rounded-circle"
              width="50"
              src={logoUrl}
              alt="Corporate Logo"
            />
          </div>
          <div className="col-8">
            <h5>
              {issuer_short_name} <small className="text-muted">SAFE</small>
            </h5>
            <div>
              {custom_id} <small className="text-muted">{security_id}</small>
              {/* {label} <small className="text-muted">{security_id}</small> */}
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end align-items-start text-end">
            <div>
              <div>
                {investment_amount !== undefined
                  ? numbro(investment_amount).format(formatOptions)
                  : '-'}{' '}
                {currency}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-between text-secondary">
            <small>
              <span>
                Issue Date: {new Date(issue_date).toLocaleDateString()}
              </span>
            </small>
            <small>
              <span></span>
            </small>
          </div>
        </div>
      </section>

      {/* Modal */}
      {/* <SafeModal
        show={showModal}
        onHide={() => setShowModal(false)}
        safe={safe}
      /> */}
    </>
  );
}
