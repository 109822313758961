// Compliance.tsx

import React from 'react';
import { Table } from 'react-bootstrap';

interface ComplianceProps {
  certificate: any;
}

export default function Compliance({ certificate }: ComplianceProps) {
  const { federalExemption, rule144Date, stateExemption, stateOfResidency } =
    certificate;

  return (
    <div>
      <h4>Compliance</h4>

      <Table>
        <tbody>
          <tr>
            <th>Federal Exemption</th>
            <td>{federalExemption || 'N/A'}</td>
          </tr>
          <tr>
            <th>Rule 144 Date</th>
            <td>
              {rule144Date ? new Date(rule144Date).toLocaleDateString() : 'N/A'}
            </td>
          </tr>
          <tr>
            <th>State Exemption</th>
            <td>{stateExemption || 'N/A'}</td>
          </tr>
          <tr>
            <th>State of Residency</th>
            <td>{stateOfResidency || 'N/A'}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
