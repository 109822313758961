// capsign.com/src/components/SidePopout.tsx

import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { AiOutlineMessage } from 'react-icons/ai'; // Icon for the tab
import Chat from '../Chat'; // Update the path as necessary
import FileViewer from '../FileViewer'; // Update the path as necessary
import './index.css';

const SidePopout = () => {
  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);

  return (
    <>
      {/* Clickable Tab */}
      <div
        className={`side-popout-tab ${show ? 'open' : ''}`}
        onClick={handleToggle}
      >
        <AiOutlineMessage size={24} />
      </div>

      {/* Offcanvas Side Panel */}
      <Offcanvas
        show={show}
        onHide={handleToggle}
        placement="end"
        className="side-popout-offcanvas"
        backdrop={false}
        scroll={true}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Assistant</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Chat />
          {/* <FileViewer /> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SidePopout;
